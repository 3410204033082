#mc_embed_signup {
    
    display:block; 
    position:relative; 
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    padding: 5px 10px;
    font-family: $body-font-family;

    #mc-embedded-subscribe-form {

        h2 {
            font-family: $header-font-family;
            font-size: 27px;
            margin-bottom: 30px;
        }


        display: flex;
        flex-direction: column;
        width: 100%;

        div {
        margin-bottom: 25px;
        }

        .asterisk {
        color: red;
        
        }


        .mc-field-group {
            label {
            margin-bottom: 15px;
            font-weight: normal;
            font-size: 17px;
            }
        }

        input[type='text'],
        input[type='email'],
        textarea {
            display: block;
            width: 100%;
            line-height: 32px;
            height: 32px;
            padding: 0 15px;
            border: none;
            background-color: #dedede;
            font-family: $body-font-family;
            font-weight: normal;

            &.inline {
                & + .inline {
                    margin-top: 10px;
                }
            }
        }
    
        textarea {
            height: auto;
            padding: 10px 15px;
            line-height: 1;
        }

        input[type='radio'] {
            border: none;
            outline: none;

            &:focus {
                outline: none;
            }

        }


        .subscribe {

            &-container {
                display: block;
                margin-bottom: 15px;
                font-size: 1rem;
                cursor: pointer;
        
                &--disabled {
                    opacity: .7;
                    pointer-events: none;
                }
            }
        
            &-wrapper {
                display: inline-block;
                background-color: map-get($brand-colours, group-dark-blue);
                text-align: center;
                padding: 4px 5px;
                min-height: 40px;
                height: auto !important;
                position: relative;
        
                &::before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 5px;
                    border: solid rgba(0,0,0,0.5);
                    border-width: 0 1px;
                    background: transparent;
                    transition: 0.3s;
                    height: 0%;
                    width: calc(100% - 10px);
                    opacity: 0;
                    pointer-events: none;
                }
        
                &::after {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: 8px;
                    background: transparent;
                    border: solid rgba(0,0,0,0.5);
                    border-width: 1px 0;
                    transition: 0.3s;
                    height: calc(100% - 10px);
                    width: 0%;
                    opacity: 0;
                    pointer-events: none;
                }
        
                &:hover {
                    background-color: map-get($brand-colours, group-dark-blue);
                    border: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
        
                    &::before {
                        height: calc(100% - 20px);
                        opacity: 1;
                    }
        
                    &::after {
                        width: calc(100% - 15px);
                        opacity: 1;
                    }
                }
        
                .subscribe-button {
                    font-family: $header-font-family;
                    font-size: rem-calc(13);
                    line-height: rem-calc(13);
                    color: map-get($brand-colours, light-gray);
                    text-transform: uppercase;
                    padding: 10px 20px 6px 20px;
                    background-color: transparent;
                    position: relative;
                    margin: 0 !important;
                    cursor: pointer;
                    vertical-align: bottom;
                    border: none;
        
                    &:hover {		
                        text-decoration: none !important;
                    }
                }
            }
        }

     



        .input-group {
            width: 100%;

            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                }
            }

        
            input[type='radio'] {
                margin: 10px;
                font-size: 17px;

                // label {
                //     margin-left:10px;
                // }
            }

        }    
    }
}
