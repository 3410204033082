.blog-quote {
	width: 100%;
	max-width: 792px;
	margin: 0 auto 60px;
	
	&__image {
		@include breakpoint(small only) {
			margin-bottom: 55px;
		}
	}
	
	&__text {
		padding: 25px;
		@include flex-align-center;
		
		p {
			font-family: $header-font-family;
			font-size: 25px;
			line-height: 27px;
			margin-bottom: 45px;
		}
		
		&::after {
			content: '';
			display: block;
			width: 75px;
			height: 1px;
			margin: 0 auto;
			background: #707070;
			
			@include breakpoint(medium) {
				margin-bottom: 60px;
			}
		}
	}
}