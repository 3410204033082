.text-image {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 30px;

    @include breakpoint(medium down) {
        padding: 0 10px;
    }

    &:not(.text-image--reverse) {
        .text-image__text {
            order: 2;
        }

        .text-image__img {
            order: 3;
        }
    }

    &__wrapper {
    }

    &__title {
        margin-bottom: 15px;

        h2 {
            margin-bottom: 0;
        }
    }

    &__text {
        padding: 50px 40px 20px 20px;

        @include breakpoint(small only) {
            text-align: center;
            padding: 20px;
        }

        a.cta {
            text-decoration: none !important;
            margin: 0 40px 15px 0px !important;

            @include breakpoint(small only) {
                margin-left: 0 !important;
            }
        }

        &-container {
            @include breakpoint(small only) {
                text-align: left;
            }

            p {
            }

            ul {
                li {
                    font-size: rem-calc(17);
                }
            }

            a {
                font-size: rem-calc(17);
                text-decoration: underline;
                color: #000;

                &:hover {
                    color: map-get($brand-colours, group-dark-blue);
                }
            }
        }
    }

    &__img {
        &-slide {
            img {
                margin: 0 auto;
            }
        }
    }

    &-video {
        position: relative;
        width: 100%;
        padding-bottom: 125%; // 4:5 aspect ratio

        .mejs__player {
            position: absolute;
            top: 0;
            left: 0;
        }

        .mejs__player,
        .mejs__overlay,
        video,
        iframe {
            max-width: 100%;
            height: 100% !important;
        }
    }
}
