.blog-highlights {
	width: 100%;
	max-width: 975px;
	margin: 0 auto 40px;
	padding: 0 25px;
	text-align: center;
	
	&__header {
		border-bottom: 4px solid #000;
		
		@include breakpoint(medium down) {
			padding: 0 25px;
		}
		
		h2 {
			text-transform: uppercase;
			font-family: $blog-font-family;
			font-size: 13px;
			letter-spacing: 0.16em;
			margin-bottom: 40px;
		}
	}
	
	&__image {

		@include breakpoint(medium) {
			padding: 0 17px 0 0;
		}

		@include breakpoint(small only) {
			margin-bottom: 15px;
		}
	}
	
	&__content {
		@include flex-align-center;
		padding: 0 5px;
		
		h3 {
			position: relative;
			display: block;
			color: #000;
			font-family: $blog-font-family;
			font-size: 10px;
			letter-spacing: 0.16em;
			margin-bottom: 20px;
			
			&::after {
				content: '';
				width: 24px;
				height: 1px;
				background: #000;
				display: block;
				margin: 4px auto 0;
			}
		}
		
		h4 {
			font-size: 35px;
			line-height: 41px;
			font-family: $header-font-family;
			
			@include breakpoint(small only) {
				font-size: 30px;
				line-height: 33px;
			}
		}
	}
	
	&__cta {
		a {
			color: #000;
			font-style: italic;
			font-family: $header-font-family;
			font-size: 17px;
			display: inline-block;
			padding-bottom: 0;
			border-bottom: 1px solid #000;
			text-transform: lowercase;
		}
	}
	
	
	
	&__item {
		padding: 25px 0;
		border-bottom: 1px solid #000;
		
		&:nth-child(odd) {
			.blog-highlights__image {
				@include breakpoint(medium) {
					order: 2;
					padding: 0 0 0 17px;
				}
			}
			
			.blog-highlights__content {
				@include breakpoint(medium) {
					order: 1;
				}
			}
		}
	}
}