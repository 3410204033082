.form {
    .field-validation-error {
        display: block;
        text-align: left;
        padding: 10px 0;
        color: red;
    }

    .field-wrapper {
        padding: 0 10px;
        margin-bottom: 10px;
        clear: both;

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    .accordion-content {
    	&--no-padding .field-wrapper {
	    	padding-left: 0;
			padding-right: 0;
		}
		
		.field-wrapper {
			margin-bottom: 17px;
		}
    }

    input[type='text'],
    input[type='email'],
    textarea,
    select {
        display: block;
        width: 100%;
        line-height: 32px;
        height: 32px;
        padding: 0 15px;
        border: none;
        background-color: #dedede;
        font-family: $body-font-family;
        font-weight: normal;

		&::placeholder {
			text-transform: none !important;
		}

        &.inline {
            & + .inline {
                margin-top: 10px;
            }
            
            &--30 {
                @media screen and (min-width: 450px) {
                    display: inline-block;
                    width: 30%;

                    & + .inline {
                        display: inline-block;
                        width: calc(70% - 13px);
                        margin-top: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    textarea {
        height: auto;
        padding: 10px 15px;
        line-height: 1;
    }

    select {
        padding: 0 12px;
        appearance: none;
        @include background-triangle(#202020);
        background-origin: content-box;
        background-position: right -15px center;
        background-repeat: no-repeat;
        background-size: 9px 6px;
        padding-right: 30px;

        &:focus {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }
    }

	input[type="checkbox"] {
		position: absolute; 
		opacity: 0;
		
		~ label,
		+ label {
			position: relative;
			padding-left: 20px;
			font-size: 13px;
			color: #000;
			cursor: pointer; 
			display: inline-block !important;
			
			&::before,
			&::after {
				content: '';
				position: absolute; 
			}
			
			&::before {
				top: 3px;
				left: 0;
				width: 13px;
				height: 13px;
				border: 1px solid #000;
			}
			
			&::after {
				top: 5px;
				left: 2px;
				width: 9px;
				height: 9px;
				content: '';
				opacity: 0;
				background: map-get($brand-colours, group-dark-blue);
				transition: opacity 0.3s ease-in-out;
			}
		}
		
		&:checked ~ label,
		&:checked + label {
			&:after {
				opacity: 1;  
			}
		}
	}
    
    .field {
	    &-20 {
		    width: 20%;
		    float: left;
		    margin-right: 2%;
		    
		    @media screen and (max-width: 1024px) {
			    width: 100%;
			    margin-right: 0;
		    }
		    
		    &:not(:last-child) {
			    @media screen and (max-width: 1024px) {
					margin-bottom: 17px;
				}
		    }
		    
		    input,
		    select {
			    width: 100%;
		    }
		}
	    
	    &-40 {
		    width: 38%;
		    float: left;
		    margin-right: 2%;
		    
		    @media screen and (max-width: 1024px) {
			    width: 100%;
			    margin-right: 0;
		    }
		    
		    &:not(:last-child) {
			    @media screen and (max-width: 1024px) {
					margin-bottom: 17px;
				}
		    }
		    
		    &:last-child {
			    margin-right: 0;
		    }
		    
		    input,
		    select {
			    width: 100%;
		    }
	    }
	    
	    &-50 {
		    width: 48%;
		    float: left;
		    margin-right: 2%;
		    
		    @media screen and (max-width: 1024px) {
			    width: 100%;
			    margin-right: 0;
		    }
		    
		    &:not(:last-child) {
			    @media screen and (max-width: 1024px) {
					margin-bottom: 17px;
				}
		    }
		    
		    &:last-child {
			    margin-left: 2%;
			    margin-right: 0;
			    
			    @media screen and (max-width: 1024px) {
				    margin-left: 0;
			    }
		    }
		}
		
		&-60 {
		    width: 58%;
		    float: left;
		    margin-right: 2%;
		    
		    @media screen and (max-width: 1024px) {
			    width: 100%;
			    margin-right: 0;
		    }
		    
		    &:not(:last-child) {
			    @media screen and (max-width: 1024px) {
					margin-bottom: 17px;
				}
		    }
		    
		    &:last-child {
			    margin-left: 2%;
			    margin-right: 0;
			    
			    @media screen and (max-width: 1024px) {
				    margin-left: 0;
			    }
		    }
	    }
	    
	    &-wrapper {
			&.cf {
				*zoom: 1;

				&:before,
				&:after {
					content: " ";
					display: table;
				}

				&:after {
					clear: both;
				}
			}

		    textarea {
			    height: 190px;
			    background-color: #fff;
			    border: 1px solid #dde1e3;
		    }
	    }
	    
	    &-file {
		    position: relative;
		    display: inline-block;
		    overflow: hidden;
		    width: 100%;
		    height: 32px;
		    padding-left: 10px;
		    
		    button.add-file {
			    font-family: $body-font-family;
			    color: #202020;
			    font-size: rem-calc(13);
			    height: 32px;
			    padding-top: 9px;
			    
			    &::after {
				    content: '';
				    display: inline-block;
				    width: 13px;
				    height: 17px;
				    background: url('img/oetker-collection/icon-document.svg') no-repeat center center;
				    background-size: 13px 17px;
				    margin-left: 10px;
			    }
			    
			    span {
				    padding-right: 10px;
				    border-bottom: 1px solid #1c3942;
				    text-overflow: ellipsis;
					max-width: 150px;
					display: inline-block;
					white-space: nowrap;
					overflow: hidden;
					text-align: left;
			    }
		    }
		    
		    button.remove-file {
			    position: absolute;
			    z-index: 10;
			    top: 12px;
			    right: 0;
			    width: 15px;
			    height: 15px;
			    background: url('img/oetker-collection/close.svg') no-repeat center center;
			    background-size: 18px 18px;
			    text-indent: -12345px;
			    visibility: hidden;
			    
			    &.visible {
				    visibility: visible;
			    }
		    }
		    
		    input[type=file] {
				font-size: 100px;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				cursor: pointer;
			}
	    }
    }
}

.theme-villa-stephanie .pre-stay-form .accordion-title {
	background-color: #9b7793!important;
	color:#ffffff!important;	
}