.booking-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2000;
	background-color: #fff;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70px;
		height: 70px;
		transform: translate(-35px, -35px);
		background: url('img/oetker-collection/loading-booking.gif') no-repeat center center;
		background-size: 70px 70px;
	}
}


ng-http-loader[spinner='sk-chasing-dots'] {
    display: none !important;
}