.page-anchor {
    margin: 0 auto 30px;
    font-size: 1.5rem;
    font-family: $header-font-family;
    text-align: center;
    color: #838383;

    &--hidden {
        visibility: hidden;
        height: 1px;
        margin: 0;
        margin-top: -120px;
        padding-top: 120px;
    }

    @at-root .row .grid6 {
        position: relative;
    }

    @at-root .row .grid12 {
        position: relative;
    }

    @at-root .row #{&} {
        position: absolute;
        top: -65px;
        left: 0;
        right: 0;
        z-index: 4;
        margin-top: 20px;
        padding-top: 65px;
        pointer-events: none;

        @media screen and (min-width: 1024px) {
            top: -190px;
            padding-top: 190px;
        }
    }

    @at-root .row .grid6 #{&} {
        @media screen and (min-width: 1161px) {
            margin-top: 40px;
        }
    }

    @at-root .row[style*='background-image'] .grid6 #{&} {
        @media screen and (min-width: 921px) {
            margin-top: 95px;
        }
    }

    + .hotelContainerShared {
        margin-top: 50px;

        @media screen and (min-width: 921px) {
            margin-top: 40px;
        }
    }

    + .featureImageFull {
        @media screen and (max-width: 720px) {
            margin-top: 10px;
        }
    }
}