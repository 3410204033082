.feature-image {
	max-width: 1260px;
	width: 100%;
	background-size: cover;
	background-position: center center;
	padding: 5% 10%;
	margin: 0 auto 30px;

	@include breakpoint(small only) {
		padding: 5%;
	}

	&__left, &__right {
		background-color: #fff;
		position: relative;
		max-width: 550px;
		width: 100%;
		padding: 50px;

		@include breakpoint(medium down) {
			padding: 35px;
		}

		&-container {

			&-title {

				h2 {
					margin-bottom: 20px;
				}
			}

			&-text {
				margin-bottom: 25px;

				p {
					margin-bottom: 0px;
				}

				a {
					color: #000;
					text-decoration: underline;

					&:hover {
						color: map-get($brand-colours, group-dark-blue);
					}
				}
			}

			&-cta {
				margin-bottom: 20px;
			}

			&-terms {
				margin-top: 10px;

				p {
					margin-bottom: 0;
					font-size: rem-calc(11);
				}
			}
		}
	}
}