.grid {
    position: relative;
    max-width: 1260px;
    margin: 0 auto 30px;

    &__view {
        position: absolute;
        top: 15px;
        right: 15px;
        margin-bottom: 25px;

        @include breakpoint(medium down) {
            position: relative;
            top: auto;
            right: auto;
        }

        @include breakpoint(small only) {
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            width: 100%;
        }

        &-switch {
            z-index: 9;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @include breakpoint(medium only) {
                padding-right: 30px;
            }

            @include breakpoint(small only) {
                padding-right: 0px;
                justify-content: center;
            }

            p {
                display: inline-block;
                letter-spacing: 2px;
                margin: 0 5px 0 0;
                font-size: rem-calc(13);
                line-height: 1;
                text-transform: uppercase;
                font-family: $header-font-family;
            }

            ul {
                display: inline-block;
                list-style: none;
                margin: 0;
                line-height: 1;

                li {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 3px;

                    &#carousel {
                        button {
                            display: block;
                            padding: 10px;
							background: url('img/oetker-collection/carousel-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;
                            &:hover {
								background: url('img/oetker-collection/carousel-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/carousel-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }

                    &#tile {
                        button {
                            display: block;
                            padding: 10px;
							background: url('img/oetker-collection/tile-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;
                            &:hover {
								background: url('img/oetker-collection/tile-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/tile-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }

                    &#listing {
                        button {
                            display: block;
                            padding: 10px;
							background: url('img/oetker-collection/listing-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;
                            &:hover {
								background: url('img/oetker-collection/listing-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/listing-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }

                    &#location {
                        button {
                            display: block;
                            padding: 10px;
							background: url('img/oetker-collection/location-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;							

                            &:hover {
								background: url('img/oetker-collection/location-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/location-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }
                }
            }
        }
    }

    .filter-mobile-accordion {
        text-align: center;
        margin-bottom: 10px;
        padding-top: 10px;
        position: relative;

        .accordionButton {
            border: solid rgba(map-get($brand-colours, light-gray), 0.5);
            border-width: 1px 0px;

            h4 {                
                margin-bottom: 0;
                padding: 10px;
                font-size: rem-calc(13px);
                text-transform: uppercase;

                &::after {
                    background: url('img/oetker-collection/arrow-down-black.svg') no-repeat center;
                    background-size: 10px 6px;
                    content: '';
                    width: 10px;
                    height: 10px;
                    padding: 10px;
                }
            }

            &.on {
                border-bottom: none;

                h4 {
                    &::after {
                        background: url('img/oetker-collection/arrow-up-black.svg') no-repeat center;
                        background-size: 10px 6px;
                    }
                }
            }
        }

        .accordionContent.filter-controls {
            padding-top: 0;
        }
    }

    // .filter-controls .accordionContent {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column;

    //     .filter-anything-button {
    //         margin-bottom: 10px;
    //     }
    // }



    .filter-controls {
        width: 100%;
        min-height: 40px;
        text-align: center;
        border: solid #acacac;
        border-width: 1px 0px;
        margin: 0 auto 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(small only) {
            padding: 20px 10px;
            border-top: none;
            flex-direction: column;
        }

        .button,
        .filter-anything-button {
            margin: 0 10px !important;
            cursor: pointer !important;
        }

        .on,
        .filter-anything-selected {
            text-decoration: underline !important;
        }

        .button,
        .filter-anything-button {
            color: map-get($brand-colours, light-gray);
            background-color: transparent;
            background: url('img/oetker-collection/filter-button.png') no-repeat left center;
            font-size: rem-calc(13);
            line-height: rem-calc(15);
            font-family: $header-font-family;
            min-width: 50px;
            padding: 15px 10px 10px 20px;
            margin: 0 10px !important;
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
                color: map-get($brand-colours, dark-gray);
            }

            @include breakpoint(small only) {
                width: 40%;
                margin: 0 auto;
                background-position: 10% center;
                padding: 10px 10px 10px 30px;
            }

            &.on,
            &.filter-anything-selected  {
                background: url('img/oetker-collection/filter-button-active.png') no-repeat left center;
                text-decoration: underline;
                color: map-get($brand-colours, dark-gray);

                @include breakpoint(small only) {
                    background-position: 10% center;
                }
            }
        }  
    }
}
