.venue-grid {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto 30px;

    > div {
        margin-top: -30px;
    }

    &__item {
        max-width: 510px;
        width: 100%;
        margin: 30px auto 0;
        padding: 0 10px;

        &-img {
            .slick-prev {
                background: url('img/oetker-collection/left.svg') no-repeat center;
                left: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;
                z-index: 2;

                &::before {
                    content: '';
                }
            }

            .slick-next {
                background: url('img/oetker-collection/right.svg') no-repeat center;
                right: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;

                &::before {
                    content: '';
                }
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 70.59%; // 17:12 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        &-container {
            padding: 20px 25px 30px;
            border: solid rgba(map-get($brand-colours, light-gray), 0.2);
            border-width: 0 1px 1px;
            text-align: center;

            @include breakpoint(medium only) {
                padding: 20px 5px 30px;
            }
        }

        &-title {
            margin-bottom: 30px;

            h2 {
                margin-bottom: 0;

                @include breakpoint(small only) {
                    color: #000;
                }
            }

            h3 {
                font-family: $header-font-family;
                margin-bottom: 0;
                font-size: rem-calc(21);

                @include breakpoint(small only) {
                    color: #000;
                }
            }
        }

        &-description {
            margin-bottom: 20px;
        }

        &-layouts {
            justify-content: center;

            &-item {
                margin-bottom: 20px;
                padding: 0 5px;

                &-title {
                    p {
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        margin-bottom: 0;
                        font-size: rem-calc(9);
                        line-height: rem-calc(11);
                    }
                }

                &-icon {
                    img {
                        width: 40px;
                        height: 40px;
                        padding-top: 5px;
                    }
                }
            }
        }
    }
}
