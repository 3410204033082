.mice-modal {
    width: 100%;
    max-width: 600px;
    position: relative;
  

    @media screen and (max-width: 1023px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    .mice-form-link {
        text-align: center;
        margin-bottom: 50px;
        font-size: 11px;

        a {
            border-bottom: 1px solid #bebebe;
            padding-bottom: 5px;
            color: #1a3740;
            font-family: $header-font-family;
        }

        a:hover {
            color: #566e76;
        }
    }
}

.mice-form {
    position: relative;
    background-color: #fff;

    .row {
        .wrapper {
            .innerNarrow {
                padding: 0 20px;

                @media screen and (min-width: 1024px) {
                    padding: 0 10%;
                }
            }

            .innerWide {
                .col {
                    min-width: auto !important;

                    @media screen and (max-width: 735px) {
                        width: 100% !important;
                        margin: 0 0 20px 0 !important;
                    }
                }
            }
        }
    }

    h2 {
        font-family: $header-font-family;
        font-weight: normal;
        text-align: center;
        margin: 0 0 20px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #9a9a9a;
    }

    h3 {
        font-weight: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202020;
    }

    p {
        font-size: rem-calc(13);
        margin: 20px 0 10px 0;
    }

    .separator {
        background-color: rgba(map-get($brand-colours, light-gray), 0.2);
        height: 1px;
        margin: 0 auto 20px;
        width: 100%;
    }

    .backButton {
        position: relative;
        margin: 17px 0 0 25px !important;
        border-bottom: 1px solid #9fb1b6 !important;

        + .wrapper {
            margin-top: 18px !important;
        }
    }

    label.error {
        display: none !important;
    }

    .section-landing {
        .wrapper {
            &:first-child {
                margin-bottom: 0;
            }
        }

        .btn {
            min-width: 180px;
            margin-bottom: 20px;

            > span {
                display: inline-flex !important;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding-left: 10px !important;
                padding-right: 10px !important;
            }

            @media screen and (min-width: 736px) {
                height: 100%;
                margin-bottom: 0;
            }
        }
    }

    .section-form {
        margin-top: 0;
        .wrapper {
            &:first-child {
                margin: 0 auto 10px;
            }

            &:last-child {
                margin-top: 10px;
                width: 100%;
                text-align: center;
            }
        }

        .selected-treatments {
            text-align: left;
            padding: 0 20px 10px 20px;

            input[type='checkbox'],
            label {
                pointer-events: none;
            }
        }
    }

    .section-completion {
        .wrapper {
            margin-top: 80px;
        }
    }

    .accordion {
        text-align: left;
        list-style: none;

        &-item {
            margin-bottom: 10px;
        }

        &-title {
            display: block;
            color: #202020;
            background-color: rgba(map-get($brand-colours, light-gray), 0.5);
            padding: 8px 10px;
            position: relative;
            font-size: rem-calc(13);

            &::before {
                content: '';
                border-left: 5px solid transparent !important;
                border-right: 5px solid transparent !important;
                border-top: 4px solid #1a3740 !important;
                margin-top: -0.2rem;
                position: absolute;
                top: 50%;
                right: 20px;
            }
        }

        .is-active .accordion-title,
        .is-complete .accordion-title {
            color: #fff;
            background-color: map-get($brand-colours, group-dark-blue);

            &::before {
                border-top: 4px solid #fff !important;
            }
        }

        .is-active .accordion-title {
            &::before {
                border-top: 4px solid transparent !important;
                border-bottom: 4px solid #fff !important;
            }
        }

        &-content {
            label {
                color: #202020;
                font-size: rem-calc(13);
            }
        }

        p {
            text-align: center;
        }
    }

    .checkbox-wrapper {
        position: relative;
        margin-bottom: 5px;

        & + .separator {
            margin: 20px 0;
        }
    }

    .spinner {
        display: none;
        position: absolute;
        bottom: 100px;
        top: auto;
        left: 50%;
        margin-top: -36px;
        margin-left: -44px;
        width: 88px;
        height: 72px;
        z-index: 5;
    }

    .form {
        select,
        textarea,
        input[type='text'],
        input[type='email'] {
            background-color: #fff;
            border: 1px solid rgba(map-get($brand-colours, light-gray), 0.2);
            font-size: rem-calc(13);
            margin-bottom: 5px;

            &:disabled {
                opacity: 0.4;

                &::placeholder {
                    opacity: 1;
                    font-size: rem-calc(13);
                }
            }
        }

        input.form-datepicker {
            background: url('img/icon-calendar.svg') no-repeat;
            background-size: 18px 18px;
            background-position: right 10px center;
            cursor: pointer;
            margin-bottom: 5px;

            &:focus {
                outline: none;
            }
        }

        select {
            background-image: url('img/select-double-arrow.svg');
            background-size: 10px 11px;
            margin-bottom: 5px;

            &:focus {
                outline: none;
            }

            > span {
                display: none;
            }
        }
    }

    .checkbox-wrapper [type='radio']:checked,
    .checkbox-wrapper [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    .checkbox-wrapper [type='radio']:checked + label,
    .checkbox-wrapper [type='radio']:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    .checkbox-wrapper [type='radio']:checked + label:before,
    .checkbox-wrapper [type='radio']:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2013%2013%22%20style%3D%22enable-background%3Anew%200%200%2013%2013%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.st0%7Bfill%3A%2523202020%3B%7D%3C%2Fstyle%3E%3Cg%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0.4%2C13V1.4h11.6V13H0.4z%20M1.1%2C12.2h10v-10h-10V12.2z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')
            no-repeat center center;
        background-size: 16px 16px;
    }
    .checkbox-wrapper [type='radio']:checked + label:after,
    .checkbox-wrapper [type='radio']:not(:checked) + label:after {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        left: 0;
        background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2013%2013%22%20style%3D%22enable-background%3Anew%200%200%2013%2013%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.st0%7Bfill%3A%2523202020%3B%7D%3C%2Fstyle%3E%3Cg%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M11.5%2C1.4c0.4-0.3%2C0.7-0.5%2C1.1-0.7l0.2%2C0.4c-0.3%2C0.2-0.5%2C0.4-0.9%2C0.7l-0.8%2C0.7C9.9%2C3.6%2C8.8%2C4.8%2C7.9%2C6.2%20c-1%2C1.4-1.7%2C2.7-2.1%2C3.9l-0.3%2C0.2c-0.3%2C0.2-0.6%2C0.4-0.8%2C0.6c0-0.2-0.1-0.5-0.3-0.9L4.2%2C9.6c-0.4-1-0.7-1.6-1-2%20C2.9%2C7.2%2C2.6%2C6.9%2C2.3%2C6.9c0.4-0.4%2C0.8-0.6%2C1.1-0.6c0.4%2C0%2C0.9%2C0.6%2C1.4%2C1.7l0.3%2C0.6c1.4-2.4%2C3.2-4.6%2C5.4-6.5L11.5%2C1.4z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')
            no-repeat center center;
        background-size: 16px 16px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    .checkbox-wrapper [type='radio']:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .checkbox-wrapper [type='radio']:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .mice-thank-you {
        padding: 15% 0 5%;
    }

    &__checkbox {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;

  

    }

    #privacy {
        a {
            color: #000;
            text-decoration: underline;

            &:hover {
                color: map-get($brand-colours, group-light-blue) !important
            }
        }    
    }

    .wrapper {
        .innerWide {
            .col {
                .book-now-container {
                    .book-now-wrapper {
                        min-height: 20px;
                        
                        button {
                            padding: 4px 20px;
                        }
                    }
                }
            }
        }
    }




}
