.headerBookingModal {
    min-height: 200px;
    height: auto;
    overflow-y: visible;

    &.reveal.large {
        max-width: 750px;
    }

    .closeButton {
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 9999;
        width: 20px;
        height: 20px;
        font-family: $header-font-family;
        font-size: 1.8rem;

        &:after {
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            right: 0px;
            top: calc(50% - 6px);
            background-image: url('../assets/img/close.svg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -36px;
        margin-left: -44px;
        width: 88px;
        height: 72px;
        z-index: -1;
    }

    @media screen and (min-width: 1024px) {
        &.no-blur {
            max-height: 75vh;
            overflow-y: auto;
        }
    }
}

.spa-booking-form {
    background-color: #fff;

    &__headings {
        padding: 30px;
        margin: 60px auto 30px auto;
    }

    .row {
        .wrapper {
            .innerNarrow {
                padding: 0 20px;

                @media screen and (min-width: 736px) {
                    padding: 0 20%;
                }
            }

            .innerWide {
                .col {
                    min-width: auto !important;

                    @media screen and (max-width: 735px) {
                        width: 100% !important;
                        margin: 0 0 20px 0 !important;
                    }
                }
            }
        }
    }

    h2 {
        font-family: $header-font-family;
        font-size: 40px;
        font-weight: normal;
        text-align: center;
        margin: 0 0 20px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #9a9a9a;
    }

    h3 {
        font-family: $header-font-family;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202020;
    }

    p {
        font-size: rem-calc(15);
        margin: 20px 0 10px 0;
    }

    .separator {
        background-color: #b7b7b7;
        height: 1px;
        margin: 0 20px 20px 20px;
    }

    .backButton {
        position: absolute;
        top: 0;
        left: 0;
        margin: 17px 0 0 25px !important;
        border-bottom: 1px solid #9fb1b6 !important;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: -10px;
            width: 5px;
            height: 7px;
            transform: rotate(180deg);
            background: url('../assets/img/arrow.svg') no-repeat left top;
        }
    }

    .section-landing {
        .book-button {
            min-width: 180px;

            @media screen and (min-width: 736px) {
                margin-bottom: 0;
            }
        }
    }

    .section-daily-rates {
        margin-bottom: 40px;
    }

    .spa-package {
        padding: 5px;
        margin-bottom: 20px;
        border: 1px solid #e9eef2;

        &__intro {
            > * {
                display: block;
                text-align: center;

                @media screen and (min-width: 770px) {
                    display: inline-block;
                    vertical-align: middle;
                    width: 49%;
                }
            }

            > div {
                @media screen and (min-width: 770px) {
                    padding-left: 30px;
                }

                .btn {
                    margin-bottom: 20px;
                }
            }
        }

        &__info {
            display: none;
            margin: 30px;
            text-align: center;
            border-top: 1px solid #e9eef2;

            &--active {
                display: block;
            }
        }

        h3 {
            margin: 30px 0 15px 0;
            font-size: 36px;
            text-transform: none;
        }

        p {
            font-size: 15px;
        }

        .btn {
            margin-top: 10px;
        }
    }

    .section-treatments {
        .wrapper {
            &:first-child {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-top: 10px;
            }
        }

        .accordion {
            text-align: left;

            &-title {
                display: block;
                padding-left: 10px;
                color: #000;
                font-size: .9375rem;
                line-height: 1.25rem;
                border-bottom: 1px solid rgba(155,155,155,.2);
                background: url('../assets/img/oetker-collection/arrow-down-black.svg') 99% center no-repeat;
                background-size: 10px 10px;
                margin: 10px 0 5px 0;

                &:before {
                    content: none;
                }

                @at-root .accordion-item.is-active #{&} {
                    background-image: url('../assets/img/oetker-collection/arrow-up-black.svg');
                }
            }

            &-content {
                padding: 10px;
            }
        }

        .checkbox-wrapper {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: none;
            }
        }
    }

    .section-form {
        .selected-treatments {
            text-align: left;
            padding: 0 20px 10px 20px;

            input[type='checkbox'],
            label {
                pointer-events: none;
            }
        }

        select,
        textarea,
        input[type='text'],
        input[type='email'] {
            background-color: #fff;
            border: 1px solid rgba(map-get($brand-colours, light-gray), 0.2);
            font-size: rem-calc(13);
            margin-bottom: 5px;

            &:disabled {
                opacity: 0.4;

                &::placeholder {
                    opacity: 1;
                    font-size: rem-calc(13);
                }
            }
        }
    }

    .section-completion {
        padding-top: 80px;
    }

    .spa-booking-form-link {
        text-align: center;
        margin-top: 30px;
        font-size: 11px;

        a {
            border-bottom: 1px solid #bebebe;
            padding-bottom: 5px;
            color: #9b7793;
            font-family: $header-font-family;
        }

        a:hover {
            color: #a6869f;
        }
    }
}