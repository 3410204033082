.booking-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2000;
	background-color: #fff;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70px;
		height: 70px;
		transform: translate(-35px, -35px);
		background: url('img/spinner-black.gif') no-repeat center center;
		background-size: 70px 70px;

		@at-root body.theme-le-bristol-paris #{&} {
			background-image: url('img/10615-New-Le-Bristol-Animation-3-CS2-IS1.gif');
		}

		@at-root body.theme-brenners-park-hotel-spa #{&} {
			background-image: url('img/brenner-animation.gif');
		}

		@at-root body.theme-the-woodward #{&} {
			background-image: url('img/woodward.gif');
			width: 100px;
			height: 100px;
			transform: translate(-50px, -50px);
			background-size: 100px 100px;
		}

		@at-root body.theme-lapogee-courchevel #{&} {
			background-image: url('img/oetker-collection/loading-apg.gif');
		}

		@at-root body.theme-chateau-saint-martin-spa #{&} {
			background-image: url('img/oetker-collection/loading-csm.gif');
		}

		@at-root body.theme-eden-rock-st-barths #{&} {
			background-image: url('img/oetker-collection/loading-ero.gif');
		}

		@at-root body.theme-hotel-du-cap-eden-roc #{&} {
			background-image: url('img/oetker-collection/loading-hdcer.gif');
		}

		@at-root body.theme-jumby-bay-island #{&} {
			background-image: url('img/oetker-collection/loading-jbi.gif');
		}

		@at-root body.theme-the-lanesborough #{&} {
			background-image: url('img/oetker-collection/loading-lan.gif');
		}

		@at-root body.theme-palacio-tangara #{&} {
			background-image: url('img/oetker-collection/loading-sao.gif');
		}

		@at-root body.theme-hotel-la-palma #{&} {
			background-image: url('img/oetker-collection/CSM-animation.gif');
		}
	}
}


			ng-http-loader[spinner='sk-chasing-dots'] {
				display: none !important;
			}
