.carousel {
	max-width: 1260px;
	width: 100%;
	padding: 0 0 40px;
	margin: 0 auto 30px;


	&__inner {
		&.slick-slider[data-slick*="\"arrows\": true"] {
			padding: 0;
		}

		.slick-prev {
			background: url('img/oetker-collection/left.svg') no-repeat center;
			// background: url('img/oetker-collection/blue-arrow-prev.svg') no-repeat center;
			left: 7%;
			top: 50%;
			transform: translate(0%,-50%);
			background-size: 15px 30px;
			width: 30px;
			height: 30px;
			z-index: 2;

			@include breakpoint(medium only) {
				left: 6%;
			}

			@include breakpoint(small only) {
				left: 2%;
			}

			&::before {
				content: '';
			}
		}

		.slick-next {
			background: url('img/oetker-collection/right.svg') no-repeat center;
			// background: url('img/oetker-collection/blue-arrow-next.svg') no-repeat center;
			right: 7%;
			top: 50%;
			transform: translate(0%,-50%);
			background-size: 15px 30px;
			width: 30px;
			height: 30px;

			@include breakpoint(medium only) {
				right: 6%;
			}

			@include breakpoint(small only) {
				right: 2%;
			}

			&::before {
				content: '';
			}
		}

		.slick-dots {
			bottom: -30px;
		}
	}

	&__slide {
		padding: 0 10px;
		opacity: 0.5;

		@include breakpoint(small only) {
			padding: 0 2px;
		}

		&.slick-current {
			opacity: 1;
		}
	}

}
