.masterpiece-contact-form {
	margin: 0 auto 30px;
	width: 100%;
	max-width: 1260px;

	@include breakpoint(medium down) {
		padding: 0 20px;
	}

	&__intro {
		margin-bottom: 30px;

		&include breakpoint(small only) {
			padding: 0 20px;
		}
	}
}

.estates-contact-form {
    max-width: 850px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;

    // set form element order
    > * {
        &:nth-child(n+1) {
            order: 1;
        }
        &:nth-child(n+2) {
            order: 2;
        }
        &:nth-child(n+3) {
            order: 3;
        }
        &:nth-child(n+4) {
            order: 4;
        }
        &:nth-child(n+5) {
            order: 5;
        }
        &:nth-child(n+6) {
            order: 6;
        }
        &:nth-child(n+7) {
            order: 7;
        }
        &:nth-child(n+8) {
            order: 8;
        }
        &:nth-child(n+9) {
            order: 10;

            @media screen and (min-width: 750px) {
                order: 9;
            }
        }
        &:nth-child(n+10) {
            order: 11;

            @media screen and (min-width: 750px) {
                order: 10;
            }
        }
        &:nth-child(n+11) {
            order: 9;

            @media screen and (min-width: 750px) {
                order: 11;
            }
        }
        &:nth-child(n+12) {
            order: 12;
        }
        &:nth-child(n+13) {
            width: 100%;
            order: 13;
        }
    }

    select,
    input[type="text"],
    input[type="email"],
    textarea {
        float: left;
        width: calc(100% - 10px);
        height: 32px;
        margin: 0 5px 15px 5px;
        padding: 0 12px;
        background-color: #faf8f6;
        border: 0;
        font-size: rem-calc(13);
        font-family: $body-font-family;
        color: #2f251d;

        &::placeholder {
            opacity: 1;
        }

        :focus {
            outline: none;
        }

        &.half {
            @media screen and (min-width: 750px) {
                width: calc(50% - 10px);
            }
        }

        &.quarter {
            @media screen and (min-width: 750px) {
                width: calc(25% - 10px);
            }
        }
    }

    select,
    .js-date-range-picker {
        padding: 0 30px 0 12px !important;
        appearance: none;
        @include background-triangle(#202020);
        background-origin: content-box;
        background-position: right -15px center;
        background-repeat: no-repeat;
        background-size: 9px 6px;

        &:focus {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }
    }

    .js-date-range-picker-calendar {
        display: none;
        max-width: calc(100% - 10px);
        margin: 0px 5px 10px;

        &.visible {
            display: block;
        }

        .calendar-wrapper {
            display: inline-block;
            width: 300px;
            max-width: 100%;
            margin-right: 10px;
            text-align: center;

            &__heading {
                font-family: $header-font-family;
                font-size: rem-calc(21);
                color: #1a3740;
                margin-bottom: 10px;
            }

            #Datepickk {
                font-family: $header-font-family;
                background-color: #f9f8f6;
                margin-bottom: 10px;

                .d-prev,
                .d-next {
                    &:after {
                        content: none;
                    }
                }

                .d-header,
                .d-month,
                .d-week {
                    background-color: #f9f8f6;
                }

                .d-table label {
                    font-family: $header-font-family;
                }
            }
        }
    }

    textarea {
        height: 190px;
        padding: 12px;

        &:focus {
            outline: none;
        }
    }

    input[type="checkbox"] {
		position: absolute; 
		opacity: 0;
		
		~ label,
		+ label {
			position: relative;
			padding-left: 20px;
			font-size: 13px;
			color: #000;
			cursor: pointer; 
			display: inline-block !important;
			
			&::before,
			&::after {
				content: '';
				position: absolute; 
			}
			
			&::before {
				top: 3px;
				left: 0;
				width: 13px;
				height: 13px;
				border: 1px solid #000;
			}
			
			&::after {
				top: 5px;
				left: 2px;
				width: 9px;
				height: 9px;
				content: '';
				opacity: 0;
				background: map-get($brand-colours, group-dark-blue);
				transition: opacity 0.3s ease-in-out;
			}
		}
		
		&:checked ~ label,
		&:checked + label {
			&:after {
				opacity: 1;  
			}
		}
	}

    label {
        display: inline-block;
        font-size: rem-calc(13);
        font-family: $body-font-family;

        &.mandatory {
            margin: 0 0 10px 5px;
            font-family: $body-font-family;
            font-style: italic;
            color: #000;
            font-size: rem-calc(13);
        }

        &.error {
            color: red;
            margin: -8px 0 10px;
        }
    }
}