.feature-text-image-paged {
    position: relative;
    max-width: 1100px;
    margin: 0 auto 30px;
    padding: 0 0 40px;

    &--single {
        padding: 0;
    }

    &--reverse .grid-x {
        flex-direction: row-reverse;
    }

    .slick-initialized {
        @include breakpoint(small only) {
            padding: 0 20px;
        }

        .slick-track {
            @include breakpoint(medium) {
                display: flex;
            }
        }

        .slick-slide {
            display: flex;
            height: auto;
            align-items: center;
            justify-content: center;
        }

        .slick-arrow {
            @include breakpoint(small only) {
                top: 56vw;
            }
        }

        .slick-prev {
            background-image: url('img/oetker-collection/left-green.svg');

            @include breakpoint(small only) {
                left: 5px;
            }
        }

        .slick-next {
            background-image: url('img/oetker-collection/right-green.svg');

            @include breakpoint(small only) {
                right: 5px;
            }
        }
    }

    &__slide {
        text-align: center;
        padding: 0 1px;

        &-image {
            @include breakpoint(small only) {
                margin-bottom: 15px;
            }
        }

        &-heading {
            margin-bottom: 5px;

            @include breakpoint(small only) {
                margin-top: 10px;
                line-height: 1.2;
            }
        }

        &-subheading {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 0;
            font-family: $header-font-family;
            font-size: rem-calc(14px);
            line-height: rem-calc(14px);

            @include breakpoint(small only) {
                margin-top: 0;
            }

            li {
                display: inline-block;
                margin: 0 0 5px 0;
                font-size: rem-calc(17);

                &:after {
                    content: '|';
                    padding: 0 5px;
                    display: inline-block;
                    width: rem-calc(15px);
                    height: rem-calc(15px);
                    margin-right: -rem-calc(14px);
                }

                &.no-pipe-separator {
                    &:after {
                        color: transparent;
                    }
                }

                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 67.65%; // 34:23 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        &-description {
            margin-top: 15px;
            font-size: rem-calc(13px);

            a {
                color: #000;
                text-decoration: underline;

                &:hover {
                    color: map-get($brand-colours, group-dark-blue);
                }
            }
        }

        > .grid-x {
            width: 100%;

            > .cell:last-child {
                @include breakpoint(medium) {
                    padding-left: 50px;
                }

                @at-root .feature-text-image-paged--reverse #{&} {
                    @include breakpoint(medium) {
                        padding-left: rem-calc(15px);
                        padding-right: 50px;
                    }
                }
            }
        }
    }

    .slick-slider {
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
    }
}
