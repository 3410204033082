.two-images {
    max-width: 1260px;
    margin: 0 auto 30px;

    &--reverse {
        flex-direction: row-reverse;
    }

    img {
        margin-bottom: 20px;

        @include breakpoint(medium only) {
        }

        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }

    .cell {
        &:first-child {
            @include breakpoint(small only) {
                order: 3;
                padding: 0 40px;
                margin-top: 20px;
            }
        }
    }

    &__small-img {
        &-slide {
            background: center center no-repeat;
            background-size: cover;
            min-height: 280px;

            @include breakpoint(medium only) {
                min-height: 230px;
            }

            @include breakpoint(small only) {
                min-height: 180px;
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 107.69%; // 13:14 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        .slick-prev {
            background: url('img/oetker-collection/left.svg') no-repeat center;
            left: 10px;
            top: 50%;
            transform: translate(0%, -50%);
            background-size: 15px 30px;
            width: 30px;
            height: 30px;
            z-index: 2;

            &::before {
                content: '';
            }
        }

        .slick-next {
            background: url('img/oetker-collection/right.svg') no-repeat center;
            right: 10px;
            top: 50%;
            transform: translate(0%, -50%);
            background-size: 15px 30px;
            width: 30px;
            height: 30px;

            &::before {
                content: '';
            }
        }
    }

    &__big-img {
        min-height: 360px;

        @include breakpoint(medium only) {
            min-height: unset;
        }

        @include breakpoint(small only) {
            min-height: 260px;
        }

        &-slide {
            background: center center no-repeat;
            background-size: cover;
            min-height: 360px;

            @include breakpoint(medium only) {
                min-height: 310px;
            }

            @include breakpoint(small only) {
                min-height: 260px;
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 72.59%; // 135:98 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        .slick-prev {
            background: url('img/oetker-collection/left.svg') no-repeat center;
            left: 10px;
            top: 50%;
            transform: translate(0%, -50%);
            background-size: 15px 30px;
            width: 30px;
            height: 30px;
            z-index: 2;

            &::before {
                content: '';
            }
        }

        .slick-next {
            background: url('img/oetker-collection/right.svg') no-repeat center;
            right: 10px;
            top: 50%;
            transform: translate(0%, -50%);
            background-size: 15px 30px;
            width: 30px;
            height: 30px;

            &::before {
                content: '';
            }
        }
    }

    &__content {
        max-width: 490px;
        margin: 0 auto;

        h3 {
            margin-bottom: 0;
            font-size: rem-calc(15);
            letter-spacing: 0;
            line-height: rem-calc(20);

            @include breakpoint(small only) {
                margin-bottom: 10px;
            }
        }

        @include breakpoint(medium) {
            max-width: none;
        }

        @include breakpoint(small only) {
            text-align: center;
            margin-top: 10px;
        }
    }

    &__text {
        margin-top: 15px;

        @include breakpoint(small only) {
            margin-top: 0;
            padding: 0 30px;
        }

        p {
            margin-bottom: 20px;
        }

        a {
            text-decoration: underline;
            color: #000;

            &:hover {
                color: map-get($brand-colours, group-dark-blue);
            }
        }
    }

    &__link {

        &.cta {
            margin-left: 0 !important;

            &:first-of-type:last-of-type {
                margin-right: 0;
            }
        }
    }
}
