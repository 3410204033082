.grid-location {
    display: none;

    &.active {
        display: block;
    }

    .destinations-map {
        height: 100vh;

        @media screen and (max-width: 899px) {
            /* height: calc(100vh + 50px); */
            height: auto !important;

            .hero {
                height: 100vh;
            }
        }

        @media screen and (min-width: 900px) {
            height: 100%;

            .hero {
                min-height: 700px;
            }
        }

        .hero {
            margin-bottom: 0;
        }

        &__map {
            position: relative;
            width: 100%;
            height: 100vh;
            margin: 0 auto;
            background-color: #587b8d;

            @media screen and (min-width: 900px) {
                height: 100%;
            }

            &-wrapper {
                width: 100%;
                height: 100%;
                margin-top: 0px;

                @media screen and (min-width: 900px) {
                    position: absolute;
                    bottom: 0;
                }

                @media screen and (min-width: 1024px) {
                    margin-top: 180px;
                }

                @media screen and (min-width: 1125px) {
                    margin-top: 0;
                }
            }

            a[href^="https://maps.google.com/maps"] {
                > div {
                    img {
                        opacity: 0;
                    }

                    &:before {
                        content: '';
                        opacity: 0.25;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-image: url('../assets/img/maps/google_blue.png');
                        background-repeat: no-repeat;
                    }
                }
            }

            .cluster > div {
                width: 22px;
                height: 22px;
                line-height: 20px !important;
                border-radius: 22px;
                border: 1px solid transparent;
                cursor: pointer;
                pointer-events: all;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    top: 2px;
                    left: 2px;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    background-color: map-get($brand-colours, group-dark-blue);
                }

                &:hover {
                    border-color: map-get($brand-colours, group-dark-blue);

                    &:before {
                        top: 2px;
                        left: 2px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        &__marker {
            position: absolute;
            width: 370px;
            height: 60px;
            margin-top: 4px;
            pointer-events: none;

            @media screen and (min-width: 900px) {
                margin-top: 0;
            }

            $marker: &;

            &-name,
            &-location {
                position: relative;
                font-family: $body-font-family;
                font-size: 14px;
                text-shadow: #222 0px 0px 1px;
                opacity: 0;
            }

            &-name {
                font-family: $header-font-family;
                text-transform: uppercase;
            }

            &-icon {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 16px;
                height: 16px;
                border-radius: 16px;
                border: 1px solid transparent;
                margin: 5px auto 5px -9px;
                cursor: pointer;
                pointer-events: all;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    background-color: map-get($brand-colours, group-dark-blue);
                }

                &:hover {
                    border-color: map-get($brand-colours, group-dark-blue);

                    &:before {
                        top: 2px;
                        left: 2px;
                        width: 10px;
                        height: 10px;
                    }

                    ~ #{$marker} {
                        &-name,
                        &-location {
                            opacity: 1;
                        }
                    }
                }
            }

            &--hover,
            &--active {
                #{$marker} {
                    &-name,
                    &-location {
                        opacity: 1;
                    }

                    &-icon {
                        border-color: #fff;

                        &:before {
                            top: 2px;
                            left: 2px;
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
        }

        &__links {
            background-color: rgba(255, 255, 255, 0.76);
            display: block;
            position: relative;
            padding: 30px;
            text-align: left;
            margin: 0;
            width: 100%;
            max-width: 400px;

            &-reset {
                display: none;
                position: absolute;
                top: 5px;
                left: 5px;
                height: 30px;
                width: 30px;
                background: url('img/oetker-collection/map-back.svg') center center no-repeat;
                background-size: 20px 20px;
                cursor: pointer;
                font-size: 13px;
                text-indent: 30px;
                text-transform: uppercase;
                color: map-get($brand-colours, group-dark-blue);
            }

            @media screen and (min-width: 900px) {
                height: 100%;
                position: absolute;
                top: 0;
                right: 0px;
            }
        }

        &__link {
            display: flex;
            justify-content: flex-start;
            align-content: flex-start;
            color: #cad0d3;
            text-align: left;
            margin-bottom: 20px;
            cursor: pointer;
            @media screen and (max-width: 899px) {
                color: #000000;
            }
            &:hover,
            &--hover,
            &--active {
                color: #fff;
                @media screen and (max-width: 899px) {
                    color: #000000;
                }
            }

            &-logo {
                width: 40px;
                height: 30px;
                margin-right: 15px;
            }

            &-name,
            &-location {
                font-family: $body-font-family;
                font-size: rem-calc(12);
                line-height: rem-calc(10);
                letter-spacing: 1px;
                color: #000;
            }

            &-name {
                font-family: $header-font-family;
                font-size: rem-calc(19);
                line-height: rem-calc(24);
                margin-bottom: 5px;
            }
        }

        &__popup {
            position: absolute;
            right: 0px;
            top: 0;
            z-index: 2;
            margin: 0px auto;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: none;
            background-color: #fff;
            padding: 30px 10px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

            @media screen and (min-width: 600px) {
                padding: 40px 0;
            }

            @include breakpoint(medium) {
                max-width: 400px;
                margin: 0;
                top: 0;
                right: 0;
            }

            &--active {
                opacity: 1;
                pointer-events: all;
            }

            &-close {
                display: inline-block;
                position: absolute;
                top: 0px;
                left: 0px;
                height: 32px;
                width: 32px;
                z-index: 2;
                background: url(img/oetker-collection/close-white.svg) center center no-repeat;
                background-color: map-get($brand-colours, group-dark-blue);
                background-size: 16px;
            }

            &-slider {
                > .slick-list {
                    margin: 0 20px;
                }

                > .control-arrow {
                    transform: none;
                    width: 15px;
                    height: 23px;
                    top: 62.5px;
                    bottom: auto;
                    margin-top: -23px;
                    z-index: 2;
                    background-position: center center;

                    @media screen and (min-width: 600px) {
                        top: 142.5px;
                    }

                    &.control-prev {
                        left: 30px;
                    }

                    &.control-next {
                        right: 30px;
                    }
                }
            }

            h2 {
                letter-spacing: 1px;
                font-size: rem-calc(24);
            }

            &-image {
                &-slide,
                > a > img {
                    max-height: 125px;
                    margin: 0 auto;

                    @media screen and (min-width: 600px) {
                        max-height: 300px;
                    }
                }
            }

            &-video {
                position: relative;
                width: 75%;
                max-width: 315px;
                padding-bottom: 67.5%; // 10:9 aspect ratio
                margin: 0 auto;

                @media screen and (min-width: 600px) {
                    width: 100%;
                    padding-bottom: 90%; // 10:9 aspect ratio
                }

                .mejs__player {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .mejs__mediaelement {
                    margin-top: 0;
                }

                .mejs__player,
                .mejs__overlay,
                video,
                iframe {
                    max-width: 100%;
                    height: 100% !important;
                }
            }

            &-location {
                h4 {
                    font-size: rem-calc(13);
                    line-height: rem-calc(25);
                    padding-top: 20px;
                    font-family: $header-font-family;
                }
            }

            &-intro {
                font-size: rem-calc(16);
                line-height: rem-calc(30);
                text-align: center;
            }

            &-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding-bottom: 1px;

                @media screen and (min-width: 1024px) {
                    flex-direction: row;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .desktop-map-link {
            display: none;
        }
        .mobile-map-link {
            display: block;
        }
    }

    @media only screen and (min-width: 600px) {
        .mobile-map-link {
            display: none;
        }

        .desktop-map-link {
            display: flex;
        }
    }
}
