/* Foundation mixins */
@import 'util/mixins';

/* Removes default UL list styling and inlines the elements */
@mixin reset-list {
    list-style: none;
    margin: 0;
}

@mixin inline-list {
    display: inline-block;

    > li {
        display: inline-block;
    }
}

@mixin flex-align-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}