/* colours */
$brand-colours: (
    'group-dark-blue': #ADBDB2,
    'group-light-blue': #566e76,
    'dark-gray': #4c4c4c,
    'medium-gray': #535353,
    'light-gray': #373737,
    'lightest-gray': #f4f4f4
);

/* fonts */
$body-font-family: 'Newsreader', sans-serif;
$header-font-family: 'Gill Sans MT Pro', sans-serif;
$blog-font-family: 'wedding-gothic-atf', sans-serif;

