.pre-stay-form {
    max-width: 700px;
    margin: 0 auto 60px;

    @include breakpoint(medium down) {
        padding: 0 15px;
    }

    .form {
        input[type='text'],
        input[type='email'],
        textarea,
        select {
            background-color: #fff;
            border: 1px solid #dedede;
            font-size: 15px;
            line-height: 36px;
            min-height: 36px;
            color: #0a0a0a !important;

            &::placeholder {
                opacity: 1;
                color: #0a0a0a !important;
            }
        }

        input[type='text'],
        input[type='email'],
        select {
            max-width: 425px;
        }

        select {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='94.859' height='94.858' fill='%23adbdb2'%3E%3Cpath d='M17.312 41.757h60.236a2 2 0 1 0 1.565-3.247L48.994.753a2 2 0 0 0-3.126 0L15.748 38.51a1.998 1.998 0 0 0-.238 2.114 1.997 1.997 0 0 0 1.802 1.133zm60.235 11.345H17.312c-.769 0-1.469.439-1.802 1.133a1.995 1.995 0 0 0 .238 2.113l30.119 37.758a2 2 0 0 0 3.126 0L79.11 56.348a1.996 1.996 0 0 0 .237-2.113 1.99 1.99 0 0 0-1.8-1.133z'/%3E%3C/svg%3E");
            background-size: 12px;
            background-position: right -20px center;
            color: #0a0a0a !important;
        }

        .datepickerfield {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 341.333 341.333' fill='%23adbdb2'%3E%3Cpath d='M128 128h85.333v85.333H128zM0 0h85.333v85.333H0zM128 256h85.333v85.333H128zM0 128h85.333v85.333H0zM0 256h85.333v85.333H0zM256 0h85.333v85.333H256zM128 0h85.333v85.333H128zM256 128h85.333v85.333H256zM256 256h85.333v85.333H256z'/%3E%3C/svg%3E");
            background-size: 12px;
            background-position: right -5px center;
            background-origin: content-box;
            background-repeat: no-repeat;
        }

        label,
        p {
            font-size: 15px;
        }

        .radiobuttonlist {
            label {
                padding-right: 20px;

                &:first-child {
                    padding: 0 15px;
                }
            }

            @include breakpoint(small only) {
                padding-left: 15px;

                > label:first-child,
                > span {
                    padding: 0;
                    display: block;
                }
            }
        }

        .checkboxlist {
            label {
                padding-right: 20px;

                &:first-child {
                    padding: 0 15px;
                }
            }

            @include breakpoint(small only) {
                padding-left: 15px;

                > label:first-child,
                > span {
                    padding: 0;
                    display: block;
                }
            }
        }

        button[type='submit'] {
            margin-top: 30px;
            padding: 11px 4px 13px;
            background-color: map-get($brand-colours, group-dark-blue);
            padding: 4px 5px;
            min-height: 40px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: 5px;
                border: solid rgba(0,0,0,0.5);
                border-width: 0 1px;
                background: transparent;
                transition: 0.3s;
                height: 0%;
                width: calc(100% - 10px);
                opacity: 0;
                pointer-events: none;
            }

            &::after {
                content: '';
                position: absolute;
                top: 5px;
                left: 8px;
                background: transparent;
                border: solid rgba(0,0,0,0.5);
                border-width: 1px 0;
                transition: 0.3s;
                height: calc(100% - 10px);
                width: 0%;
                opacity: 0;
                pointer-events: none;
            }

            &:hover {
                background-color: map-get($brand-colours, group-dark-blue);
                border: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
                text-decoration: none !important;

                &::before {
                    height: calc(100% - 20px);
                    opacity: 1;
                }

                &::after {
                    width: calc(100% - 15px);
                    opacity: 1;
                }
            }

            span {
                font-family: $header-font-family;
                font-size: rem-calc(13);
                line-height: rem-calc(13);
                color: #fff;
                text-transform: uppercase;
                padding: 8px 20px;
                background-color: transparent;
                margin: 0 !important;
            }
        }

        .field-validation-error {
            text-align: center;
        }
    }

    .accordion {
        margin-left: 0;
    }

    .accordion-item {
        .accordion-title {
            background-color: #dde1e3;
            color: #0a0a0a;
            font-size: 18px;
            position: relative;
            padding: 10px 20px;
            display: block;

            &:before {
                margin-top: -3px;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #adbdb2;
                position: absolute;
                top: 50%;
                left: 4px;
            }

            &.error {
                background-color: red;
                color: #fff;

                &:before {
                    border-top-color: #fff;
                }
            }
        }

        .accordion-content {
            .innerWide {
                margin-left: -15px;
                margin-right: -15px;

                .col {
                    margin-left: 15px !important;
                    margin-right: 15px !important;
                }
            }
        }

        &.is-active {
            .accordion-title {
                background-color: #adbdb2;
                color: #fff;

                &:before {
                    border-bottom: 6px solid #fff;
                }

                &.error {
                    background-color: #adbdb2;
                    color: #fff;

                    &:before {
                        border-top-color: #adbdb2;
                    }
                }
            }
        }
    }

    .umbraco-forms-field {
        height: auto !important;
        margin-bottom: 20px;

        &.titleanddescription {
            text-align: center;

            h2 {
                font-size: 24px;
                font-weight: 400;
            }
        }

        &.dividerline {
            hr {
                margin: 0;
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top-color: #8c9b9f;
                color: #8c9b9f;
            }
        }

        &.image {
            text-align: center;
        }

        &.richtexteditor {
            text-align: center;
        }

        &.checkbox {
            text-align: center;
        }

        > .umbraco-forms-field-wrapper {

            ul {
                list-style: none;
            }

            a {
                color: map-get($brand-colours, group-dark-blue);
            }

            input, select {
                margin: 0 auto;
            }

            > * {
                @media screen and (max-width: 768px) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .umbraco-forms-container {
        padding-top: 10px;

        &.grid3,
        &.grid4 {
            @include flex();
            @include flex-align(justify, middle);

            @media screen and (min-width: 769px) {
                min-width: 0 !important;
            }

            > div {
                flex: 1 1 auto;
            }
        }

        &.medium-6 {
            .radiobuttonlist,
            .checkboxlist {
                padding-left: 15px;

                label:first-child {
                    padding: 0;
                    display: block;
                }
            }
        }
    }

    .umbraco-forms-tooltip {
        padding: 15px 15px 5px 15px;
        font-style: italic;
    }
}

.pika-table {
    .is-selected .pika-button {
        background-color: #adbdb2;
        box-shadow: none;
        border-radius: 0;
    }

    .is-today .pika-button {
        color: #adbdb2;
    }

    .pika-button {
        border-radius: 0;

        &:hover {
            background-color: rgba(#adbdb2, 0.7);
            border-radius: 0;
        }
    }
}
