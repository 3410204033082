.feature-text {
	max-width: 1290px;
	margin: 0 auto 30px;

	&__title {
		position: relative;

		&::before {
			content: ''; 
			padding: 5px 20px;
			margin: 0 7px;
			background: url('img/oetker-collection/title-line.png') no-repeat center center;
			background-size: 40px 1px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			@include breakpoint(large) {
				background: none;
			}

			@include breakpoint(medium down) {
				left: -50px;
			}
			
			@include breakpoint(small only) {
				display: none;
			}
		}

		&::after {
			content: '';
			padding: 5px 20px;
			margin: 0 7px;
			background: url('img/oetker-collection/title-line.png') no-repeat center center;
			background-size: 40px 1px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);

			@include breakpoint(large) {
				background: none;
			}

			@include breakpoint(medium down) {
				right: -50px;
			}
			
			@include breakpoint(small only) {
				display: none;
			}
		}

		&--no-lines {
			&::before,
			&::after {
				content: none;
			}
		}

		h1 {
			letter-spacing: 1px;
			padding: 0;

			&::before {
				content: ''; 
				padding: 5px 20px;
				margin: 0 7px;
				background: url('img/oetker-collection/title-line.png') no-repeat center center;
				background-size: 40px 1px;

				@include breakpoint(medium down) {
					background: none;
					padding: 0;
					margin: 0;
				}
				
				@include breakpoint(small only) {
					display: none;
				}
			}

			&::after {
				content: '';
				padding: 5px 20px;
				margin: 0 7px;
				background: url('img/oetker-collection/title-line.png') no-repeat center center;
				background-size: 40px 1px;

				@include breakpoint(medium down) {
					background: none;
					padding: 0;
					margin: 0;
				}
				
				@include breakpoint(small only) {
					display: none;
				}
			}
		}

		h2 {

		}
	}

	&__sub-title {

		h2 {
			
		}

		h3 {
			margin-bottom: 5px;

			ul {
				display: inline-flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 0 15px;
				padding: 0;
				list-style: none;
				font-size: rem-calc(13px);
				line-height: rem-calc(13px);
				letter-spacing: 1px;

				li {
					display: inline-block;
					margin: 0 0 5px 0;

					&:after {
						content: '|';
						padding: 0 5px;
						display: inline-block;
						width: rem-calc(15px);
						height: rem-calc(11px);
						margin-right: -rem-calc(14px);
					}

					&.no-pipe-separator {
						&:after {
							color: transparent;
						}
					}

					&:last-child {
						&:after {
							content: none;
						}
					}
				}
			}
		}
	}

	&__text {
		width: 100%;
		max-width: 800px;
		margin: 0 auto 15px;
		font-size: rem-calc(13);
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: #000;
			text-decoration: underline;

			&:hover {
				color: map-get($brand-colours, group-dark-blue);
			}
		}

		ul {
			list-style: none;
			margin: 0;

			li {
				font-family: $body-font-family;
				font-size: rem-calc(15);
				line-height: rem-calc(25);
			}
		}

		ol {
			margin: 0;
			list-style-position: inside;
			font-family: $body-font-family;
			font-size: rem-calc(15);
			line-height: rem-calc(25);
		}
	}

	&__cta {
		.cta {
			@include breakpoint(small only) {
				margin: 0 5px 15px;
			}
		}
	}

	&__accordion {

		.accordionButton {
			margin-top: 15px;
			margin-bottom: 15px;
			text-align: center;
			padding-bottom: 5px;
			background: url(img/oetker-collection/arrow-down-black.svg) no-repeat bottom center;
			background-size: 10px 12px;
			cursor: pointer;

			&:hover {

				h4 {
					color: map-get($brand-colours, medium-gray);
				}
			}

			&.on {
				background: url(img/oetker-collection/arrow-up-black.svg) no-repeat bottom center;
				background-size: 10px 12px;
			}

			&.readmore {
				background: none;

				h4 {
					text-transform: lowercase;
					border-bottom: 1px solid #373737;
					width: max-content !important;
					margin: 0 auto;
					padding-bottom: 6px;
				}
			}

			h4 {
				margin-bottom: 8px;
				letter-spacing: 2px;
				font-size: rem-calc(13);
				line-height: 1;
				text-transform: uppercase;
				font-family: $header-font-family;

			}
		}

		.accordionContent {

			p {
				
			}

			a {
				color: #000;
				text-decoration: underline;

				&:hover {
					color: map-get($brand-colours, group-dark-blue);
				}
			}

			ul {
				margin: 0;
				text-align: left;
				margin-left: 15%;
				margin-bottom: 15px;

				@include breakpoint(small only) {
					margin-left: 15px;
				}

				li {

					font-family: $body-font-family;
					font-size: rem-calc(13);
					line-height: rem-calc(25);
				}
			}
		}
	}

	&__price {
		margin-top: 15px;
		
		p {
			margin-bottom: 0;
			padding-bottom: 30px;
			font-family: $header-font-family;
			font-size:rem-calc(21);
		}

		span {
			font-size:rem-calc(33);
		}
	}

}

.no-hero .feature-text {

	@include breakpoint(small only) {
		margin: 30px auto;
	}
}

.no-hero.hotel .feature-text {

	@include breakpoint(small only) {
		margin-top: 110px;
	}
}


.feature-text__text.cell.small-10.small-offset-1.text-center.layerOn::after {
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	content: "";
	background: rgb(0,0,0);
	background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.90242034313725494) 100%);
	transition: all 0.3 ease-in-out;
}