.breadcrumb {
	margin: 0 auto 30px;
	text-align: center;

	&__container {

		ul {
			list-style: none;
			margin: 0;

			li {
				display: inline-block;
				font-family: $header-font-family;
				font-size: rem-calc(10);
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: map-get($brand-colours, dark-gray);
				margin-right: 5px;

				&:last-child {
					
					a {
						color: map-get($brand-colours, dark-gray);

						&::after {
							content: '';
						}
					}
					
				}

				a {
					color: map-get($brand-colours, group-dark-blue);

					&:hover {
						color: #000;
					}

					&::after {
						content: '|';
						padding-left: 10px;
					}
				}
			}
		}
	}

}

.no-hero .breadcrumb {
	margin-top: 100px;
}

.no-hero.hotel .breadcrumb {
	margin-top: 0px;
}

.no-hero.hotel.destination .breadcrumb {
	margin-top: 30px;
}

.hero--empty + .breadcrumb {
	margin-top: 0;
}