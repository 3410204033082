.offers-details {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto;

	@include breakpoint(small only) {
		margin-top: 20px;
	}

	&__title {
		width: fit-content !important;
		width: -moz-fit-content !important;
		min-width: 220px;
		padding: 0 10px;
		margin: 0 auto 30px auto;

		h1 {
			letter-spacing: 1px;
			padding: 0px;
			margin: 0 20px;
			max-width: 500px;
			position: relative;
			display: block;
			@include breakpoint(large) {
				padding: 0;
			}
		

			&::after {
				content: '';
				padding: 5px 20px;
				margin: 0px;
				background: url('img/oetker-collection/title-line.png') no-repeat center center;
				background-size: 40px 1px;
				position: absolute;
				top: 50%;
				right: -3%;
				transform: translateX(100%);
	
				@include breakpoint(medium down) {
					display: none;
				}
			}

			&::before {
				content: ''; 
				padding: 5px 20px;
				margin: 0px;
				background: url('img/oetker-collection/title-line.png') no-repeat center center;
				background-size: 40px 1px;
				position: absolute;
				top: 50%;
				left: -3%;
				transform: translateX(-100%);

				@include breakpoint(medium down) {
					display: none;
				}
			}
		}
	}

	&__categories {

		h3 {
			margin-bottom: 10px;
			font-size: rem-calc(17);
		}
	}

	&__intro {
		margin-bottom: 30px;

		p {
			font-size: rem-calc(17);
			margin-bottom: 0;
		}

		a {
			color: map-get($brand-colours, group-dark-blue);

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__features {
		margin-bottom: 30px;

		h3 {
			margin-left: 15%;
			font-family: $header-font-family;
			margin-bottom: 20px;

			@include breakpoint(small only) {
				margin-left: 5%;
			}
		}

		ul {
			margin-left: 15%;

			@include breakpoint(small only) {
				margin-left: 5%;
			}

			li {
				font-family: $body-font-family;
				font-size: rem-calc(17);
				line-height: rem-calc(24);
				margin-bottom: 10px;
			}
		}

		p {
			margin-left: 15%;
			margin-bottom: 0;
			font-size: rem-calc(17);
			line-height: rem-calc(24);

			@include breakpoint(small only) {
				margin-left: 5%;
			}
		}

		a {
			font-family: $body-font-family;
			color: #000;
			text-decoration: underline;
			font-size: rem-calc(17);
			line-height: rem-calc(24);

			&:hover {
				color: map-get($brand-colours, group-dark-blue);
			}
		}
	}

	&__cta {
		margin-bottom: 30px;

		p {
			font-family: $header-font-family;
			margin-bottom: 20px;
			font-size: rem-calc(21);

			span {
				font-size: rem-calc(32);
				line-height: rem-calc(20);
				margin-bottom: 20px;
				font-family: $header-font-family;
			}
		}

		&:last-child {
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__terms {
		width: 100%;
		max-width: 680px;
		margin: 0 auto 30px;

		> *:last-child {
			margin-bottom: 0;
		}

		a {
			font-family: $body-font-family;
			color: map-get($brand-colours, group-dark-blue);

			&:hover {
				text-decoration: underline;
			}
		}

		ul {
			list-style: none;
			margin: 0;

			li {
				font-family: $body-font-family;
				color: map-get($brand-colours, light-gray);
				font-size: rem-calc(17);
				line-height: rem-calc(24);
			}
		}
	}
}