.blog-categories {
	width: 100%;
	max-width: 975px;
	margin: 0 auto 60px;
	padding-top: 5px;
	text-align: center;

	&__header {
		border-top: 1px solid #000;
		border-bottom: 4px solid #000;
		padding: 40px 0 0;
		margin-bottom: 70px;
		
		@include breakpoint(medium down) {
			padding: 40px 25px 0;
		}

		@include breakpoint(small only) {
			margin-bottom: 30px;
		}
		
		h2 {
			text-transform: uppercase;
			font-family: $blog-font-family;
			font-size: 13px;
			letter-spacing: 0.16em;
			margin-bottom: 40px;
		}
	}
	
	ul {
		list-style-type: none;
		margin: 0;
		
		@include breakpoint(medium down) {
			padding: 0 25px;
		}
		
		@include breakpoint(large) {
			margin: 0 -40px 0;
		}
	}
	
	li {
		color: #767676;
		font-size: 17px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		margin-bottom: 10px;
		
		@include breakpoint(medium) {
			display: inline-block;
			vertical-align: top;
			margin: 0 30px 10px;
			white-space: nowrap;
		}

		@include breakpoint(small only) {
			text-align: left;
		}
	}
		
	input {
        opacity: 0;
        
        ~ label {
            color: #000;
            font-size: rem-calc(15);
            line-height: rem-calc(21);
            font-family: $body-font-family;
            min-width: 50px;
            padding-left: 15px;
            position: relative;
            cursor: pointer;

            &:hover {
            	color: #767676;
            }
            
            &::before {
                content: '';
                padding: 6px;
                background: url('img/oetker-collection/filter-button.png') no-repeat left center;
                position: absolute;
                left: -5px;
                top: 2px;
            }
            
            &::after {
                content: '';
                padding: 6px;
                background: url('img/oetker-collection/filter-button-active.png') no-repeat left center;
                position: absolute;
                left: -5px;
                top: 2px;
                opacity: 0;
                transition: 0.4s all ease-in-out;
            }
        }

        &:checked ~ label {
            &:after {
                opacity: 1;  
            }
        }
	}

	&__filters {

		.blog-filter-accordion {
			margin-bottom: 30px;
		}

		.accordionButton {
			transition: 0.3s all ease-in-out;

			h4 {
				position: relative;
				display: inline-block;
				font-size: 15px;
				color: #767676;
				text-transform: uppercase;

				&::after {
					content: '';
					position: absolute;
					right: -25px;
					background: url('img/oetker-collection/blog-accordion-cross-grey.svg') no-repeat center center;
					width: 20px;
					height: 100%;
					transition: transform 0.6s ease-in-out;
					transform: rotate(135deg);
				}
			}

			&.on {
				margin-bottom: 20px;

				h4 {

					&::after {
						transform: rotate(0deg);
					}
				}
			}
		}
	}

	&__grid {
		justify-content: center;

		@include breakpoint(small only) {
			margin: 0 auto !important;
		}

		&-item {
			margin-bottom: 50px;

			@include breakpoint(small only) {
				margin: 0 auto 50px !important;
				width: 100% !important;
			}

			&-inner {
				padding: 0 10px;
				//max-width: 280px;
				margin: auto;
			}

			&-content {
				margin-bottom: 15px;
			}
			
			img {
				margin-bottom: 27px;

				@include breakpoint(small only) {
					margin-bottom: 15px;
				}
			}
			
			h3 {
				position: relative;
				display: block;
				color: #000;
				font-family: $blog-font-family;
				font-size: 10px;
				letter-spacing: 0.16em;
				margin-bottom: 20px;
				
				&::after {
					content: '';
					width: 24px;
					height: 1px;
					background: #000;
					display: block;
					margin: 4px auto 0;
				}
			}
			
			h4 {
				font-size: 25px;
				line-height: 29px;
				font-family: $header-font-family;
				margin-bottom: 15px;
				
				a {
					color: inherit;
				}
			}
		
		
			&-cta {
				a {
					color: #000;
					font-style: italic;
					font-family: $header-font-family;
					font-size: 17px;
					display: inline-block;
					padding-bottom: 0;
					border-bottom: 1px solid #000;
					text-transform: lowercase;
				}
			}
		}
	}

	&__load-more {
		border-top: 1px solid #000;
		padding-top: 25px;

		.accordionButton {
			cursor: pointer;

			h4 {
				font-family: $header-font-family;
				color: #000;
				position: relative;
				font-size: 20px;
				line-height: 30px;
				font-style: italic;

				&:after {
					content: '';
					background: url('img/oetker-collection/blog-arrow-down-black.svg') no-repeat bottom center;
					position: absolute;
					bottom: -25px;
					left: 50%;
					width: 50%;
					height: 15px;
					transform: translateX(-50%);
				}
			}

			&.on {

				h4 {
					display: none;
				}
			}
		}

		.accordionContent {
			justify-content: center;
		}
	}
}