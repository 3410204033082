.offers {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto 30px;

    &__grid {
        justify-content: center;
        max-width: 1260px;
        margin: 0 auto;

        &-item {
            &-container {
                width: 100%;
                max-width: 525px;
                text-align: center;
                margin: 0 auto;
                padding: 30px 20px 0;
            }

            &-img {
                .slick-prev {
                    background: url('img/oetker-collection/left.svg') no-repeat center;
                    left: 10px;
                    top: 50%;
                    transform: translate(0%, -50%);
                    background-size: 15px 30px;
                    width: 30px;
                    height: 30px;
                    z-index: 2;

                    &::before {
                        content: '';
                    }
                }

                .slick-next {
                    background: url('img/oetker-collection/right.svg') no-repeat center;
                    right: 10px;
                    top: 50%;
                    transform: translate(0%, -50%);
                    background-size: 15px 30px;
                    width: 30px;
                    height: 30px;

                    &::before {
                        content: '';
                    }
                }
            }

            &-video {
                position: relative;
                width: 100%;
                padding-bottom: 68%; // 97:66 aspect ratio

                .mejs__player {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .mejs__player,
                .mejs__overlay,
                video,
                iframe {
                    max-width: 100%;
                    height: 100% !important;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: solid rgba(map-get($brand-colours, light-gray), 0.2);
                border-width: 0 1px 1px;
            }

            &-text {
                padding-top: 20px;
                
                > a {
                    color: map-get($brand-colours, 'light-gray');
                }

                h2 {
                    margin-bottom: 15px;
                    font-size: rem-calc(24);
                }

                p {
                    font-size: rem-calc(13);
                    font-family: $header-font-family;
                    margin-bottom: 0;
                    padding-bottom: 20px;

                    span {
                        font-size: rem-calc(26);
                    }
                }

                h3 {
                    padding: 0 20px 15px;
                    text-transform: none;
                    font-family: $body-font-family;
                    font-size: 0.9rem;
                    letter-spacing: 0;
                    margin-bottom: 0px;

                    @include breakpoint(medium down) {
                        padding: 0 10px 15px;
                    }
                }

                h4 {
                    font-family: $header-font-family;
                    font-size: rem-calc(20);
                    margin-bottom: 0;
                }

                h5 {
                    font-family: $body-font-family;
                    font-size: rem-calc(13);
                    margin-bottom: 15px;
                }
            }

            &-links {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding-bottom: 20px;
                padding-top: 10px;

                @at-root .offers--horizontal-links #{&} {
                    flex-direction: row;
                }

                a {
                    margin: 0 12px 15px;

                    @include breakpoint(medium down) {
                        margin: 0 12px 15px;
                    }
                }
            }
        }
    }

    &__filters {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;

        @include breakpoint(medium down) {
            max-width: 600px;
        }
    }

    .offers-filter-accordion {
        text-align: center;
        margin-bottom: 10px;
        border: solid rgba(map-get($brand-colours, light-gray), 0.5);
        border-width: 1px 0px;

        .accordionButton {
            cursor: pointer;

            h4 {
                font-family: $body-font-family;
                font-size: 0.9rem;
                color: map-get($brand-colours, medium-gray);
                margin-bottom: 0;
                padding: 10px;

                &::after {
                    background: url('img/oetker-collection/arrow-down-black.svg') no-repeat center;
                    background-size: 10px 6px;
                    content: '';
                    width: 10px;
                    height: 10px;
                    padding: 10px 20px;
                }
            }

            &.offers-type {
                h4 {
                    font-family: $header-font-family;
                    text-align: right;

                    @include breakpoint(small only) {
                        text-align: center;
                    }
                }
            }

            &.offers-hotels {
                h4 {
                    font-family: $header-font-family;
                    text-align: left;
                    position: relative;
                    padding-left: 30px;

                    @include breakpoint(small only) {
                        text-align: center;
                    }

                    &::before {
                        content: '|';
                        position: absolute;
                        top: auto;
                        left: 0;
                    }
                }

                &.small-offset-3,
                &.medium-offset-5 {
                    h4::before {
                        content: none;
                    }
                }

                &--hidden {
                    display: none;
                }
            }

            &.on {
                border-bottom: none;

                h4 {
                    &::after {
                        background: url('img/oetker-collection/arrow-up-black.svg') no-repeat center;
                        background-size: 10px 6px;
                    }
                }
            }
        }

        .accordionContent.offers-hotels-content {
            button.hotel {
                display: inline-block;
            }

            button.type {
                display: none;
            }

            button.all {
                display: inline-block;
            }

            button.set-filters {
                span.apply-filter {
                    color: #fff;
                    padding: 5px;
                    border: 1px solid #fff;
                }
            }
        }

        .accordionContent.offers-type-content {
            button.hotel {
                display: none;
            }

            button.type {
                display: inline-block;
            }

            button.all {
                display: inline-block;
            }

            button.set-filters {
                span.apply-filter {
                    color: #fff;
                    padding: 5px;
                    border: 1px solid #fff;
                }
            }
        }

        .accordionContent.filter-controls {
            padding-top: 0;
        }
    }

    .filter-controls,
    .location-filter-controls {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        padding: 30px 0;

        @include breakpoint(small only) {
            padding: 20px 10px;
            border-top: none;
            text-align: left;
        }

        ul {
            list-style: none;
            margin: 0;

            li {
                display: inline-block;
                margin: 0 20px;

                @include breakpoint(small only) {
                    display: block;
                }

                input {
                    opacity: 0;

                    ~ label {
                        color: map-get($brand-colours, light-gray);
                        font-size: rem-calc(13);
                        line-height: rem-calc(15);
                        font-family: $header-font-family;
                        min-width: 50px;
                        padding-left: 15px;
                        position: relative;

                        &::before {
                            content: '';
                            border: 1px solid map-get($brand-colours, light-gray);
                            padding: 4px;
                            position: absolute;
                            left: 0;
                            top: 0px;
                        }

                        &::after {
                            content: '';
                            border: 1px solid map-get($brand-colours, light-gray);
                            background-color: map-get($brand-colours, group-dark-blue);
                            padding: 4px;
                            position: absolute;
                            left: 0;
                            top: 0px;
                            opacity: 0;
                            transition: 0.4s all ease-in-out;
                        }
                    }

                    &:checked ~ label {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .button {
            color: map-get($brand-colours, light-gray);
            background-color: transparent;
            background: url('img/oetker-collection/filter-button.png') no-repeat left center;
            font-size: rem-calc(15);
            line-height: rem-calc(20);
            font-family: $body-font-family;
            min-width: 50px;
            padding: 10px 10px 10px 20px;
            margin: 0 10px;

            &:hover {
                text-decoration: underline;
            }

            @include breakpoint(small only) {
                width: 50%;
                margin: 0 auto;
                background-position: 5% center;
                padding: 10px 10px 10px 30px;
                text-align: left;
            }

            &.on {
                background: url('img/oetker-collection/filter-button-active.png') no-repeat left center;
                text-decoration: underline;
                color: map-get($brand-colours, dark-gray);

                @include breakpoint(small only) {
                    background-position: 5% center;
                }
            }
        }
    }

    .location-filter-controls {
        position: relative;

        .button {
            &.all {
                /* position below filters */
                position: absolute;
                width: auto;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                background: none;

                /* CTA style */
                font-size: rem-calc(13);
                line-height: rem-calc(25);
                color: #000;
                letter-spacing: 2px;
                font-family: $header-font-family;
                text-decoration: none;
                border-bottom: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
                padding: 0 0 2px;
                margin: 0 0 15px;
                display: inline-block;
                transition: 0.2s ease-in-out;

                &:hover, &:focus {
                    color: map-get($brand-colours, group-dark-blue);
                    font-weight: 600;
                    border-bottom: 2px solid rgba(map-get($brand-colours, group-dark-blue),1);
                    margin-bottom: 14px;
                }
            }
        }
    }
}
