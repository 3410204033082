.header {
	height: 90px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	padding: 0 10px;
	background-color: rgba(0,0,0,0.6);

	&.opened {
		background-color: #fff;
	}



	&__primary-nav {
		padding: 30px 0 0 20px;

		&-burger {
			color: #fff;
			text-transform: uppercase;
			display: block;
			opacity: 1;
			transition: 0.5s opacity ease-in;

			.button {
				background: url('img/oetker-collection/burger.png') no-repeat;
				background-size: 20px 10px;
				background-position: top 2px left 0;
				padding: 0;
				padding-left: 20px;
				padding-bottom: 20px;
				text-transform: uppercase;
				font-size: rem-calc(13);
				line-height: rem-calc(13);

				&:hover, &:focus {
					background-color: transparent;
					outline: none;
				}

				@include breakpoint(large) {
					padding: 0 0 0 30px;
				}

				span {
					font-family: $header-font-family;

					@include breakpoint(medium down) {
						display: none;
					}
				}
			}

			.close-button {
				background: url('img/oetker-collection/close-white.svg') no-repeat;
				background-size: 15px;
				background-position: center left;
				padding: 0;
				padding-left: 15px;
				text-transform: uppercase;
				font-size: rem-calc(14);
				line-height: rem-calc(14);
				position: absolute;
				top: auto;
				left: 25px;
				display: none;
				width: 15px;
				height: 15px;
				cursor: pointer;

				&:hover, &:focus {
					background-color: transparent;
					outline: none;
				}

				@include breakpoint(large) {
					padding: 0 0 0px 15px;
				}

				span {
					font-family: $header-font-family;
					color: #fff;
					margin-left: 5px;

					@include breakpoint(medium down) {
						display: none;
					}
				}
			}

		}
	}

	&__logo {
		text-align: center;
		padding-top: 20px;

		@include breakpoint(small only) {
			padding-top: 25px;
		}

		@include breakpoint(medium) {
			padding-top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		a {

			img {
				max-width: 208px;
				width: 100%;
			}
		}

		.logo {
			width: 100%;
		}

		.logo-dark {
			display: none;
		}
	}

	&__secondary-nav {
		position: relative;
		padding-right: 20px;

		@include breakpoint(small only) {
			padding-right: 0;
		}

		&-language {
			display: inline-block;
			position: relative;
			margin-right: 20px;

			@include breakpoint(medium down) {
				margin-right: 10px;
			}

			.selected-language {
				font-family: $header-font-family;
				font-size: rem-calc(13);
				line-height: rem-calc(13);
				color: #fff;
				text-transform: uppercase;
				cursor: pointer;
				position: relative;
				
				@include breakpoint(small only) {
					min-height: 16px;
				}

				&::after {
					content: "";
					background: url('img/oetker-collection/arrow-down.svg') no-repeat;
					background-size: 8px 5px;
					background-position: center;
					padding-left: 15px;
					width: 8px;
					height: 5px;
					position: absolute;
					top: 3px;
				}
			}

			.language-list {
				display: none;
				position: absolute;
				top: 30px;
				left: 50%;
				transform: translateX(-50%);

				&.open {
					display: block;
					padding: 7px 15px;
					background-color: rgba(0,0,0,0.2);
					text-align: center;
				}

				ul {
					list-style: none;
					margin: 0;
					white-space: normal;

					li {
						line-height: 1;
						padding: 6px 0;

						&:first-child {
							padding-top: 2px;
						}

						&:last-child {
							padding-bottom: 2px;
						}

						a {
							font-family: $header-font-family;
							font-size: rem-calc(13);
							line-height: rem-calc(13);
							color: #fff;
							text-transform: uppercase;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}

		&-links {
			margin-top: 25px;

			&-list {
				display: none;

				@include breakpoint(large) {
					display: inline-block;
				}

				ul {
					list-style: none;
					margin: 0;

					li {
						display: inline-block;
						margin: 0 10px;

						a {
							font-size: rem-calc(13);
							line-height: rem-calc(15);
							color: #fff;
							text-transform: uppercase;
							font-family: $header-font-family;
							padding-bottom: 4px;

							&:hover {
								border-bottom: 1px solid rgba(map-get($brand-colours, light-gray),1)
							}
						}
					}
				}
			}
		}

		&-book {
			display: none;

			@media screen and (min-width: 715px) {
				display: inline-block;
				margin-left: 30px;
			}

			&-container {
				background-color: transparent;
				text-align: center;
				padding: 7px 20px;
				min-width: 92px;
				border: 1px solid #fff;
				position: relative;

				&:hover {
					background-color: map-get($brand-colours, group-dark-blue);
					border: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
				}

				&::before {
					content: '';
					position: absolute;
					top: 10px;
					left: 5px;
					border: solid rgba(0,0,0,0.5);
					border-width: 0 1px;
					background: transparent;
					transition: 0.3s;
					height: 0%;
					width: calc(100% - 10px);
					opacity: 0;
					pointer-events: none;
				}

				&::after {
					content: '';
					position: absolute;
					top: 5px;
					left: 8px;
					background: transparent;
					border: solid rgba(0,0,0,0.5);
					border-width: 1px 0;
					transition: 0.3s;
					height: calc(100% - 10px);
					width: 0%;
					opacity: 0;
					pointer-events: none;
				}

				&:hover {

					&::before {
						height: calc(100% - 20px);
						opacity: 1;
					}

					&::after {
						width: calc(100% - 15px);
						opacity: 1;
					}
				}

				a {
					font-size: rem-calc(13);
					line-height: rem-calc(15);
					color: #fff;
					text-transform: uppercase;
					font-family: $header-font-family;
					padding: 15px 25px;

					&:hover {
						color: map-get($brand-colours, light-gray);
					}
				}
			}
		}
	}

	&__mobile-booking {
		position: fixed;
		bottom: 0;
		left: 0;
		border-bottom: env(safe-area-inset-bottom) solid #fff;
		padding: 5px 5px;
		background-color: map-get($brand-colours, group-dark-blue);
		z-index: 15;
		border-top: 1px solid map-get($brand-colours, group-dark-blue);

		@media screen and (min-width: 715px) {
			display: none;
		}

		&-container {
			text-align: center;
			padding: 5px;

			a {
				display: block;
				font-size: rem-calc(13);
				line-height: rem-calc(13);
				color: map-get($brand-colours, light-gray);
				text-transform: uppercase;
				padding: 6px 15px 4px;
				font-family: $header-font-family;
			}
		}
	}
}

@mixin fixed_header {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	background-color: #fff;
	border-bottom: 1px solid #e6e6e6;

	.header__logo {
		.logo {
			width: 100%;
			display: none !important;
		}

		.logo-dark {
			width: 100%;
			display: block !important;
		}
	}

	.header__primary-nav {

		.header__primary-nav-burger {

			.button {
				background-image: url('img/oetker-collection/burger-black.png');

				span {
					color: map-get($brand-colours, light-gray);
				}
			}

			.close-button {
				background: url('img/oetker-collection/close.svg') no-repeat;
				background-size: 15px;

				span {
					color: map-get($brand-colours, light-gray);
				}
			}
		}
	}

	.header__secondary-nav-language {
		color: map-get($brand-colours, light-gray);

		button {
			color: map-get($brand-colours, light-gray);

			&::after {
				background: url('img/oetker-collection/arrow-down-black.svg') no-repeat;
				background-size: 8px 5px;
				background-position: center;
			}
		}

		.language-list.open {
			text-align: center;
			background-color: #fff;
			border: 1px solid #e6e6e6;

			ul {

				li {

					a {
						color: map-get($brand-colours, light-gray);
					}
				}
			}
		}
	}

	.language-list ul li a {
		color: map-get($brand-colours, light-gray);
	}

	.header__secondary-nav-links ul li a {
		color: map-get($brand-colours, light-gray);
	}

	.header__secondary-nav-book-container {
		background-color: map-get($brand-colours, group-dark-blue);
		position: relative;

		a {
			color: map-get($brand-colours, light-gray);
		}
	}

}

.fixed.header {
	@include fixed_header();
}

// @include breakpoint(small only) {
// 	.header {
// 		@include fixed_header();
// 	}
// }

// @media screen and (orientation: portrait) {
// 	.header {
// 		@include fixed_header();
// 	}
// }

body.no-hero .header {

	position: fixed;
	top:0;
	left:0;
	width: 100%;
	background-color: #fff;

	.header__primary-nav {

		.header__primary-nav-burger {

			.button {
				background: url('img/oetker-collection/burger-black.png') no-repeat;

				span {
					color: map-get($brand-colours, light-gray);
				}
			}

			.close-button {
				background: url('img/oetker-collection/close.svg') no-repeat;
				background-size: 15px;

				span {
					color: map-get($brand-colours, light-gray);
				}
			}
		}
	}

	.header__logo {

		.logo {
			display: none;
		}

		.logo-dark {
			display: block;
			width: 100%;
		}
	}

	.header__secondary-nav-language {
		color: map-get($brand-colours, light-gray);

		button {
			color: map-get($brand-colours, light-gray);

			&::after {
				background: url('img/oetker-collection/arrow-down-black.svg') no-repeat;
				background-size: 8px 5px;
				background-position: center;
			}
		}

		.language-list.open {
			background-color: rgba(255,255,255,1);
			border: 1px solid #e6e6e6;
		}
	}

	.language-list ul li a {
		color: map-get($brand-colours, light-gray);
	}

	.header__secondary-nav-links ul li a {
		color: map-get($brand-colours, light-gray);
	}

	.header__secondary-nav-book-container {
		background-color: map-get($brand-colours, group-dark-blue);

		a {
			color: map-get($brand-colours, light-gray);
		}
	}
}

body.video-slider .header {

	@include breakpoint(small only) {
		@include fixed_header();
	}
}

body.mobile-booking-hide {
	
	.header__mobile-booking {
		display: none !important;
	}
}

.header__mobile-booking.over-footer {
	border-top: 1px solid map-get($brand-colours, dark-gray);
}
