.cta {
	font-size: 0.9rem;
	line-height: rem-calc(15);
	color: #000;
	letter-spacing: 0.1em;
	font-family: $header-font-family;
	position: relative;
	margin: 0 15px 15px;
	display: inline-block;
	transition: 0.3s ease-in-out;

	span {
		line-height: rem-calc(28);
		padding-bottom: 6px;
		border-bottom: 1px solid #000;
		transition: 0.3s ease-in-out;
		color: #000 !important;
	}

	&:hover, &:focus {
		text-decoration: none !important;

		span {
			color: map-get($brand-colours, group-dark-blue) !important;
			border-bottom: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
		}
	}
}

a:focus {
	outline: none;
}