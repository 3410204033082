.hero-booking-widget {
    position: fixed;
    bottom: 0;
    border-bottom: env(safe-area-inset-bottom);
    left: 0;
    z-index: 11;
    width: 100vw;

    @media only screen and (min-width: 900px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: none;
        width: auto;
        max-width: 840px;

        +.scroll.scroll {
            bottom: 120px;
        }
    }

    @media only screen and (min-width: 1050px) {
        +.scroll.scroll {
            bottom: 110px;
        }
    }

    button {
        font-family: $header-font-family;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        white-space: nowrap;
    }

    &__overlay {
        display: none;
        position: absolute;
        bottom: 0;
        left: 50%;
        top: 50%;
        z-index: -1;
        transform: translateX(-50%) translateY(-50%);
        width: 100vw;
        height: 200vh;

        &.active {
            display: block;
        }
    }

    &__calendars {
        display: none;
        justify-content: space-between;
        align-items: center;


        &.active {
            display: flex;
        }

        #Datepickk {
            max-width: none;
            padding: 20px;
            background-color: rgba(map-get($brand-colours, light-gray), 0.8);
            color: #fff;
            font-family: $header-font-family;

            &.multi {

                .d-table {

                    &:before {
                        color: #fff;
                    }
                }
            }

            .d-calendar {
                font-size: rem-calc(13);
            }

            .d-header,
            .d-week,
            .d-month-picker,
            .d-month,
            .d-year-picker ,
            .d-year {
                background-color: transparent;
                color: #fff;
            }

            .d-month-picker,
            .d-year-picker {
                > div {
                    &:hover {
                        color: #fff;
                        background-color: rgba(#adbdb2, .8);
                    }

                    &.current {
                        color: #fff;
                        background-color: #adbdb2;
                    }
                }
            }

            .d-week {
                &:first-child {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-left: 5px;
                }

                > div p {
                    font-size: rem-calc(13);
                    color:#fff;
                }
            }

            .d-header {

                #d-previous {

                    &:before {
                        color: #fff;
                        background-image: url('img/arrow-white.svg');
                    }
                }

                #d-next {

                    &:before {
                        color: #fff;
                        background-image: url('img/arrow-white.svg');
                    }
                }
            }

            .d-tables {
                border: none;
                min-height: 184px;

                &.range:not(.before) input:not(.single):checked+label~label:not(.hidden){
                    color: #fff;
                }

                &.range {
                    .d-table {
                        border: 1px solid #cbc8c0;

                        &:before {
                            font-size: rem-calc(13);
                        }

                        input {
                            & + label {
                                &:before {
                                    background-color: #adbdb2;
                                }

                                &.next,
                                &.prev {
                                    color: #ccc !important;

                                    &:before {
                                        transform: scaleX(0) !important;
                                    }
                                }

                                &.d-hidden {
                                    &:before {
                                        transform: scaleX(0) !important;
                                    }
                                }
                            }

                            &:checked {
                                & + label {
                                    color: #fff;

                                    &:before {
                                        transform: scaleX(1);
                                    }

                                    & ~ label:not(.d-hidden) {
                                        @at-root .selected#{&} {
                                            color: #fff;
                                        }

                                        &:before {
                                            background-color: rgba(#adbdb2, .8);

                                            @at-root .selected#{&} {
                                                transform: scaleX(1);
                                            }
                                        }
                                    }
                                }

                                & ~ input:checked {
                                    & + label {
                                        &:before {
                                            color: #fff;
                                            background-color: #adbdb2;
                                        }

                                        & ~ label {
                                            @at-root .selected#{&} {
                                                color: inherit;
                                            }

                                            &:before {
                                                @at-root .selected#{&} {
                                                    transform: scaleX(0);
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:not(:checked) {
                                & + label.hover {
                                    background-color: #eaeaea;
                                }
                            }
                        }

                        &:first-child {
                            margin-right: 5px;

                            @at-root .selected.first-month-end:not(.first-month-start)#{&}, .selected.first-month-full#{&} {
                                input {
                                    & + label {
                                        &:not(.d-hidden):not(.prev) {
                                            color: #fff;

                                            &:before {
                                                background-color: rgba(#adbdb2, .8);
                                                transform: scaleX(1);
                                            }
                                        }
                                    }

                                    &:checked {
                                        & + label {
                                            &:before {
                                                background-color: #adbdb2 !important;
                                            }

                                            & + input {
                                                & ~ label {
                                                    color: inherit !important;

                                                    &.next,
                                                    &.prev {
                                                        color: #ccc !important;
                                                    }

                                                    &:before {
                                                        transform: scaleX(0) !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            @at-root .selected.first-month-end:not(.first-month-start)#{&} {
                                +.d-table {
                                    label:before {
                                        transform: scaleX(0) !important;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            margin-left: 5px;

                            @at-root .selected.second-month-end:not(.second-month-start)#{&}, .selected.second-month-full#{&} {
                                input {
                                    & + label {
                                        &:not(.d-hidden):not(.next) {
                                            color: #fff;

                                            &:before {
                                                background-color: rgba(#adbdb2, .8);
                                                transform: scaleX(1);
                                            }
                                        }
                                    }

                                    &:checked {
                                        & + label {
                                            &:before {
                                                background-color: #adbdb2 !important;
                                            }

                                            & + input {
                                                & ~ label {
                                                    color: inherit !important;

                                                    &.next,
                                                    &.prev {
                                                        color: #ccc !important;
                                                    }

                                                    &:before {
                                                        transform: scaleX(0) !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-close {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 1;
            cursor: pointer;
            width: 7px;
        }
    }

    &__pickers {
        display: none;
        justify-content: space-around;
        align-items: center;
        padding: 20px;
        color: #fff;
        background-color: rgba(map-get($brand-colours, light-gray), 0.8);
        font-family: $header-font-family;
        font-size: 17px;

        &.active {
            display: flex;
        }

        > div {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            > div:first-child {
                width: 100%;
                margin-bottom: 5px;
                font-family: $header-font-family;
                text-transform: uppercase;
            }
        }
    }

    &__minus {
        display: inline-block;
        width: 19px;
        height: 19px;
        margin: 6px 10px 6px 0;
        background: url('../assets/img/minus-white.svg') no-repeat center center;
        background-size: cover;
    }

    &__plus {
        display: inline-block;
        width: 19px;
        height: 19px;
        margin: 6px 0 6px 10px;
        background: url('../assets/img/plus-white.svg') no-repeat center center;
        background-size: cover;
    }

    &__textbox {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: none;
        color: #fff;
        text-align: center;
        margin: 0;
        appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__summary {
        display: none;
        justify-content: space-around;
        align-items: center;
        // margin-top: 2px;
        color: #fff;
        background-color: rgba(map-get($brand-colours, light-gray), 0.8);
        border-bottom: 1px solid #ccc;

        @media only screen and (min-width: 900px) {
            padding: 5px 20px;
            border-bottom: none;
            display: flex;
        }

        .book-now-container {
            min-width: 100%;
            margin-bottom: 0;

            @media only screen and (min-width: 900px) {
                min-width: 0;
                max-width: 90%;
            }

            .book-now-wrapper {
                width: 100%;
                background-color: transparent;
                border: 1px solid #fff;
                color: #fff;
                min-height: 35px;

                &::before, &::after {
                    width: 0 !important;
                    height: 0 !important;
                }

                &:hover {
                    background-color: #fff;
                    color: #000;

                    &::before, &::after {
                        width: 0 !important;
                        height: 0 !important;
                    }                    
                }



                .book-button {
                    width: 100%;
                    padding: 7px 20px 3px;
                    color: inherit !important;
                    font-family: $header-font-family;
                }
            }
        }
    }

    &__dates {
        display: none;
        position: relative;
        padding-right: 50px;
        margin-right: 70px;
        cursor: pointer;
        color: #fff;
        font-family: $header-font-family;
        text-transform: uppercase;

        @media only screen and (min-width: 900px) {
            display: block;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            background: url('../assets/img/calendar-white.svg') no-repeat center center;
            background-size: cover;
        }
    }

    &__numbers {
        display: none;
        position: relative;
        padding-right: 50px;
        margin-right: 70px;
        cursor: pointer;
        color: #fff;
        font-family: $header-font-family;
        text-transform: uppercase;

        @media only screen and (min-width: 900px) {
            display: block;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            background: url('../assets/img/plus-white.svg') no-repeat center center;
            background-size: cover;
        }
    }
}
