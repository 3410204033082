.footer {
	background-color: map-get($brand-colours, group-dark-blue);
	padding: 60px 5% 80px;

	@include breakpoint(small only) {
		padding: 40px 30px 60px;
	}

	&__nav {
		text-align: center;
		margin-bottom: 60px;

		@include breakpoint(small only) {
			margin-bottom: 30px;
		}

		ul {
			list-style: none;
			margin: 0;

			li {
				display: inline-block;
				margin: 0 10px 5px;

				a {
					color: map-get($brand-colours, light-gray);
					text-transform: uppercase;
					font-family: $body-font-family;
					font-size: rem-calc(14);
					line-height: rem-calc(17);
					letter-spacing: 2px;
					position: relative;
					font-family: $header-font-family;

					&::after {
						content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 0%;
							height: 1px;
							opacity: 0;
							background: map-get($brand-colours, light-gray);
							transition: 0.3s all ease-in-out;
					}

					&:hover {

						&::after {
							width: 100%;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&__contact {
		text-align: center;

		@include breakpoint(small only) {
			padding: 0 5px;
		}

		h4 {
			color: map-get($brand-colours, light-gray);
			font-size: rem-calc(21);
			line-height: rem-calc(24);
			font-family: $header-font-family;
			margin-bottom: 30px;
		}

		p {
			color: map-get($brand-colours, light-gray);
			font-size: rem-calc(13);
			line-height: rem-calc(13);
			margin: 0 0 5px;
			font-family: $body-font-family;
		}

		a {
			font-size: rem-calc(13);
			line-height: rem-calc(13);
			color: map-get($brand-colours, light-gray);
			font-family: $body-font-family;
			position: relative;

			&::after {
				content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0%;
					height: 1px;
					opacity: 0;
					background: map-get($brand-colours, light-gray);
					transition: 0.3s all ease-in-out;
			}

			&:hover {

				&::after {
					width: 100%;
					opacity: 1;
				}
			}

			&.hashtag {
				color: map-get($brand-colours, light-gray);
				font-size: rem-calc(21);
				line-height: rem-calc(24);
				font-family: $header-font-family;
				margin-bottom: 30px;
			}
		}
	}
}