.timeline {
	width: 100%;
	max-width: 1060px;
	margin: 0 auto 30px;
	font-family: $header-font-family;

	button {

		&:focus,
		&:active {
			outline: none;
		}
	}

	&__header {
		text-align: center;

		h2 {
			position: relative;
			display: inline-block;
			font-weight: normal;

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				width: 34px;
				height: 1px;
				background-color: #959595;
			}

			&::before {
				left: -70px;
			}

			&::after {
				right: -70px;
			}
		}
	}

	.slick-list {
		margin: 0;
	}

	&__wrapper {
		position: relative;
	}

	&__items {
		padding: 0 35px;

		@media screen and (min-width: 451px) {
			padding: 0 40px;
		}

		@media screen and (min-width: 641px) {
			padding: 0;
		}

		.slick-arrow {
			position: absolute;
			z-index: 9;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			padding-left: 50%;
			padding-right: 85px;
			text-align: center;
			padding-top: 14px;
			padding-right: 85px;
			font-size: 13px;
			height: 13px;
			color: map-get($brand-colours, light-gray);

			@media screen and (max-width: 640px) {
				padding-left: 0;
				padding-right: 0;
				width: auto;
				height: 52px;
			}

			&::before,
			&::after {
				display: block;
				width: 26px;
				height: 15px;
				margin: 5px auto;
				background-repeat: no-repeat;
				background-size: 26px 15px;
			}

			&.slick-prev {
				&::before {
					content: '';
					background-image: url('img/timeline/timeline-up.svg');
				}

				@media screen and (max-width: 640px) {
					top: 50%;
					bottom: auto;
					right: auto;
					left: 3px;
					padding: 0;

					&::before {
						transform: rotate(-90deg);
						background-position: center;
						height: 25px;
					}
				}
			}

			&.slick-next {
				top: auto;
				bottom: 31px;
				padding-top: 0;
				padding-bottom: 14px;

				&::after {
					content: '';
					background-image: url('img/timeline/timeline-down.svg');
				}

				@media screen and (max-width: 640px) {
					top: 50%;
					bottom: auto;
					left: auto;
					right: 3px;
					padding: 0;

					&::before {
						content: '';
						background-image: url('img/timeline/timeline-down.svg');
						transform: rotate(-90deg);
						background-position: center;
						height: 25px;
					}

					&::after {
						content: none;
					}
				}
			}

			&.slick-disabled {
				opacity: 0.3
			}
		}
	}

	&__image {
		width: 50%;
		float: left;
		background: no-repeat center center;
		background-size: contain;

		@media screen and (max-width: 640px) {
			width: 100%;
			padding-bottom: 100%;
			float: none;
		}
	}

	&__content {
		width: 50%;
		min-height: 539px;
		float: left;
		padding: 40px 120px 40px 35px;
		text-align: center;
		box-sizing: border-box;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;

		@media screen and (max-width: 640px) {
			width: 100%;
			float: none;
			min-height: 0;
			padding: 10px 20px 10px;
		}

		h3 {
			font-weight: normal;
			margin: 0 0 16px;
		}

		h4 {
			font-size: 15px;
			font-weight: normal;
			margin: 0 0 36px;
		}

		p {
			font-size: 13px;
			line-height: 29px;
			color: map-get($brand-colours, light-gray);
		}

		a {
			color: map-get($brand-colours, group-dark-blue);

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__nav {
		position: absolute;
		z-index: 19;
		top: 0;
		right: 0;
		bottom: 0;
		width: 85px;
		min-height: 539px;
		background-color: #EBEBEB;

		@media screen and (max-width: 640px) {
			position: static;
			width: 100%;
			min-height: 0;
			height: 60px;
			margin-bottom: 20px;
		}

		.slick-arrow {
			position: static  !important;
			width: 100% !important;
			height: 77px !important;
			text-indent: -12345px;
			background: url('img/timeline/timeline-nav-up.svg') no-repeat;
			background-position: 23px 58px !important;
			background-size: 15px 8px !important;

			&.slick-next {
				background-image: url('img/timeline/timeline-nav-down.svg');
				background-position: 23px 9px !important;
			}

			&.slick-disabled {
				opacity: 0.3;
			}
		}
	}

	&__year {
		width: 32%;
		background-color: #EBEBEB;
		transition: background-color 0.3s ease-in-out;
		outline: 0 !important;

		button {
			position: relative;
			height: 77px;
			line-height: 77px;
			font-size: 13px;
			padding-left: 37px;
			color: #535353;

			@media screen and (max-width: 640px) {
				padding: 0;
				height: 60px;
				line-height: 60px;
				text-align: center;
				width: 100%;
			}

			&::before {
				content: '';
				position: absolute;
				left: 29px;
				top: -1px;
				bottom: -1px;
				width: 1px;
				background-color: #959595;

				@media screen and (max-width: 640px) {
					left: 50%;
					bottom: 0;
					top: 42px;
				}
			}

			&::after {
				content: '';
				position: absolute;
				top: 34px;
				left: 25px;
				width: 9px;
				height: 9px;
				background-color: #EBEBEB;
				border: 1px solid #959595;
				border-radius: 100%;
				transition: background-color 0.3s ease-in-out;

				@media screen and (max-width: 640px) {
					display: none;
				}
			}
		}



		&.slick-current {
			background-color: #DEDEDE;

			button::after {
				background-color: #959595;
			}
		}
	}

	// Override the slider-specific arrow styles
	.slick-arrow {
		line-height: 1 !important;
	}

	.slick-arrow.slick-prev,
	.slick-arrow.slick-prev:hover,
	.slick-arrow.slick-next,
	.slick-arrow.slick-next:hover {
		transform: none !important;
	}

	&__items .slick-arrow.slick-prev,
	&__items .slick-arrow.slick-prev:hover,
	&__items .slick-arrow.slick-next,
	&__items .slick-arrow.slick-next:hover {
		background-image: none !important;
	}

	.slick-arrow.slick-prev::before
	.slick-arrow.slick-next::before {
		opacity: 1 !important;
	}

	.slick-slider {
		-webkit-user-select: text;
		-khtml-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}
}
