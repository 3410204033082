.accordion {
    background: none;
    list-style: none;

    .accordion-item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .accordion-title {
            &:before {
                content: '';
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid #202020;
            }
        }

        &.is-active {
            .accordion-title {
                &:before {
                    border-bottom: 8px solid #202020;
                    border-top: none;
                }
            }
        }
    }
    
    .accordion-content {
	    &--no-padding {
		    padding-left: 0;
		    padding-right: 0;
		    padding-top: 0;
	    }
    }
}