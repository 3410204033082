.grid-carousel {
    display: none;

    &.active {
        display: block;
        margin: 0 auto;
    }

    &__carousel {
        padding: 0 20px;

        .slick-prev {
            background: url('img/oetker-collection/left-green.svg') no-repeat center;
            left: 5px;
            top: 16%;
            transform: translate(0%, 0%);
            background-size: 15px 30px;
            width: 30px;
            height: 30px;

            &::before {
                content: '';
            }

            &:hover,
            &:focus {
                transform: translate(-5px, 0);
            }
        }

        .slick-next {
            background: url('img/oetker-collection/right-green.svg') no-repeat center;
            right: 5px;
            top: 16%;
            transform: translate(0%, 0%);
            background-size: 15px 30px;
            width: 30px;
            height: 30px;

            &::before {
                content: '';
            }

            &:hover,
            &:focus {
                transform: translate(5px, 0);
            }
        }
    }

    &__title {
        text-align: center;

        h2 {
            letter-spacing: 1px;
            font-size: rem-calc(51);

            @include breakpoint(small only) {
                font-size: rem-calc(42);
            }
        }
    }

    &__item {
        text-align: center;
        margin: 0 20px;

        &:focus {
            outline: none;
        }

        &-img {

            &.border-on {
                border: solid #e6e6e6;
                border-width: 1px 1px 0px 1px;
            }

            .slick-prev {
                background: url('img/oetker-collection/left.svg') no-repeat center;
                left: 10px;
                top: 50% !important;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;
                z-index: 2;

                &::before {
                    content: '';
                }

                &:hover,
                &:focus {
                    transform: translate(-5px, -50%);
                }
            }

            .slick-next {
                background: url('img/oetker-collection/right.svg') no-repeat center;
                right: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;

                &::before {
                    content: '';
                }

                &:hover,
                &:focus {
                    transform: translate(5px, -50%);
                }
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 67.65%; // 34:23 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        &-detail {
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;
            padding: 10px 20px 20px;

            .item-subtitle-wrapper {
                height: auto!important;
                min-height: 66px;
                margin-top: 15px;
                h4 {
                    font-size: rem-calc(13);
                }

                h2 {
                    font-size: rem-calc(24);
                }
            }

            div {
                margin-bottom: 15px;
                min-height: 40px;

                p {
                    font-size: rem-calc(16);
                }
            }



            a.cta {
                display: inline-block;
                margin-bottom: 15px;
            }

            .book-now-container {
                height: 30px;
            }
        }

        &-links {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 0 0 !important;

            @at-root .grid--horizontal-links #{&} {
                flex-direction: row;
            }
        }
    }

    .slick-slider {
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
    }
}
