.blog-text {
	width: 100%;
	max-width: 842px;
	margin: 0 auto 60px;
	padding: 0 25px;
	
	p {
		font-size: 16px;
		line-height: 27px;
	}
}