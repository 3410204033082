.contact-sale {
	max-width: 1290px;
	width: 100%;
	margin: 0 auto 30px;
	justify-content: center;
	height: 100% !important;

	&__item {
		margin-bottom: 30px;
		border: 1px solid #e6e6e6;

		&:last-child {
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
		}

		@include breakpoint(small only) {
			padding-top: 10px;
		}

		&-img {
			background-position: top;
			background-size: cover;
			min-height: 180px;

			@include breakpoint(small only) {
				max-width: 270px;
				width: 100%;
				margin: 0 auto;
				min-height: 280px;
			}
		}

		&-info {
			padding: 5px 0;

			&-title {
				padding: 0 15px;

				@include breakpoint(small only) {
					text-align: center;
				}

				h3 {
					font-family: $header-font-family;
					margin-bottom: 0;
				}

				h4 {
					font-family: $body-font-family;
					font-size: rem-calc(15);
				}
			}

			.module-wrapper {
				margin-bottom: 0;
			}
		}
	}

	.contact-details {
		margin-bottom: 0;

		@include breakpoint(small only) {
			padding-top: 5px;
		}

		&__item {
			padding: 0 15px;

			@include breakpoint(small only) {
				padding: 0;
			}
		}
	}
}

/* fixes July 2023 */

.contact-sales-module-wrapper {
	width: 80vw;
	margin: auto;
}

.contact-sale-wrapper {
	width: 45%;
	margin: 0 2% 0 2%;
	display: inline-block;

	@media screen and (max-width: 63.9375em) {
		width: 100%;
		margin: 0 0 0 0;
	}

}

.contact-sale__item-img {
	width:40%;
	display: inline-block;

	@media screen and (max-width: 63.9375em) {
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
	}
}

.contact-sale__item-info {
	width: 58%;
	display: inline-block;

	@media screen and (max-width: 63.9375em) {
		width: 100%;
		text-align: center;
	}

}

.press-agencies-grid {
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	margin-bottom: 2rem;

	@include breakpoint(medium) {
		max-width: 969px;
		grid-template-columns: 1fr;
		padding-left: 0px;
		padding-right: 0px;
	}

	@include breakpoint(large) {
		max-width: 1290px;
		grid-template-columns: 1fr 1fr;
		padding-left: 0px;
		padding-right: 0px;
	}

	// .contact-sale {
	// 	padding-right: 15px;
	// 	padding-left: 15px;
	// }
}

.contact-details__item-email {
	margin-top: 20px;
}

.press-agencies-grid .contact-sale__item {
    // margin-bottom: 30px;
	width: 100%;
	height: 100%;
}

.press-agencies-grid .contact-sale__item-info {
	width: 100%;
	padding: 40px 20px 20px 20px;
}

.press-agencies-grid .contact-sale {
	// border: 1px solid #e6e6e6;

	@media screen and (max-width: 63.9375em) {
		margin-bottom: 20px;
	}
}

.margin-0-auto {
	margin: 0 auto;
}

.justify-content-center {
	justify-content: center;
}