.intro {
	margin: 0 auto 30px;
	width: 100%;
	max-width: 1260px;
	flex-direction: column;

	&__title {
		
		width: fit-content !important;
		width: -moz-fit-content !important;
		min-width: 220px;
		padding: 0 10px;
		margin: 0 auto 30px auto;
		max-width: 550px;


		// &::before {
		// 	content: ''; 
		// 	padding: 5px 20px;
		// 	margin: 0 7px;
		// 	background: url('img/oetker-collection/title-line.png') no-repeat center center;
		// 	background-size: 40px 1px;
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 0;
		// 	transform: translateX(-100%);

		// 	@include breakpoint(medium down) {
		// 		display: none;
		// 	}
		// }

		// &::after {
		// 	content: '';
		// 	padding: 5px 20px;
		// 	margin: 0 7px;
		// 	background: url('img/oetker-collection/title-line.png') no-repeat center center;
		// 	background-size: 40px 1px;
		// 	position: absolute;
		// 	top: 50%;
		// 	right: 0;
		// 	transform: translateX(100%);

		// 	@include breakpoint(medium down) {
		// 		display: none;
		// 	}
		// }

		h1 {
			letter-spacing: 1px;
			padding: 0px;
			margin: 0 20px;
			max-width: 500px;
			position: relative;
			display: block;
			@include breakpoint(large) {
				padding: 0;
			}

			// &::after, &::before {
			// 	display: none;
			// }
		

			&::after {
				content: '';
				padding: 5px 20px;
				margin: 0px;
				background: url('img/oetker-collection/title-line.png') no-repeat center center;
				background-size: 40px 1px;
				position: absolute;
				top: 50%;
				right: -3%;
				transform: translateX(100%);
	
				@include breakpoint(medium down) {
					display: none;
				}
			}

			&::before {
				content: ''; 
				padding: 5px 20px;
				margin: 0px;
				background: url('img/oetker-collection/title-line.png') no-repeat center center;
				background-size: 40px 1px;
				position: absolute;
				top: 50%;
				left: -3%;
				transform: translateX(-100%);

				@include breakpoint(medium down) {
					display: none;
				}
			}

		}

	}

	&__sub-title {

		h2 {

		}
	}

	&__text {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;

		> *:last-child {
			margin-bottom: 0;
		}

		a {
			text-decoration: underline;
			color: #000;
			
			&:hover {
				color: map-get($brand-colours, group-dark-blue);
			}
		}
	}

}

.no-hero .intro {

	@include breakpoint(small only) {
		margin: 30px auto;
	}
}

.no-hero.hotel .intro {

	@include breakpoint(small only) {
		margin-top: 30px;
	}
}

.no-hero.hotel.destination .intro {

	@include breakpoint(small only) {
		margin-top: 20px;
	}
}