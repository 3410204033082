.cms {
    margin: 0 auto 30px;
    padding: 0 20px;

    @media screen and (min-width: 781px) {
        max-width: 1000px;        
        padding: 0 80px;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    p {
        font-size: rem-calc(17);
        line-height: 21px;
        letter-spacing: 0.5px;
        margin: 20px 0;
    }

    h1, 
    h2, 
    h3, 
    h4,
    h5,
    h6 {
        font-weight: normal;
        margin: 20px 0;
        letter-spacing: 1px;
    }

    h5 {
        font-size: 12px;
        line-height: 25px;
    }

    ul,
    ol {
        margin: 20px;

        li {
            letter-spacing: 0.5px;
            color: map-get($brand-colours, light-gray);
        }
    }

    ul {
        li {
            list-style-type: disc;
        }
    }

    ol {
        li {
            list-style-type: decimal;
        }
    }

    a {
        color: #000;
        text-decoration: underline;
        font-size: rem-calc(17);

        &:hover {
            color: map-get($brand-colours, group-dark-blue);
        }
    }
}