*:focus {
	outline: 0;
}

.module-wrapper {
	max-width: 1290px;
	width: 100%;
	margin: 0 auto 30px;
	align-items: center;

	> .cell > *:last-child {
		margin-bottom: 0;
	}
}

/*** Animated Borders ***/

.rollover-border-top,
.rollover-border-right,
.rollover-border-bottom,
.rollover-border-left {
	position: absolute;
	background-color: map-get($brand-colours, group-dark-blue);
	transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
}

.rollover-border-top {
	top: -1px;
	left: -1px;
	width: 0;
	height: 1px;
}

.rollover-border-right {
	bottom: -1px;
	right: -1px;
	width: 1px;
	height: 0;
}

.rollover-border-bottom {
	bottom: -1px;
	right: -1px;
	width: 0;
	height: 1px;
}

.rollover-border-left {
	top: -1px;
	left: -1px;
	width: 1px;
	height: 0;
}

:hover > .rollover-border-top,
:hover > .rollover-border-bottom {
	width: 100%;
}

:hover > .rollover-border-left,
:hover > .rollover-border-right {
	height: 100%;
}

@keyframes border-delayed {
	0% { border-color: #fff; }
	100% { border-color: rgba(map-get($brand-colours, group-dark-blue),1); }
}

body:lang(ru)  {
	font-family: 'NewsreaderCyrillic';
}


h1 {
	font-family: $header-font-family;
	font-size: rem-calc(34);
	color : map-get($brand-colours, light-gray);
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

h1:lang(ru)  {
	font-family: 'GillSansMTCyrillicProLight';
}

h2 {
	font-family: $header-font-family;
	font-size: rem-calc(24);
	color : map-get($brand-colours, light-gray);
	font-weight: 400;
	line-height: rem-calc(32);
	letter-spacing: 0.05em;
}

h2:lang(ru)  {
	font-family: 'GillSansMTCyrillicProLight';
}

h3 {
	font-family: $header-font-family;
	font-size: rem-calc(24);
	font-weight: 400;
	color: map-get($brand-colours, light-gray);
	letter-spacing: 1px;
	line-height: rem-calc(28);
	margin-top: 0;
	margin-bottom: 35px;
	text-transform: uppercase;
}

h3:lang(ru)  {
	font-family: 'GillSansMTCyrillicProLight';
}

h4 {
	font-family: $body-font-family;
	font-size: rem-calc(21);
	margin-bottom : 5px;
	color: map-get($brand-colours, light-gray);
	font-weight: 400;
	text-transform: uppercase;
}

h4:lang(ru)  {
	font-family: 'NewsreaderCyrillic';
}

h5 {
	color: map-get($brand-colours, light-gray);
	text-transform: uppercase;
}

p {
	font-family: $body-font-family;
	font-size: rem-calc(17);
	color: map-get($brand-colours, light-gray) ;
	line-height: rem-calc(24);
}

p:lang(ru)  {
	font-family: 'NewsreaderCyrillic';
}

// Spacing adjustments for the July 2021 rebrand, since the new font seems to have a different line height
main {
	h1:first-child {
		// padding-top: 12px;
	}

	h2:first-child {
		padding-top: 10px;
	}

	h3:first-child {
		padding-top: 8px;
	}
}

/* BODY */
body {
	> .off-canvas-wrapper {
		> .off-canvas-content {
			> div[data-sticky-container] {
				display: flex;
				flex-direction: column;
				min-height: 100vh;
				justify-content: space-between;

				> main {
					margin-bottom: auto;
				}
			}
		}
	}

	&.no-scroll {
		@include breakpoint(small only) {
			overflow: hidden;
		}
	}
}

.reveal {

	&-overlay {
		overflow-y: auto;
	}
}

/* original cookie banner

.hero-booking-widget {
	padding-bottom: 0px;
	background: transparent;
}

.hero-booking-widget.cookie-on {
	bottom: 0px;
	// padding-bottom: 40px;
	background: #fff;
}


.hero-booking-widget+.scroll.scroll.cookie-on {
	bottom: 140px;
}

.cookie {
    display: none;
	position: fixed;
	width: 100%;
	top: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 9999999999;
    font-size: 13px;
	line-height: 14px;
    @include clearfix();

    @media screen and (min-width: 781px) {
		height: auto;
        font-size: 1.6rem;
		padding: 5px 50px;
	}

    .inner {
        padding: 10px 20px 10px 20px;
    }

    .copy {
		width: 100%;
		margin-bottom: 0;
		text-align: center;

        @media screen and (min-width: 781px) {
			width: 90%;
			text-align: left;
        }
    }

    .btn {
        font-size: 14px;
        display: block;
		margin: 10px auto 0 auto;
		cursor: pointer;

        @media screen and (min-width: 781px) {
            display: inline-block;
            margin: 0;
            vertical-align: top;
            z-index: 1;
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            right: 60px;
            font-size: 14px;
        }
    }
}*/

.booking-overlay-open {
	overflow: auto !important;
}

.mejs__container {
	background: transparent !important;
}