.feature-text-paged {
    width: 100%;
    max-width: 670px;
    margin: 0 auto 30px !important;
    padding: 0 0 30px;

    &__inner {
        .slick-dots {
            bottom: -15px;
        }

        .slick-prev {
             background-image: url('img/oetker-collection/left-green.svg');
        }

        .slick-next {
             background-image: url('img/oetker-collection/right-green.svg');
        }
    }

    &__item {
        &-wrapper {
            padding: 0 20px;

            @at-root .feature-text-paged__item:first-child:last-child #{&} {
                padding: 0;
            }

            h1 {
                letter-spacing: 1px;
                padding: 0 40px;

                @include breakpoint(large) {
                    padding: 0;
                }

                &::before {
                    content: '';
                    padding: 5px 20px;
                    margin: 0 7px;
                    background: url('img/oetker-collection/title-line.png') no-repeat center center;
                    background-size: 40px 1px;

                    @include breakpoint(medium down) {
                        background: none;
                        padding: 0;
                        margin: 0;
                    }
                }

                &::after {
                    content: '';
                    padding: 5px 20px;
                    margin: 0 7px;
                    background: url('img/oetker-collection/title-line.png') no-repeat center center;
                    background-size: 40px 1px;

                    @include breakpoint(medium down) {
                        background: none;
                        padding: 0;
                        margin: 0;
                    }
                }
            }

            a {
                color: #000;
                
                &:hover {
                    text-decoration: none;
                    color: map-get($brand-colours, group-dark-blue);
                }

                &.cta {
                    text-decoration: none !important;
                }
            }

            ul {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 0 15px;
                padding: 0;
                list-style: none;
                font-family: $header-font-family;
                font-size: rem-calc(17px);
                line-height: rem-calc(17px);


                li {
                    display: inline-block;
                    margin: 0 0 5px 0;
                    color: map-get($brand-colours, light-gray);
                    font-size: rem-calc(13);
                    line-height: rem-calc(15);

                    &:after {
                        content: '|';
                        padding: 0 5px;
                        display: inline-block;
                        width: rem-calc(15px);
                        height: rem-calc(11px);
                        margin-right: -rem-calc(14px);
                    }

                    &.no-pipe-separator {
                        &:after {
                            color: transparent;
                        }
                    }

                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    &__accordion {
        .accordionButton {
            margin-top: 15px;
            margin-bottom: 15px;
            text-align: center;
            padding-bottom: 5px;
            background: url(img/oetker-collection/arrow-down-black.svg) no-repeat bottom center;
            background-size: 10px;
            cursor: pointer;

            &:hover {
                h4 {

                }
            }

            &.on {
                background: url(img/oetker-collection/arrow-up-black.svg) no-repeat bottom center;
                background-size: 10px;
            }

            h4 {
                margin-bottom: 8px;
                letter-spacing: 2px;
				font-size: rem-calc(13);
				line-height: 1;
				text-transform: uppercase;
                font-family: $header-font-family;
            }
        }

        .accordionContent {
            p {

            }

            a {
                color: #000;
                text-decoration: underline;

                &:hover {
                  color: map-get($brand-colours, group-dark-blue);
                }
            }

            ul {
                margin: 0;
                text-align: left;
                margin-left: 15%;
                margin-bottom: 15px;

                @include breakpoint(small only) {
                    margin-left: 15px;
                }

                li {
                    font-family: $body-font-family;
                    font-size: rem-calc(17);
                    line-height: rem-calc(24);
                }
            }
        }
    }

    &__price {
        margin-top: 15px;

        p {
            margin-bottom: 0;
            padding-bottom: 20px;
            font-family: $header-font-family;
            font-size: rem-calc(21);
        }

        span {
            font-size: rem-calc(33);
        }
    }
}

.feature-text-paged.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
