.hero {
    font-size: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    margin: 0 auto 30px;
    padding-bottom: 100vh;
    color: #fff;
    overflow: hidden;
    
    @media screen and (max-width: 1024px) and (orientation: portrait) {
        padding-bottom: 80vh;
    }
    
    @media screen and (max-width: 640px) and (orientation: portrait) {
        padding-bottom: 40vh;
    }
    
    &:not(.hero--video) {
        @include breakpoint(small only) {
            padding-bottom: 100%;
        }
    }
    
    &--video {
        padding-bottom: 56.25%;
        
        @include breakpoint(small only) {
            margin-top: 0px;
        }
    }

    &__threesixty,
    &__threesixtymobile {
        font-family: $body-font-family;
        background-color: white;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        color: black;
        text-transform: uppercase;
        cursor: pointer;
        z-index: 9;

        button {
            display: flex;
            font-family: $body-font-family;
            align-items: center;
            cursor: pointer;

            p {
                font-family: "Gill Sans MT Pro", sans-serif;
                text-transform: uppercase;
            }
        }

        p {
            margin: 0px 10px;
        }
    }

    &__threesixty {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__threesixtymobile {
        position: absolute;
        left: 18%;
        top: 95%;
        width: 250px;
        height: 35px;
        transform: translateX(-51%) translateY(-50%);

        @include breakpoint(medium) {
            display: none;
        }
    }
        
    &__image,
    &__video {
        position: absolute;
        top: 0;
        right: 0 ;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include breakpoint(small only) {
            padding-bottom: 56.25%;
            height: 0;
        }
        
        iframe,
        video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include breakpoint(small only) {
                top: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
                left: 0 !important;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .video-wrapper .video-placeholder {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 1s ease-in-out;
        background-size: cover;

        @include breakpoint(small only) {
            padding-bottom: 56.25%;
            height: 0;
        }

        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    .slider-gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(rgba(0, 0, 0, 0.5), 25%, transparent);
        z-index: 9;
        pointer-events: none;
    }

    &.heroHome {
        max-height: none;
        position: relative;
    }

    &--small {
        max-height: 275px;
        background-color: #fff;

        .titleDark {
            margin-top: 75px !important;
            color: #1a3740 !important;
        }
    }

    &--empty {
        opacity: 0;
        min-height: 0;
        height: 55px;
        padding: 0 !important;
        
        @media screen and (min-width: 1024px) {
            height: 125px;
        }
    }

    &--video {
        max-height: calc(56.25vw + 50px); // 16:9 aspect ratio + header height
        min-height: 200px;
        justify-content: flex-start;

        .hero-slider__slide {
            align-items: flex-start;
        }
    }

    &--grid {
        position: relative;
        width: 100vw;
        height: 100%;
        left: 50%;
        margin-left: -50vw;

        @media screen and (min-width: 461px) {
            height: 750px;
        }

        .hero-slider {
            &.hero-slider {
                @media screen and (max-width: 500px) {
                    position: relative;
                }
            }

            .control-arrow {
                background-color: transparent;
            }

            &__slide {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    &-slider {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;

        .slick-dots {
            font-size: 19px;
            bottom: 80px !important;
            z-index: 12;

            @include breakpoint(small only) {
                bottom: 30px !important;
            }

            li {
                margin: 0 9px;

                button {
                    background-color: transparent;
                    border: 1px solid #fff;
                }

                &.slick-active {
                    button {
                        background-color: #fff;
                    }
                }
            }
        }

        &__slide {
            width: 100%;
            height: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            display: flex !important;
            align-items: flex-end;

            &-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 60px;
                z-index: 1;

                @include breakpoint(small only) {
                    padding: 20px 0;
                }
            }

            &-heading {
                max-width: calc(100% - 125px);
                margin: 0 auto 40px auto;
                font-family: $body-font-family;
                font-size: 2.6rem;
                font-weight: normal;
                letter-spacing: 1px;
                line-height: 31px;

                @media screen and (min-width: 461px) {
                    font-size: 64px;
                    line-height: 70px;
                }

                @media screen and (min-width: 550px) {
                    max-width: none;
                }
            }

            &-text {
                width: 100%;
                max-width: calc(100% - 125px);
                margin: 0 auto 40px auto;
                font-size: 24px;

                @media screen and (min-width: 550px) {
                    max-width: 500px;
                }

                p {
                    margin: 0;

                    + p {
                        margin-top: 1rem;
                    }
                }
            }

            .btnWrap {
                font-size: 19px;

                a {
                    &:hover {
                        color: #566e76;

                        &:before {
                            background: url('../assets/img/arrow.svg') no-repeat center center;
                        }
                    }
                }
            }
        }

        &__video {
            display: block;
            position: absolute;
            left: 50%;
            height: 100%;
            margin-top: 50px;

            @media screen and (min-width: 1024px) {
                position: relative;
                left: 0;
                width: 100%;
                height: auto;
                margin-top: 0;
            }
        }

        &__mobileplay {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 70px;
            height: 70px;
            cursor: pointer;
            z-index: 9;

            &:before {
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                margin-left: 15px;
                margin-top: 15px;
                background: url('../assets/img/play.svg') no-repeat center center;
                background-size: cover;
            }

            @media screen and (min-width: 800px) {
                display: none;
            }
        }

        &__muted {
            display: none;
            
            @media screen and (min-width: 1024px) {
                position: absolute;
                right: 40px;
                top: 130px;
                z-index: 10;
                transform: translateX(50%) translateY(-50%);
                width: 50px;
                height: 50px;
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-left: 15px;
                    margin-top: 15px;
                    background: url('../assets/img/mute.svg') no-repeat center center;
                    background-size: cover;
                }

                &--unmuted {
                    &:before {
                        background: url('../assets/img/unmute.svg') no-repeat center center;
                        background-size: cover;
                    }
                }
            }
        }

        .slick-list {
            width: 100%;
            height: 100%;
            margin: 0;
        }

        .slick-track {
            display: flex;
            width: 100%;
            height: 100%;
        }

        .control-arrow {
            transform: none;
            width: 30px;
            height: 46px;
            top: 50%;
            bottom: auto;
            margin-top: -23px;
            z-index: 3;
            background-position: center center;
            background-size: cover;

            &.control-prev {
                left: 30px;
                background-image: url('../assets/img/left.svg');
            }

            &.control-next {
                right: 30px;
                background-image: url('../assets/img/right.svg');
            }
        }

        &--white-arrows {
            .control-arrow {
                &.control-prev {
                    background-image: url('../assets/img/left-white.svg');
                }

                &.control-next {
                    background-image: url('../assets/img/right-white.svg');
                }
            }
        }
    }

    &Img {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: opacity 0.3s;

        &.active {
            opacity: 1;
        }
    }

    &Inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 12%;

        @media screen and (min-width: 960px) {
            flex-direction: row;
        }
        
        @include breakpoint(small only) {
            display: block;
        }

        .link {
            font-family: $header-font-family;
            font-size: rem-calc(51);
            line-height: rem-calc(51);
            color: #fff;
            text-shadow: 1px 1px rgba(map-get($brand-colours, dark-gray), 1);
            margin-left: 3%;
            margin-right: 3%;
            margin-bottom: 30px;

            @media screen and (min-width: 960px) {
                margin-bottom: 0;
                line-height: 1;
            }

            @include breakpoint(small only) {
                font-size: 1.5rem;
                line-height: 1.5rem;
                display: block;
                margin-bottom: 0;
            }
        }
    }

    .logo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        max-width: 100%;
        height: auto;
        padding: 0 30px 0 30px;
        pointer-events: none;
    }

    .titleDefault {
        z-index: 9;
        margin: 0;
        font-size: rem-calc(60);
        font-weight: normal;
        text-shadow: rgba(0, 0, 0, 0.4) -1px 1px;
        letter-spacing: 0.9px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 85px;
        pointer-events: none;
        color: #fff;

        @include breakpoint(small only) {
            padding: 0 15px;
            font-size: rem-calc(40);
            line-height: rem-calc(44);
            bottom: 60px;
        }

        &.titleDark {
            margin-top: -150px;
            color: #000;
        }
    }

    span.titleDefault {
        z-index: 2;
        margin: 0;
        font-size: 100%;
        font-weight: normal;
        text-shadow: rgba(0, 0, 0, 0.4) -1px 1px;
        letter-spacing: 0.9px;
        position: relative;
    }
    
    h1.titleDefault {
        @include breakpoint(medium) {
            bottom: 125px;
        }
    }
    
    .hero-booking-widget ~ h1.titleDefault {
        @include breakpoint(medium) {
            bottom: 175px;
        }
    }

    .scroll {
        display: block;
        position: absolute;
        z-index: 10;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 5px;
        color: #fff;
        letter-spacing: 2px;
        font-family: $body-font-family;
        font-size: rem-calc(13);
        line-height: rem-calc(35);
        text-transform: uppercase;
        background: url('img/oetker-collection/arrow-down.svg') no-repeat center 100%;
        background-size: 30px 10px;
        cursor: pointer;

        @include breakpoint(small only) {
            display: none;
        }
    }

    &__countdown {
        position: absolute;
        left: 0px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        z-index: 9;
        bottom: 0px;

        @include breakpoint (medium) {
            padding-left: 4rem;
            padding-right: 4rem;
            width: auto;
            top: 50%;
            left: 50%;
            bottom: auto;
            -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
        }

        .title-small {
            text-align: center;
            font-size: 1rem;
            color: black;
        }

        .title-large {
            text-align: center;
            font-size: 2rem;
            color: black;
            margin-bottom: 1rem;

            @include breakpoint (large) {
                font-size: 2rem;
            }
        }

        .counters {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0px;

            @include breakpoint (medium) {
                grid-gap: 40px;
            }
        }

        .counter {
            display: flex;
            align-items: center;
            justify-content: center;

            .number {
                font-size: 36px;
                color: #ADBDB2;
                margin-right: 10px;
            }

            .text {
                font-size: 13px;
                text-transform: uppercase;
                color: #373737;
            }
        }
    }
}

.video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    align-items: flex-end;

    > .mejs__player {
        opacity: 0;
        
        &--started {
            opacity: 1;
        }
    }
     
     .mejs__player {   
        &.mejs__container {
            //height: 100% !important;
        }

        mediaelementwrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        iframe {
            width: 100vw !important;
            height: 100vh !important; // 16:9 aspect ratio
            max-height: 100vh !important;
        }

        .mejs__overlay-button {
            display: none;
        }
    }
}

@media only screen and (max-width: 800px) {
    .hero .mejs__container {
        position: absolute;
        top: 0px;
    }

    .video-wrapper {
        display: block;
        height: 70vw;
        min-height: 275px;
        max-height: 500px;
    }
}

@media only screen and (max-width: 640px) {
    .video-wrapper {
        height: auto;
    }
}

.mejs__button > button {
    background: url('../assets/img/mejs-controls.svg');
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    
    margin: 10px 6px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-decoration: none;
    width: 20px;
}

.mejs__play > button {
    background-position: 0 0;
}

.mejs__pause > button {
    background-position: -20px 0;
}

/* Start: Fullscreen */
.mejs__fullscreen-button > button {
    background-position: -80px 0;
}

.mejs__unfullscreen > button {
    background-position: -100px 0;
}

/* End: Fullscreen */

/* Start: Mute/Volume */
.mejs__mute > button {
    background-position: -60px 0;
}

.mejs__unmute > button {
    background-position: -40px 0;
}

.mejs__volume-button {
    position: relative;
}

.mejs__volume-button > .mejs__volume-slider {
    -webkit-backface-visibility: hidden;
    background: rgba(50, 50, 50, 0.7);
    border-radius: 0;
    bottom: 100%;
    display: none;
    height: 115px;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 25px;
    z-index: 1;
}

.mejs__volume-button:hover {
    border-radius: 0 0 4px 4px;
}

.mejs__volume-total {
    background: rgba(255, 255, 255, 0.5);
    height: 100px;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 8px;
    transform: translateX(-50%);
    width: 2px;
}

.mejs__volume-current {
    background: rgba(255, 255, 255, 0.9);
    left: 0;
    margin: 0;
    position: absolute;
    width: 100%;
}

.mejs__volume-handle {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1px;
    cursor: ns-resize;
    height: 6px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 16px;
}

.mejs__horizontal-volume-slider {
    display: block;
    height: 36px;
    position: relative;
    vertical-align: middle;
    width: 56px;
}

.mejs__horizontal-volume-total {
    background: rgba(50, 50, 50, 0.8);
    border-radius: 2px;
    font-size: 1px;
    height: 8px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 16px;
    width: 50px;
}

.mejs__horizontal-volume-current {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    font-size: 1px;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.mejs__horizontal-volume-handle {
    display: none;
}

/* End: Mute/Volume */

.mejs__controls:not([style*='display: none']) {
    background: rgba(255, 0, 0, 0.7);
    background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0));
}

.hotel .hero {
    .slider-gradient {
        opacity: 0;
    }
}

body.no-hero .hero--empty {
    @include breakpoint(small only) {
        margin: 30px auto;
    }
}

body.video-slider .hero {
    @include breakpoint(small only) {
        margin-top: 90px;
    }
}
