.hotel-offers-landing {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto 30px;

    &__tabs {
        justify-content: center;
        max-width: 1260px;
        margin: 0 auto;

        &-item {
            display: inline-block;

            &-container {
                width: 100%;
                max-width: 525px;
                text-align: center;
                margin: 0 auto;
                padding: 30px 20px 0;
            }

            &-img {
                .slick-prev {
                    background: url('img/oetker-collection/left.svg') no-repeat center;
                    left: 10px;
                    top: 50%;
                    transform: translate(0%, -50%);
                    background-size: 15px 30px;
                    width: 30px;
                    height: 30px;
                    z-index: 2;

                    &::before {
                        content: '';
                    }
                }

                .slick-next {
                    background: url('img/oetker-collection/right.svg') no-repeat center;
                    right: 10px;
                    top: 50%;
                    transform: translate(0%, -50%);
                    background-size: 15px 30px;
                    width: 30px;
                    height: 30px;

                    &::before {
                        content: '';
                    }
                }
            }

            &-video {
                position: relative;
                width: 100%;
                padding-bottom: 68%; // 97:66 aspect ratio

                .mejs__player {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .mejs__player,
                .mejs__overlay,
                video,
                iframe {
                    max-width: 100%;
                    height: 100% !important;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: solid rgba(map-get($brand-colours, light-gray), 0.2);
                border-width: 0 1px 1px;
            }

            &-text {
                padding-top: 20px;

                h2 {
                    margin-bottom: 15px;
                    font-size: rem-calc(24);
                }

                p {
                    font-size: rem-calc(20);
                    line-height: rem-calc(29);
                    font-family: $header-font-family;
                    margin-bottom: 0;
                    padding-bottom: 10px;
                }

                h3 {
                    padding: 0 20px 15px;
                    text-transform: none;
                    font-family: $body-font-family;
                    font-size: rem-calc(15);
                    letter-spacing: 0;
                    margin-bottom: 0px;

                    @include breakpoint(medium down) {
                        padding: 0 10px 15px;
                    }
                }
            }

            &-links {
                display: flex;
                flex-wrap: wrap;
                flex-direction: initial;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding-bottom: 20px;
                padding-top: 10px;

                @at-root .offers--horizontal-links #{&} {
                    flex-direction: row;
                }

                a {
                    margin: 0 12px 15px;

                    @include breakpoint(medium down) {
                        margin: 0 12px 15px;
                    }

                    span {
                        font-size: 13px;
                    }
                }

                .book-now-container {
                    display: inline-block;
                }
            }
        }
    }

    .tabs {
        max-width: 490px;
        border-color: rgba(map-get($brand-colours, dark-gray),0.45);

        &-title {
            margin: 0 5px;
            
            a {
                font-family: $header-font-family;
                font-size: 13px;
                text-transform: initial;
                color: map-get($brand-colours, light-gray);
                text-decoration: none;
                padding-left: 20px;
                position: relative;

                &::before {
                    content: '';
                    border: 1px solid #373737;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(calc(-50% - 2px));
                    width: 13px;
                    height: 13px;
                }

                &::after {
                    content: '';
                    background-color: map-get($brand-colours, group-dark-blue);
                    border-radius: 50%;
                    position: absolute;
                    left: 2px;
                    top: 50%;
                    transform: translateY(calc(-50% - 2px));
                    width: 9px;
                    height: 9px;
                    opacity: 0;
                }

            }

            &.is-active {

                a {

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        
        &-content {
            
            .tabs-panel {
                padding: 30px 0;

                &.is-active {
                    display: flex;
                }
            }
        }
    }
}