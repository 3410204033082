.off-canvas {
	display: none;
	margin-top: 90px;
	background-color: #fff;
	opacity: 0;
	transition: all 0.5s ease;
	z-index: 16;

	@include breakpoint(small only) {
		background-color: map-get($brand-colours, group-dark-blue);
	}

	&[data-t] {
		// only show when initialised to prevent FOUC
		display: block;
	}

	&.position-top {
		transform: translateY(-100%);
		height: calc(100% - 90px);

		@include breakpoint(medium) {
			height: auto;
		}
		
		&.is-open {
			transform: translate(0,0);
			display: block;
		}
	}

	&.is-open {
		overflow: auto;
		opacity: 1;

		@include breakpoint(medium) {
			overflow: visible;
		}
		
		@include breakpoint(small only) {
			overflow: scroll;
		}

		@at-root .hotel #{&} {
			@include breakpoint(xlarge) {
				display: none;
			}
		}
	}

	&__inner {
		position: relative;
		height: 100%;
	}

	&__nav {
		text-align: center;
		padding: 10px 30px;

		@include breakpoint(small only) {
			padding: 30px;
		}

		ul {
			list-style: none;
			margin: 0;

			li {
				display: inline-block;
				margin: 0 10px;

				@include breakpoint(small only) {
					display: block;
					margin-bottom: 30px;
				}

				a {
					color: map-get($brand-colours, light-gray);
					text-transform: uppercase;
					font-size: 0.875rem;
					line-height: rem-calc(29);
					letter-spacing: 0.05em;
					font-family: $header-font-family;

					&:hover {
						border-bottom: 1px solid rgba(map-get($brand-colours, light-gray),1);
					}
				}

				&.show-dropdown {

					a {
						border: none;

						&:hover {
							border-bottom: 1px solid rgba(map-get($brand-colours, light-gray),1);
						}
					}
				}

				@include breakpoint(medium) {
					ul {
						position: inherit;
						padding-bottom: 10px;
						margin-top: -5px;
						margin-bottom: 0px;
						background: url('img/oetker-collection/arrow-down-black.svg') no-repeat;
						background-position: center bottom;
						background-size: 25px 7px;
						transition: all 0.4s ease-in;
					}

					&.show-dropdown {

						ul {
							background: url('img/oetker-collection/arrow-up-black.svg') no-repeat;
							background-position: center bottom;
							background-size: 25px 7px;

							.off-canvas__sub-nav-dropdown-wrapper {
								display: inline-flex;
							}
						}
					}
				}
			}
		}
	}

	&__sub-nav {
		&-dropdown {
			width: 100%;
			background: rgba(0,0,0,0.60);
			padding: 30px;
			margin: 0 auto;

			&-wrapper {
				display: none;
				position: absolute;
				top: 28px;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				padding-top: 25px;
				width: 100%;
			}

			&-close {
				display: none;
				position: absolute;
				right: 10px;
				top: 35px;
				border: none;
				background: url('img/oetker-collection/close-white.svg') no-repeat;
				background-size: 15px;
				background-position: center left;
				width: 15px;
				height: 15px;

				&:hover, &:focus {
					background-color: transparent;
					outline: none;
				}

				@at-root .show-dropdown--with-close #{&} {
					display: inline-block;
				}
			}

			&-container {
				width: 100%;
				height: 30px;
				background-color: transparent;
			}

			&-left {
				border-right: 1px solid #fff;
				text-align: left;

				li {
					margin: 0 0 10px 0;

					a {
						text-transform: unset !important;
						color: #fff !important;
						font-family: $header-font-family;

						&:hover, &:focus {
							border-bottom: 1px solid #fff !important;
						}
					}

					p {
						font-family: $body-font-family;
						color: map-get($brand-colours, light-gray);
						margin-bottom: 0;
						font-size: rem-calc(11);
						letter-spacing: 0.05em;
					}
				}
			}

			&-right {
				text-align: left;

				li {
					margin: 0 0 10px 40px !important;

					a {
						text-transform: unset !important;
						color: #fff !important;
						font-family: $header-font-family;

						&:hover, &:focus {
							border-bottom: 1px solid #fff !important;
						}
					}

					p {
						font-family: $header-font-family;
						color: map-get($brand-colours, light-gray);
						font-weight: 400;
						margin-bottom: 0;
						font-size: rem-calc(11);
						letter-spacing: 0.05em;
					}
				}
			}
		}
	}

	&__secondary {
		background-color: #fff;
		text-align: center;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		ul {
			list-style: none;
			margin: 0;

			li {
				display: inline-block;
				margin: 0 30px;
				padding: 18px 0;

				a {
					color: map-get($brand-colours, light-gray);
					font-size: rem-calc(13);
					line-height: rem-calc(18);
					text-transform: uppercase;
					font-family: $header-font-family;
				}
			}
		}
	}
}

.js-off-canvas-overlay.is-visible {
	opacity: 0;
}