.advent-micro header  {
    text-align: center;
    min-height: 130px;
}

.advent-micro header .logo {
    display: inline-block;
    padding: 30px 0 0px 0;

    @include breakpoint(small only) {}
    padding: 15px 0 10px 0;
}

.advent-micro header .logo img {
    height: 64px;
}

.advent-micro header .share {
    position: absolute;
    right: 20px;
    top: 30px;
    text-align: left;
    width: 200px;
    z-index: 5;
}

.advent-micro header .share li {
    display: inline-block;
    padding: 0 0 0 10px;
    font-family: $header-font-family;
    font-size: 13px;
    line-height: 24px;
    font-weight: 300;
    text-transform: uppercase;
    color: map-get($brand-colours, light-gray);
}

.advent-micro .advent {
    position: relative;
    max-width: 1260px;
    margin: auto;
    z-index:1;
}

.advent-micro .advent .illustration {
    max-width: 1260px;
    width: 100%;
    height: auto;
    max-height: 861px;
}

.advent-micro .advent .baubles .bauble {
    /* border: 1px solid blue;  */
    position: absolute;
    z-index: 2;
    height: auto;
    border-radius: 100%;
    transition: 0.5s all ease-in-out;
}

.advent-micro .advent .baubles .bauble.active:hover {
    transform: scale(1.5);
    z-index: 3;
}


/* baubles */

.day-1 {
    left: 55.6%;
    top: 60.7%;
    width: 8.18%;
    height: auto;
}

.day-2 {
    left: 12.32%;
    top: 8.0%;
    width: 8.1%;
}

.day-3 {
    left: 31.3%;
    top: 13.65%;
    width: 8.1%;
}

.day-4 {
    left: 22%;
    top: 1.93%;
    width: 8.1%;
}

.day-5 {
    left: 41.8%;
    top: 1.25%;
    width: 8.1%;
}

.day-6 {
    left: 38.5%;
    top: 60.88%;
    width: 8.1%;
}

.day-7 {
    left: 13.15%;
    top: 52.6%;
    width: 8.1%;
}

.day-8 {
    left: 72.4%;
    top: 7.9%;
    width: 7.8%;
}

.day-9 {
    left: 83%;
    top: 2.7%;
    width: 7.6%;
}

.day-10 {
    left: 81.60%;
    top: 21.40%;
    width: 7.3%;
}

.day-11 {
    left: 79.9%;
    top: 55.9%;
    width: 6.6%;
}

.day-12 {
    left: 13.2%;
    top: 26.5%;
    width: 7%;
}

.day-13 {
    left: 47.4%;
    top: 21.4%;
    width: 8.1%;
}

.day-14 {
    left: 91.1%;
    top: 2.7%;
    width: 7.4%;
}

.day-15 {
    left: 88.4%;
    top: 32.4%;
    width: 7.8%;
}

.day-16 {
    left: 4.1%;
    top: 54.5%;
    width: 7.2%;
}

.day-17 {
    left: 64.45%;
    top: 18.75%;
    width: 8.3%;
    height: auto;
}

.day-18 {
    left: 27.6%;
    top: 55.5%;
    width: 7.6%;
}

.day-19 {
    left: 65.9%;
    top: 55.45%;
    width: 7.4%;
}

.day-20 {
    left: 54.9%;
    top: 9.67%;
    width: 8.18%;
}


.day-21 {
    left: 88.4%;
    top: 55.6%;
    width: 6.8%;
}

.day-22 {
    left: 3.5%;
    top: 1.65%;
    width: 9.2%;
}

.day-23 {
    left: 3.6%;
    top: 34%;
    width: 9.4%;
}

.day-24 {
    left: 45.7%;
    top: 59.6%;
    width: 9.9%;
}

/* advent main copy */

.advent-micro main {
    text-align: center;
}

.advent-micro h1 {
    font-family: $header-font-family;
    font-size: 34px;
    color: map-get($brand-colours, light-gray);;
    letter-spacing: 1px;
    line-height: 36px;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    display: inline-block;

    &::before {
        content: ''; 
        padding: 5px 20px;
        margin: 0 7px;
        background: url('img/oetker-collection/title-line.png') no-repeat center center;
        background-size: 40px 1px;
        position: absolute;
        top: 50%;
        left: -60px;
        transform: translateY(-50%);

        @include breakpoint(small only) {
            background: none;
        }
    }

    &::after {
        content: '';
        padding: 5px 20px;
        margin: 0 7px;
        background: url('img/oetker-collection/title-line.png') no-repeat center center;
        background-size: 40px 1px;
        position: absolute;
        top: 50%;
        right: -60px;
        transform: translateY(-50%);

        @include breakpoint(small only) {
            background: none;
        }
    }
}

.advent-micro .divider {
    display: inline-block;
    padding: 20px 0 40px 0;
}

.advent-micro .intro {
    margin: 0 auto 30px;
    font-size: 17px;
    line-height: 24px;
    font-family: $body-font-family;
    color: map-get($brand-colours, light-gray);
}

.advent-micro .highlight-panel {
    margin: 0 auto 65px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e6e6e6;
}

.advent-micro .highlight-panel img {
    display: inline-block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.advent-micro .highlight-panel .text {
    display: inline-block;
    position: relative;
}

.advent-micro .highlight-panel .text-inner {
    padding: 0 5% 0 5%;
    margin: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.advent-micro .highlight-panel .text-inner h2 {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.05em;
    font-family: $header-font-family;
    color: map-get($brand-colours, light-gray);
}

.advent-micro .highlight-panel .text-inner p {
    font-size: 16px;
    line-height: 24px;
    color: map-get($brand-colours, light-gray);
    font-family: $body-font-family;
}


.advent-micro footer {
    margin: 150px 0 0 0;
    background-color: #1A3740;
    color: #A6A4A3;
    font-size: 14px;
    line-height: 16px;
}

.advent-micro footer, .advent-micro footer p, .advent-micro footer a {
    font-weight: 400;
}

.advent-micro footer .share {
    width: 100%;
    background-color: #0B272F;
    padding: 20px 0 20px 0;
    text-align: center;
    margin: 0 0 30px 0;
}

.advent-micro footer .share li {
    display: inline-block;
    padding: 0 5px 0 5px;
}

.advent-micro footer .lwh {
    padding: 40px 0 0 0;
}

.advent__info {

    @include breakpoint(small only) {
        padding: 0 20px;
    }
}

/* snow */


@keyframes animatedBackground {
from {
    background-position: bottom; }

to {
    background-position: top; }
}

@-webkit-keyframes animatedBackground {
from {
    background-position: bottom;}

to {
    background-position: top; }
}


.advent-micro .snow {
    width: 90vw;
    height: 90vh;
    background-image: url('../assets/img/advent-micro/snow.png');
    background-repeat: repeat;
    background-position: bottom;
    position: absolute;
    z-index:2;
    pointer-events: none;

    -webkit-animation: animatedBackground 10s linear infinite;
-moz-animation: animatedBackground 10s linear infinite;
animation: animatedBackground 10s linear infinite;
}


.zoom {
    display: none;
}



/* Advent Detail page */

.advent-micro .advent-slider {
    margin: 0px 0 30px 0;
}

.advent-micro .form .intro p {
    padding: 40px 0 40px 0;
    font-size: 16px;
    line-height: 26px;
}

.advent-micro .form form .intro p {
    padding: 20px 0 20px 0;
    font-size: 20px;
    line-height: 34px;
}


.advent-micro .form {
    width: 70%;
    max-width: 1260px;
    margin: auto;
    font-size: 16px;
    line-height: 26px;
}

.advent-micro .form input {
    background: #fff;
    border: 1px solid #DDE1E3;
    width: 100%;
}

.advent-micro .form {
    color: #1A3740;
    font-size: 16px;
    line-height: 26px;
}

.advent-micro .form .row {
    padding: 0 0 20px 0;
}

.advent-micro .form .half-row {
    text-align: left;
}
.advent-micro .form .half-row input {
    width: 49%;
    display: inline-block;
}

.advent-micro .form .half-row .left {
    margin: 0 1% 0 0;
}

.advent-micro .form .half-row .right {
    margin: 0 0 0 0;
}

.advent-micro .form ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #1A3740;
    font-size: 16px;
    line-height: 16px;
}
.advent-micro .form ::-moz-placeholder { /* Firefox 19+ */
    color: #1A3740;
    font-size: 16px;
    line-height: 16px;
}
.advent-micro .form :-ms-input-placeholder { /* IE 10+ */
    color: #1A3740;
    font-size: 16px;
    line-height: 16px;
}
.advent-micro .form :-moz-placeholder { /* Firefox 18- */
    color: #1A3740;
    font-size: 16px;
    line-height: 16px;
}

.advent-micro .form .checkbox ul {
    text-align: left;
}

.advent-micro .form .checkbox ul li {
    display:inline-block;
    text-align: left;
}

.advent-micro .form .checkbox ul li label, .advent-micro .form .checkbox ul li input {
    display: inline-block;
    width: auto;
}

.advent-micro .form .checkbox ul li input {
    margin: 0 0 0 0;

}

.advent-micro .form .checkbox ul li label {
    padding: 0 30px 0 30px;
}

.advent-micro .form input[type=checkbox]+label {
    position: relative;
    display: block;
    padding-left: 28px;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
}

.advent-micro .form input[type=checkbox] {
    /* position: absolute;
    opacity: 0; */
    visibility: hidden;
}

.advent-micro .form input[type=checkbox]+label::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #DDE1E3;
}

.advent-micro .form input[type=checkbox]+label::after {
    position: absolute;
    content: '';
    top: 5px;
    left: 2px;
    width: 10px;
    height: 10px;
    background: #DDE1E3;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.advent-micro  .form input[type=checkbox]:checked+label::after {
    opacity: 1;
}

.advent-micro  .form  fieldset {
    border: 0;
}

.advent-micro .gdpr-row {
    border-top: 1px solid #DDE1E3;
    padding: 30px 0 50px 0!important;
    margin: 30px 0 0 0!important;
}

.advent-micro input.submit {
    color: #fff;
    background: #1a3740;
    padding: 15px 50px 14px 50px;
    outline: 1px solid #fff;
    outline-offset: -5px;
    width: auto;
    border: 0px;
    display: inline-block;
    font-weight: 200;
    font-size: 16px;
    line-height: 16px;
    margin-top: 50px;
    cursor: pointer;
}

/* content template */

.advent-micro .sorry {
    padding: 80px 0 60px 0;
}

.advent-micro .sorry h2 {
    font-family: garamond-premier-pro-display, serif;
    font-size: 34px;
    color: #000;
    letter-spacing: 1px;
    line-height: 34px;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 200;
    padding: 0 0 40px 0!important;
}


.advent-micro .sorry .more, .advent-micro .sorry .more:visited, .advent-micro .sorry .more:hover, .advent-micro .sorry .more:active {
    font-size: 20px;
    text-decoration: none;
    color: #1A3740;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #C2CDD0;
}

.advent-micro .sorry .more::before {
content: "> ";
}


/* Advent Question */

.advent__question {
    margin-bottom: 60px;

    h4 {
        text-transform: initial;
        font-family: $header-font-family;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.05em;
        margin-bottom: 30px;
    }
}

.advent___customer {
    margin-bottom: 60px;

    h4 {
        text-transform: initial;
        font-family: $header-font-family;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.05em;
        margin-bottom: 30px;
    }
}

.advent__form {
    text-align: left;
    margin-bottom: 60px;

    ul {
        margin: 0 auto 30px;
        list-style: none;
        text-align: center;

        li {
            display: inline-block;
            padding-right: 60px;

            @include breakpoint(small only) {
                display: block;
                padding-right: 0;
                margin-bottom: 20px;
            }

            &:last-of-type {
                padding-right: 0;
            }   
        }
    }

    input[type="text"],
    input[type="email"] {
        width: 100%;
        margin-bottom: 20px;
        color: map-get($brand-colours, light-gray);
        font-family: $body-font-family;
        font-size: 16px;
        padding: 7px;

        &::placeholder {
            color: map-get($brand-colours, light-gray);
            font-family: $body-font-family;
            font-size: 16px;
            text-transform: none !important;
        }
    }

    label {
        font-family: $body-font-family;
        font-size: 20px;
        line-height: 30px;
        position: relative;
        color: map-get($brand-colours, light-gray);
    }

    input[type="radio"] {
        /* position: absolute; 
        opacity: 0;
        top: 5px;
        left: 0;
        z-index: 1; */
        visibility: hidden;
        
        ~ label {
            position: relative;
            padding-left: 20px;
            margin-left: 0;
            cursor: pointer; 
            line-height: 1;
            margin-bottom: 15px;
            
            &::before,
            &::after {
                content: '';
                position: absolute; 
            }
            
            &::before {
                top: 2px;
                left: 0;
                width: 11px;
                height: 11px;
                border: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
                border-radius: 50%;
            }
            
            &::after {
                top: 4px;
                left: 2px;
                width: 7px;
                height: 7px;
                content: '';
                opacity: 0;
                background: map-get($brand-colours, group-dark-blue);
                transition: opacity 0.3s ease-in-out;
                border-radius: 50%;
            }
            
            a {
                color: inherit;
                text-decoration: underline;
                transition: opacity 0.3s ease-in-out;
                
                &:hover,
                &:active {
                    opacity: 0.7;
                }
            }
        }
        
        &:checked ~ label {
            &:after {
                opacity: 1;  
            }
        }
    }

    input[type="checkbox"] {
        position: relative; 
        opacity: 0;
        top: 5px;
        left: 0;
        z-index: 1;
        width: 1px;
        
        ~ label {
            position: relative;
            padding-left: 20px;
            margin-left: 0;
            cursor: pointer; 
            line-height: 1;
            margin-bottom: 15px;
            font-size: 15px;
            
            &::before,
            &::after {
                content: '';
                position: absolute; 
            }
            
            &::before {
                top: 2px;
                left: 0;
                width: 11px;
                height: 11px;
                border: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
                border-radius: 50%;
            }
            
            &::after {
                top: 4px;
                left: 2px;
                width: 7px;
                height: 7px;
                content: '';
                opacity: 0;
                background: map-get($brand-colours, group-dark-blue);
                transition: opacity 0.3s ease-in-out;
                border-radius: 50%;
            }
            
            a {
                color: inherit;
                text-decoration: underline;
                transition: opacity 0.3s ease-in-out;
                
                &:hover,
                &:active {
                    opacity: 0.7;
                }
            }
        }
        
        &:checked ~ label {
            &:after {
                opacity: 1;  
            }
        }
    }

    &-submit {
        display: inline-block;
        background-color: map-get($brand-colours, group-dark-blue);
        position: relative;
        text-align: center;
        padding: 0;
        min-width: 92px;
        left: 50%;
        transform: translateX(-50%);

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 5px;
            border: solid rgba(0,0,0,0.5);
            border-width: 0 1px;
            background: transparent;
            transition: 0.3s;
            height: 0%;
            width: calc(100% - 10px);
            opacity: 0;
            pointer-events: none;
        }
        
        &::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 8px;
            background: transparent;
            border: solid rgba(0,0,0,0.5);
            border-width: 1px 0;
            transition: 0.3s;
            height: calc(100% - 10px);
            width: 0%;
            opacity: 0;
            pointer-events: none;
        }
        
        &:hover {
            
            &::before {
                height: calc(100% - 20px);
                opacity: 1;
            }
            
            &::after {
                width: calc(100% - 15px);
                opacity: 1;
            }
        }

        input[type="submit"] {
            min-width: 92px;
            box-shadow: none;
            border: none;
            background: transparent; 
            color: map-get($brand-colours, light-gray);
            padding: 9px 20px;
            cursor: pointer;
        }
    }
}

/* media queries */


@media only screen and (max-width: 1260px) {
    .advent-micro .highlight-panel .text-inner p, .advent-micro .highlight-panel .text-inner h2, .advent-micro .highlight-panel .text-inner a, .advent-micro .highlight-panel .text-inner a:visited, .advent-micro .highlight-panel .text-inner a:hover, .advent-micro .highlight-panel .text-inner a:active {
        font-size: 16px;
    }
}

@media only screen and (max-width: 990px) {
    .advent-micro h1 {
        margin: 0 30px;
    }
}

@media only screen and (max-width: 960px) {



    .advent-micro header .share {
    position: relative;
    right: 0px;
    top: 0px;
    text-align: right;
    width: 100vw;
    padding: 10px 0 10px 0;
    border-bottom:1px solid #C2CDD0;
    display: block;
    margin: 0;
    }

    .advent-micro .form .half-row input {
    width: 100%;
    display: block;
    }

    .advent-micro .form .half-row .left {
        margin: 0 0 20px 0;
    }

    .zoom {
    display: block;
    margin: auto;
    padding: 20px 0 20px 0;
    }

    .advent-micro .highlight-panel {
        padding: 0px 0px 30px 0px;
    }

.advent-micro .highlight-panel img, .advent-micro .highlight-panel .text {
    width: 100%;
    display: block;
    clear: both;
}

.advent-micro .highlight-panel .text-inner {
        position: relative;
        top: 0%;
        -ms-transform: translateY(0%);
        transform: translateY(0%);
        padding: 5%;
    }
}