.iframe {
	padding: 40px;
	text-align: center;
	position: relative;

	@include breakpoint(small only) {
		padding: 40px 10px;
	}

	iframe {
		margin: 0 auto;
		width: 100%;
	}

	.close-button {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 25px;
		height: 25px;
		background: url('img/oetker-collection/close.svg') no-repeat center center;
		background-size: 20px 20px;
	}

	&-open {
		margin: 0 auto 30px;
		text-align: center;
	}

	&--narrow {
		iframe {
			width: auto;
		}
	}

}