@font-face {
    font-family: 'Gill Sans MT Pro';
    src: url('fonts/gill-sans/GillSansMTPro-BookItalic.woff2') format('woff2'),
        url('fonts/gill-sans/GillSansMTPro-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans MT Pro';
    src: url('fonts/gill-sans/GillSansMTPro-Book.woff2') format('woff2'),
        url('fonts/gill-sans/GillSansMTPro-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'Gill Sans MT Pro';
    src: url('fonts/gill-sans/GillSansMTPro-Medium.woff2') format('woff2'),
        url('fonts/gill-sans/GillSansMTPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans MT Pro';
    src: url('fonts/gill-sans/GillSansMTPro-MediumItalic.woff2') format('woff2'),
        url('fonts/gill-sans/GillSansMTPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rongel Regular';
    src: url('fonts/rongel/Rongel-Regular.woff2') format('woff2'),
        url('fonts/rongel/Rongel-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rongel Regular';
    src: url('fonts/rongel/Rongel-RegularItalic.woff2') format('woff2'),
        url('fonts/rongel/Rongel-RegularItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Newsreader';
    src: url('fonts/newsreader/Newsreader-Regular.woff2') format('woff2'),
        url('fonts/newsreader/Newsreader-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Newsreader';
    src: url('fonts/newsreader/Newsreader-Italic.woff2') format('woff2'),
        url('fonts/newsreader/Newsreader-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ambiance BT W01 Regular';
    src:
      url('../assets/fonts/masterpiece-estates/AmbianceBTW01-Regular.woff') format('woff'),
      url('../assets/fonts/masterpiece-estates/AmbianceBTW01-Regular.woff2') format('woff2');
    font-display: swap;
  }



@font-face {
    font-family: 'Cormorant';
    src: url('fonts/cormorant/Cormorant-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant';
    src: url('fonts/cormorant/Cormorant-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    }

@font-face {
    font-family: 'Cormorant';
    src: url('fonts/cormorant/Cormorant-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    }


@font-face {
    font-family: 'Questrial';
    src: url('fonts/questrial/Questrial-Regular.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'NewsreaderCyrillic';
  src: url('fonts/Russian/NewsreaderCyrillic/NewsreaderCyrillic.woff') format('woff');
  src: url('fonts/Russian/NewsreaderCyrillic/NewsreaderCyrillic.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GillSansMTCyrillicProLight';
  src: url('fonts/russian/GillSansMTCyrillicProLight/GillSansMTCyrillicProLight.woff') format('woff');
  src: url('fonts/russian/GillSansMTCyrillicProLight/GillSansMTCyrillicProLight.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}
