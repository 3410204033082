.property-listing {
    max-width: 1000px;
    margin: 0 auto 30px;

    @include foundation-flex-classes;
    @include foundation-flex-grid;

    .row {
        max-width: 1300px;
    }

    .text-center {
        text-align: center;
    }

    .medium-text-right {
        @include breakpoint(medium) {
            text-align: right;
        }
    }

    &__views {
        @include reset-list();
        @include inline-list();
        margin-bottom: 15px;

        li {
            vertical-align: middle;
            font-family: $body-font-family;
            font-size: 14px;
            font-style: italic;

            &:first-child {
                margin-right: 10px;
            }

            input {
                display: none;
            }
        }
    }

    &__view {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;

        @at-root input[type='radio'] + #{&} {
            margin: 0;
        }

        @at-root input[type='radio']:checked + #{&} {
            background-color: rgba(#000, 0.1);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 22px 22px;
        }

        &--grid {
            &:before {
                background-image: url('../assets/img/jumby-bay/icon-grid.png');

                @at-root input[type='radio']:checked + #{&} {
                    background-image: url('../assets/img/jumby-bay/icon-grid-solid.png');
                }
            }
        }

        &--list {
            &:before {
                background-image: url('../assets/img/jumby-bay/icon-list.png');

                @at-root input[type='radio']:checked + #{&} {
                    background-image: url('../assets/img/jumby-bay/icon-list-solid.png');
                }
            }
        }

        &--map {
            &:before {
                background-image: url('../assets/img/jumby-bay/icon-map.png');

                @at-root input[type='radio']:checked + #{&} {
                    background-image: url('../assets/img/jumby-bay/icon-map-solid.png');
                }
            }
        }
    }

    &__wrapper {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-top: 5px;

        @at-root .property-listing--grid #{&}, .property-listing--list #{&} {
            margin-top: -25px;
        }

        > .off-canvas-content {
            max-width: calc(100vw - 20px);
            margin-left: auto;
            margin-right: auto;
            transition: transform 0.5s ease, max-width 0.5s ease;

            @media screen and (min-width: 1100px) {
                max-width: 1000px;
            }

            @at-root .property-listing--map #{&} {
                transform: translate(0, 0);
            }

            > .js-off-canvas-overlay {
                background: none;
            }
        }
    }

    &__filters {
        height: auto;
        width: 100vw;
        transform: translateX(-100vw);
        margin-top: 5px;
        margin-left: 50px;
        padding: 10px 60px;
        background-color: #fff;
        border: 1px solid #e9eff0;
        transition: transform 0.5s ease, margin 0.5s ease;
        text-align: center;

        @include breakpoint(medium) {
            width: 400px;
            transform: translateX(-400px);
            visibility: visible !important;

            &.is-open ~ .off-canvas-content {
                transform: translateX(195px);

                @media screen and (min-width: 1090px) {
                    max-width: calc(100vw - 425px);
                }

                @media screen and (min-width: 1500px) {
                    transform: translateX(155px);
                }

                @media screen and (min-width: 1600px) {
                    transform: translateX(105px);
                }

                @media screen and (min-width: 1700px) {
                    transform: translateX(60px);
                }

                @media screen and (min-width: 1800px) {
                    transform: translateX(35px);
                }

                @at-root .property-listing--map #{&} {
                    max-width: inherit;
                    transform: translate(0, 0);
                }
            }
        }

        @at-root .property-listing--grid #{&}, .property-listing--list #{&} {
            margin-top: 30px;
        }

        &--hidden {
            @include breakpoint(small only) {
                @at-root .property-listing--map #{&} {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        &--fixed {
            position: fixed;
            top: 95px;
            left: 0;
            bottom: 0;
            padding-bottom: 100px;
            overflow: hidden;



            &.property-listing__filters--large-offset {
                top: 110px;
            }

            @include breakpoint(medium) {
                left: -8px;
            }

            &-bottom {
                position: absolute;
                top: auto;
                left: 0;
                bottom: 0;
            }
        }

        &--open-fixed {
            &.is-open {
                position: fixed;
                top: 50px;
                left: 0;
                bottom: 0;
                overflow-y: auto;
                padding-bottom: 100px;

                @include breakpoint(large) {
                    top: 80px;

                    &.property-listing__filters--large-offset {
                        top: 110px;
                    }
                }
            }
        }

        &.is-open {
            margin-left: 0;
        }

        &.is-transition-push:after {
            content: none;
        }

        &-toggle {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            width: 40px;
            height: 100%;
            outline: transparent;
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                background-color: #ADBDB2;
            }

            &:after {
                content: '';
                position: absolute;
                top: 20px;
                left: 20px;
                transform: translateX(-50%) translateY(-50%) rotate(180deg);
                width: 10px;
                height: 20px;
                background: url('img/oetker-collection/arrow-left-black.svg') no-repeat center;
                background-size: cover;
                transition: transform 0.5s ease;

                @at-root .property-listing__filters.is-open #{&} {
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            &:focus {
                outline: none;
            }

            span {
                position: absolute;
                top: 0;
                right: 0;
                width: 160px;
                transform: rotate(-90deg);
                transform-origin: bottom right;
                font-family: $header-font-family;
                font-size: 16px;
                line-height: 40px;
                color: #373737;
                background-color: #ADBDB2;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding: 0;
            }
        }

        &-heading {
            margin-bottom: 20px;
            font-family: $header-font-family;
            font-size: 18px;
            color: #000;
        }

        &-subheading {
            margin-top: 15px;
            margin-bottom: 10px;
            font-family: $header-font-family;
            font-size: 18px;
            color: #000;
        }

        &-dropdown {
            width: 100%;
            max-width: 175px;
            margin: 0 auto 20px auto;
            padding: 0.5rem 1.5rem 0.5rem 0.5rem;
            appearance: none;
            border-radius: 0;
            font-family: $body-font-family;
            font-size: 16px;
            font-style: italic;
            color: #000;
            border: 1px solid #000;
            background-image: url('../assets/img/jumby-bay/arrow-down-grey.png');
            background-size: 13px 7px;
            background-origin: content-box;
            background-position: right -1rem center;
            background-repeat: no-repeat;

            &::-ms-expand {
                display: none;
            }
        }

        &-datepicker {
            width: 100%;
            max-width: 175px;
            margin: 0 auto 20px auto;
            padding: 0.5rem 1.5rem 0.5rem 0.5rem;
            font-family: $body-font-family;
            font-size: 16px;
            font-style: italic;
            color: #000;
            border: 1px solid #000;
            background-image: url('../assets/img/jumby-bay/calendar.png');
            background-size: 14px 15px;
            background-origin: content-box;
            background-repeat: no-repeat;
            background-position: right -1rem center;

            &::placeholder {
                opacity: 1;
                color: #000;
            }
        }

        &-checkbox {
            font-family: $body-font-family;
            font-size: 16px;
            color: #000;

            input[type='checkbox'] {
                margin: 0 0.5rem 1.5rem 0;
            }

            &-list {
                @include reset-list;
                display: inline-block;
                margin: 0 auto;
                text-align: left;
            }
        }

        .btn {
            margin-bottom: 20px;
        }
    }

    &__no-results {
        display: none;
        padding: 0 20px;
        font-family: $header-font-family;
        font-size: 18px;
        color: #000;
        text-align: center;

        &--active {
            display: block;
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-flow: column;

        &--hidden {
            display: none;
        }

        @at-root .property-listing--grid .property-listing__filters.is-open ~ .off-canvas-content #{&} {
            @media screen and (min-width: 1090px) {
                flex: 0 0 50%;
                max-width: calc(1000px / 3);
            }
        }

        @at-root .property-listing--list #{&} {
            flex: 0 0 100% !important;
            max-width: 425px !important;
            margin: 0 auto !important;

            @include breakpoint(medium) {
                max-width: 100% !important;
            }
        }

        @at-root .property-listing--map #{&} {
            display: none;
        }

        > div {
            max-width: 395px;
            margin: 30px auto 0 auto;
            padding: 20px 20px 0px;
            border: 1px solid #e9eff0;
            text-align: center;
            flex-grow: 1;
            width: 100%;

            @at-root .property-listing--list #{&} {
                padding: 0;
                max-width: none;
                text-align: left;
                @include flex();
                @include flex-direction(column);
                @include flex-align(center, middle);

                @include breakpoint(medium) {
                    @include flex-direction(row);
                    @include flex-align(left, middle);
                }

                > a:first-child {
                    flex: 0 0 100%;
                    align-self: stretch;
                    @include flex();
                    @include flex-direction(column);
                    @include flex-align(center, middle);

                    @include breakpoint(medium) {
                        flex: 0 0 40%;
                        @include flex-direction(row);
                        margin-right: 40px;
                    }

                    + div {
                        padding: 10px 20px;
                        text-align: center;

                        @include breakpoint(medium) {
                            align-self: flex-start;
                            padding: 90px 0 0 0;
                            text-align: left;
                        }
                    }
                }
            }

            > a {
                outline: transparent;
            }
        }

        &-heading {
            margin: 20px 0;
            font-family: $header-font-family;
            font-weight: 400;
            font-size: 24px;
            color: #000;

            @at-root .property-listing--list #{&} {
                order: 2;
                font-size: 28px;
                padding: 20px 10px;

                @include breakpoint(medium) {
                    position: absolute;
                    left: calc(40% + 40px);
                    top: 40px;
                    padding: 0;
                }

                @include breakpoint(small only) {
                    text-align: center;
                }
            }
        }

        &-subheading {
            margin-bottom: 20px;
            font-family: $body-font-family;
            font-size: 14px;
            font-style: italic;
            color: #000;
        }

        &-image {
            width: 100%;
            padding-bottom: 100%;
            margin-bottom: 20px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            @at-root .property-listing--list #{&} {
                order: 1;
                margin-bottom: 0;
                height: 100%;
                padding-bottom: 0;

                @include breakpoint(small only) {
                    height: 325px;
                }
            }
        }

        .btn {
            margin-bottom: 0;

            @at-root .property-listing--list #{&} {
                margin-bottom: 20px;

                @include breakpoint(medium) {
                    margin-bottom: 40px;
                }
            }
        }

        &-description {
            display: none;
            margin-bottom: 25px;
            font-family: $body-font-family;
            font-size: 14px;
            color: #000;

            @at-root .property-listing--list #{&} {
                display: block;
            }
        }

        &-features {
            display: none;
            @include reset-list();
            padding: 20px 10px;
            text-align: left;

            @at-root .property-listing--list #{&} {
                display: block;
                flex: 0 0 50%;

                @include breakpoint(medium) {
                    flex: 0 0 25%;
                    margin-left: 30px;
                }
            }

            @include breakpoint(medium) {
                padding-top: 90px;
            }

            li {
                position: relative;
                padding-left: 50px;
                padding-bottom: 15px;
                font-family: $body-font-family;
                font-size: 12px;
                line-height: 25px;
                color: #000;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &.property-listing__item-features-heading {
                    padding-left: 0;
                    padding-bottom: 20px;
                    font-family: $header-font-family;
                    font-weight: 600;
                    font-size: 13px;
                    text-transform: uppercase;
                }
            }

            &-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 25px;
                height: 25px;
                background: no-repeat center center;
                background-size: contain;
            }
        }

        p span,
        p {
          font-family: $header-font-family !important;
        }
    }

    &__map {
        display: none;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        @at-root .property-listing--map #{&} {
            display: block;
        }

        &-content {
            height: 100%;
            @include flex();
            @include flex-align(center, top);
            text-align: center;
            background-color: rgba(#000, 0.1);

            @at-root .position-right.is-open ~ #{&} {
                @include breakpoint(small only) {
                    transform: translateX(0);
                }
            }

            @include breakpoint(medium) {
                @include flex-align(center, middle);
            }
        }

        &-island {
            position: relative;
            width: 100vw;
            height: 84vw;
            background-color: transparent;

            @include breakpoint(medium) {
                max-height: 675px;
            }

            &.leaflet-container {
                .leaflet-control-zoom {
                    margin-right: 15px;
                    margin-bottom: 35px;
                    border-radius: 0;
                    border-color: #fff;

                    @include breakpoint(medium) {
                        margin-right: 50px;
                        margin-bottom: 75px;
                    }

                    a {
                        background-color: #ADBDB2;
                        color: #fff;
                        border-color: #fff;

                        &.leaflet-disabled {
                            opacity: 0.6;
                        }
                    }

                    @at-root .property-listing__map-island--popup-open#{&} {
                        @include breakpoint(small only) {
                            display: none;
                        }
                    }
                }

                .leaflet-marker-icon[style*='opacity: 0'] {
                    pointer-events: none;
                }

                .leaflet-popup {
                    &-content {
                        margin: 0;

                        &-wrapper {
                            box-shadow: none;
                            border: none;
                            border-radius: 0;
                        }

                        .medium-6 {
                            padding: 20px;
                        }
                    }

                    &-tip {
                        display: none;

                        &-container {
                            width: 50px;
                            height: 60px;
                            margin-left: -25px;
                            margin-top: -15px;
                            background: url('../assets/img/maps/map-marker-blue.png') no-repeat center center;
                            background-size: cover;
                        }
                    }

                    &-close-button {
                        top: 10px;
                        right: 10px;
                        width: 25px;
                        height: 25px;
                        font-size: 30px;
                        font-weight: 400;
                        color: #fff;

                        @include breakpoint(medium) {
                            top: 5px;
                            right: 5px;
                            color: #ADBDB2;
                        }
                    }
                }
            }
        }

        &-property-marker {
            display: none;
            min-width: 100vw;
            max-width: 100vw;

            @include breakpoint(medium) {
                min-width: 700px;
            }

            @at-root .leaflet-popup #{&} {
                display: block;
            }

            &-image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                @include breakpoint(small only) {
                    padding-bottom: 100% !important;
                }
            }

            &-heading {
                margin-bottom: 20px;
                font-family: $header-font-family;
                font-size: 18px;
                color: #000;
            }

            &-subheading {
                font-family: $header-font-family;
                font-size: 14px;
                font-style: italic;
            }

            &-description {
                padding: 20px 40px 20px 0;
                font-family: $body-font-family;
                font-size: 14px;
                color: #000;

                p {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
