.events {
    position: relative;
    max-width: 1260px;
    margin: 0 auto 30px;
    padding-top: 20px;

    h2 {
        text-align: center;
    }

    &__carousel {
        padding: 40px 30px 60px;

        @include breakpoint(small only) {
            padding: 40px 10px 60px;
        }

        .slick-track {
            display: flex !important;
        }

        .slick-list,
        .slick-track,
        .slick-slide {
            height: inherit !important;
        }

        .slick-prev {
            background: url('img/oetker-collection/left-green.svg') no-repeat center !important;
            background-size: 15px 30px;
            left: 5px;
            top: 150px;
            width: 30px;
            height: 30px;
            transform: translate(0%, 0%);
            z-index: 1;

            &::before {
                content: '';
            }

            &:hover,
            &:focus {
                transform: translate(-5px, 0);
            }

            @include breakpoint(small only) {
                top: auto;
                bottom: 8px;
                left: 25px;
            }
        }

        .slick-next {
            background: url('img/oetker-collection/right-green.svg') no-repeat center !important;
            background-size: 15px 30px;
            top: 150px;
            right: 5px;
            width: 30px;
            height: 30px;
            transform: translate(0%, 0%);
            z-index: 1;

            &::before {
                content: '';
            }

            &:hover,
            &:focus {
                transform: translate(5px, 0);
            }

            @include breakpoint(small only) {
                top: auto;
                bottom: 8px;
                right: 25px;
            }
        }

        .slick-dots {
            bottom: 15px !important;
            z-index: 0;
        }
    }

    &__item {
        display: flex !important;
        flex-direction: column;
        margin: 0 20px;
        border: 1px solid #e6e6e6;

        &.single {
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 30px auto;

            @include breakpoint (large up) {
                flex-direction: row;
            }

            @include breakpoint (medium down) {
                width: 90%;
                max-width: 700px;
                margin: 30px auto;
            }

            .events__item-image {
                width: 60%;
                min-height: unset;

                @include breakpoint (medium down) {
                    width: 100%;
                }
            }

            .events__item-content {
                align-items: center;
                width: 40%;
                padding-top: 20px;
                padding-bottom: 20px;

                @include breakpoint (medium down) {
                    width: 100%;
                }
            }

            .events__item-date {
                height: fit-content;
                border-right: none;
                border-bottom: none;

                @include breakpoint (medium down) {
                    margin: 10px;
                    padding: 10px;
                }
            }

            .events__item-description {
                height: fit-content;
                border-left: 1px solid #e6e6e6;

                @include breakpoint (medium down) {
                    width: 90%;
                    margin: 0 auto;
                    padding: 20px 10px;
                    border-left: none;
                    border-top: 1px solid #e6e6e6;
                }
            }
        }

        &-image {
            width: 100%;
            min-height: 250px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-content {
            display: flex;
            height: 100%;
            padding: 20px 0;

            @include breakpoint (medium down) {
                flex-direction: column;
            }
        }

        &-date {
            display: flex;
            flex-direction: column;
            gap: 36px;
            padding: 0 25px;
            border-right: 1px solid #e6e6e6;

            @include breakpoint (medium down) {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 0 25px 25px;
                padding: 0;
                border-right: none;
                border-bottom: 1px solid #e6e6e6;
            }

            p {
                font-family: $header-font-family;
                font-weight: 500;
                margin-bottom: 10px;
                padding-bottom: 0 !important;
                color: map-get($brand-colours, 'group-dark-blue');
                text-transform: uppercase;

                &:last-of-type {
                    @include breakpoint (large) {
                        margin-bottom: 0;
                    }
                }
            }

            &-text {
                font-size: 18px;

                @include breakpoint (medium down) {
                    width: 100%;
                }
            }

            &-number {
                font-size: 35px;
                letter-spacing: 1px;

                &.one::first-letter {
                    margin-left: -5px;
                }
            }
        }

        &-field {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

            &:nth-child(2) {
                position: relative;

                &::before {
                    content: '';
                    background: url('img/oetker-collection/arrow-down-green.svg') no-repeat center;
                    position: absolute;
                    top: -25px;
                    left: 50%;
                    width: 10px;
                    height: 10px;
                    transform: translateX(-50%);

                    @include breakpoint (medium down) {
                        top: 23px;
                        left: -25px;
                        transform: rotate(-90deg);
                        transform-origin: center;
                    }
                }
            }
        }

        &-description {
            display: flex;
            flex-direction: column;
            padding: 0 25px;

            @include breakpoint (medium down) {
                width: 100%;
                height: 100%;
                text-align: center;
            }

            &--title {
                margin-bottom: 20px !important;

                h3 {
                    font-weight: 500;
                    margin: 0;
                }
            }

            &--text {
                margin-bottom: 10px;
            }
        }

        &-buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-self: end;
            justify-content: space-evenly;
            gap: 16px;
            margin: auto auto 30px;

            .cta,
            .book-now-container {
                margin: 0;
            }
        }
    }
}
