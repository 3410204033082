.info-tab {
	max-width: 900px;
	margin: 0 auto 30px;

	&__title {

		h2 {
			margin-bottom: 16px;
			text-transform: none;
		}
	}

	&__floorplan {
		margin-bottom: 30px;
	}

	&__item {
		padding: 0 60px 15px;

		@include breakpoint(medium down) {
			padding: 0 20px 15px;
		}

		@include breakpoint(small only) {
			padding-left: 40px;
		}

		&:nth-child(odd) {
			
		}

		&-container {

			@include breakpoint(small only) {
				padding-bottom: 20px;
			}

			> .cell.medium-6 {
				&:first-child {
					border-right: 1px solid #e6e6e6;
					text-align: left;
					padding-left: 10%;

					@include breakpoint(small only) {
						border-right: none;
						padding-left: 0;
					}
				}
			}

			&--only-show-first {
				> .cell > .info-tab__item:nth-child(n+3) {
					display: none;
				}
			}
		}

		&-top {
			margin-bottom: 0px;

			img {
				width: 24px;
				margin: 5px 0 11px;
				float: left;
			}

			h3 {
				font-family: $header-font-family;
				font-size: rem-calc(21);
				text-transform: none;
				margin-bottom: 0px;
				margin-left: 38px;
			}
		}

		&-bottom {

			ul {
				margin-left: 30px;

				li {
					padding-left: 10px;
					font-size: rem-calc(15);
				}
			}
		}
	}

	&__accordion {

		.accordionButton {
			margin-top: 15px;
			text-align: center;
			padding-bottom: 5px;
			background: url(img/oetker-collection/arrow-down-black.svg) no-repeat bottom center;
			background-size: 10px 12px;
			cursor: pointer;

			&:hover {

				h4 {
					color: map-get($brand-colours, medium-gray);
				}
			}

			&.on {
				background: url(img/oetker-collection/arrow-up-black.svg) no-repeat bottom center;
				background-size: 10px 12px;
			}

			h4 {
				margin-bottom: 8px;
				letter-spacing: 2px;
				font-size: rem-calc(13);
				line-height: 1;
				text-transform: uppercase;
			}
		}

		.accordionContent {
			@include breakpoint(small only) {
				position: relative;
				
				/*&::before {
					content: '';
					width: 75%;
					height: 1px;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					background-color: map-get($brand-colours, light-gray);
					}*/
			}

			> .cell.medium-6 {
				&:first-child {
					border-right: 1px solid #e6e6e6;
					text-align: left;
					padding-left: 10%;

					@include breakpoint(small only) {
						border-right: none;
						padding-left: 0;
					}
				}
			}
		}
	}

	.tabs-content {
		border: none !important;

		.accordionButton {
			margin-top: 15px;
			text-align: center;
			padding-bottom: 5px;
			background: url(img/oetker-collection/arrow-down-black.svg) no-repeat bottom center;
			background-size: 10px 12px;
			cursor: pointer;

			&.on {
				background: url(img/oetker-collection/arrow-up-black.svg) no-repeat bottom center;
				background-size: 10px 12px;
			}

			h4 {
				margin-bottom: 8px;
				letter-spacing: 2px;
				font-size: 0.875rem;
				font-family: $header-font-family;
				line-height: 1;
				text-transform: uppercase;
			}
		}
	}

	&__tabs {
		margin: 0 30px;

		&-wrapper {
			width: 100%;
			margin: 0 auto;
			border: solid #e6e6e6;
			border-width: 1px 0;

			ul {
				margin: 0 auto;
				border: none;
				background-color: transparent;
				text-align: center;

				li {
					float: unset;
					display: inline-block;


					a {
						font-family: $header-font-family;
						font-size: rem-calc(13);
						letter-spacing: 1px;
						text-transform: uppercase;
						background-color: transparent;
						color: #000;

						&:hover {
							color: map-get($brand-colours, light-gray);
						}
					}

					&.is-active {
						background-color: transparent;

						a {
							color: #000;
							text-decoration: underline;
							background: none;
						}
					}
				}
			}
		}
	}

	&__service-heading {
		margin-left: 15px;
	}

	&__service-item {
		padding: 10px 20px 0;

		+ h2 {
			padding-top: 20px;
		}

		&-title {
			padding-right: 5px;

			h4 {
				font-size: rem-calc(17);
				line-height: rem-calc(25);
				font-family: $header-font-family;
			}
		}

		&-detail {
			padding-left: 15px;

			p {
				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: #000;
					text-decoration: underline;
					word-break: break-all;

					&:hover {
						color: map-get($brand-colours, group-dark-blue);
					}
				}
			}
		}
	}

	&__cta {
		margin-top: 30px;

		.downloadsItems {
			padding-top: 30px;
		}
	}
}