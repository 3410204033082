.new-accordion {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 30px;

	&__title {
		text-align: center;

		h2 {
			
		}
	}

	&__item {

		.accordionButton {
			border-bottom: 1px solid #e6e6e6;
			background: url('img/oetker-collection/arrow-down-black.svg') no-repeat 99% center;
			background-size: 10px 10px;
			margin: 10px 0;
			cursor: pointer;

			&:hover {
				color: map-get($brand-colours, medium-gray);
			}

			@include breakpoint(small only) {
				margin: 0 auto 20px;
				max-width: 90%;
			}

			&.on {
				background: url('img/oetker-collection/arrow-up-black.svg') no-repeat 99% center;
				background-size: 10px 10px;
			}

			h3 {
				padding-left: 10px;
				font-size: rem-calc(21);
				line-height: rem-calc(25);
				margin-bottom: 5px;
				text-transform: initial;
			}
		}

		.accordionContent {
			padding: 20px 0;

			h4 {
				font-family: $header-font-family;
				font-size: rem-calc(21);
				text-transform: initial;
			}
			
			h3 {
				font-size: rem-calc(15);
				line-height: rem-calc(20);
				margin-bottom: 5px;
				text-transform: initial;
			}

			p {
				
			}

			a {
				font-size: rem-calc(15);

				&:hover {
					color: map-get($brand-colours, group-dark-blue);
					text-decoration: underline;
				}
			}

			ul {
				padding-left: 5px;

				li {

				}
			}
		}
	}

	&__content {

		&-wysiwyg {
			padding: 0 20px;

			@include breakpoint(small only) {
				padding: 0 50px;
			}

			a {
				color: map-get($brand-colours, group-dark-blue);

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&-features {

			@include breakpoint(small only) {
				padding: 0 50px;
			}

			&-item {
				padding: 0 20px;

				@include breakpoint(small only) {
					padding: 0;
				}

				&:nth-child(odd) {
					border-right: 1px solid #e6e6e6;

					@include breakpoint(small only) {
						border-right: none;
					}
				}

				h3 {
					display: inline-block;
					font-family: $header-font-family;
					font-size: rem-calc(21) !important;
					margin-bottom: 0px;
					margin-left: 5px;
					text-transform: initial;
				}

				ul {

					li {
						font-size: rem-calc(15);
					}
				}
			}
		}
	}
}