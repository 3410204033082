.grid-contact-tile {
    justify-content: center;
    margin-top: -30px;

    &.active {
        display: flex;
    }

    @include breakpoint(medium down) {
        padding: 0;
    }

    &__item {
        text-align: center;
        padding: 0 20px;
        margin-top: 30px;

        &:focus {
            outline: none;
        }

        &-img {

            &.border-on {
                border: solid #e6e6e6;
                border-width: 1px 1px 0px 1px;
            }

            .slick-prev {
                background: url('img/oetker-collection/left.svg') no-repeat center;
                left: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;
                z-index: 2;

                &::before {
                    content: '';
                }
            }

            .slick-next {
                background: url('img/oetker-collection/right.svg') no-repeat center;
                right: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;

                &::before {
                    content: '';
                }
            }

            img[data-open] {
                cursor: pointer;
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 67.65%; // 34:23 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        &-container {
            max-width: 650px;
            margin: 0 auto;
        }

        &-detail {
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;
            padding: 10px 20px 20px;

            @include breakpoint(medium down) {
                padding: 10px 10px 20px;
            }

            h4 {
                font-size: rem-calc(13);
            }

            h2 {
                margin-bottom: 0;
                font-size: rem-calc(24);
                line-height: rem-calc(39);
            }

            ul {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 0 15px;
                padding: 0;
                list-style: none;
                font-size: rem-calc(15px);
                line-height: rem-calc(15px);

                li {
                    display: inline-block;
                    margin: 0 0 5px 0;
                    font-size: rem-calc(13);

                    &:after {
                        content: '|';
                        padding: 0 5px;
                        display: inline-block;
                        width: rem-calc(15px);
                        height: rem-calc(13px);
                        margin-right: -rem-calc(14px);
                    }

                    &.no-pipe-separator {
                        &:after {
                            color: transparent;
                        }
                    }

                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                }
            }

            p {
                margin-bottom: 0px;
                font-size: rem-calc(16);

                &:first-of-type, &:last-of-type {
                    margin-bottom: 16px;
                }
            }

            a {
                color: #000;

                &:hover {
                    text-decoration: underline;
                    color: map-get($brand-colours, group-dark-blue);
                }

                &.cta {
                    display: inline-block;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.grid--gallery {

    .filter-controls {
        flex-wrap: nowrap;
        cursor: pointer;

        @include breakpoint(medium) {
            flex-wrap: wrap;
        }
    }

    .filter-mobile-accordion {
        display: block !important;
        cursor: pointer;
        
        @include breakpoint(medium) {
            display: none !important;
        }
    }
}