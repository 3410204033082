.blog-off-canvas {
	box-shadow: none;
	background-color: #fff;
	padding: 30px 20px 50px;

	&__close {
		position: absolute;
		right: 10px;
		top: 10px;
		border: none;
		background: url('img/oetker-collection/close.svg') no-repeat;
		background-size: 15px;
		background-position: center left;
		width: 15px;
		height: 15px;
	}

	&__nav {

		ul {
			list-style: none;
			margin: 0;

			li {
				margin-bottom: 30px;
				text-align: center;

				.accordionButton {
					color: #000;
					font-size: 14px;
					line-height: 17px;
					text-transform: uppercase;

					&.on {
						margin-bottom: 20px;
						color: #767676;
					}
				}

				.accordionContent {

					li {
						margin-bottom: 15px;
					}

				}

				a {
					color: #000;
					font-size: 14px;
					line-height: 17px;
					text-transform: uppercase;
				}
			}
		}
	}

	&__search {
		position: relative;

		input[type="text"] {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			border: none;
			width: 80px;
			padding: 0;
		}
	}
}