.grid-listing {
    display: none;

    &.active {
        display: block;
    }

    &__item {
        margin-top: 30px;
        padding: 0 20px;

        &-img {
            height: 100%;

            &.border-on {
                border: solid #e6e6e6;
                border-width: 1px 0px 1px 1px;

                @include breakpoint(small only) {
                    border-width: 1px 1px 0px;
                }
            }

            .slick-prev {
                background: url('img/oetker-collection/left.svg') no-repeat center;
                left: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;
                z-index: 2;

                &::before {
                    content: '';
                }
            }

            .slick-next {
                background: url('img/oetker-collection/right.svg') no-repeat center;
                right: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;

                &::before {
                    content: '';
                }
            }

            &-slide {
                background: center center no-repeat;
                background-size: cover;
                min-height: 300px;

                @include breakpoint(medium only) {
                    min-height: 330px;
                }

                @include breakpoint(small only) {
                    min-height: 260px;
                }
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 67.65%; // 34:23 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        &-detail {
            padding: 30px 40px;
            border-bottom: 1px solid #e6e6e6;
            border-top: 1px solid #e6e6e6;

            h2 {
                margin-bottom: 5px;
                font-size: rem-calc(24);
            }

            h4 {
                margin-bottom: 15px;
                font-size: rem-calc(13);
                font-family: $header-font-family;
            }

            p {
                margin-bottom: 5px;
                font-size: rem-calc(16);
            }

            a.cta {
                margin-left: 0 !important;
            }

            @include breakpoint(medium only) {
                padding: 10px;
            }

            @include breakpoint(small only) {
                padding: 20px;
                border-bottom: none;
                border-right: 1px solid #e6e6e6;
                border-left: 1px solid #e6e6e6;
            }
        }

        &-features {
            padding: 30px 20px;
            border-bottom: 1px solid #e6e6e6;
            border-top: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;

            h4 {
                font-weight: 600;
                margin-bottom: 0;
                font-size: rem-calc(13);
                font-family: $header-font-family;
            }

            ul {
                list-style: none;
                margin: 0;

                li {
                    padding-left: 20px;
                    font-size: rem-calc(13);
                    line-height: rem-calc(35);
                    color: map-get($brand-colours, light-gray);
                    font-family: $body-font-family;
                    background: url('img/oetker-collection/listing-bullet.png') no-repeat left center;
                    background-size: 5px;
                }
            }

            @include breakpoint(small only) {
                padding: 0 20px 10px;
                border-top: none;
                border-left: 1px solid #e6e6e6;
            }
        }

        &-links {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @at-root .grid--horizontal-links #{&} {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}
