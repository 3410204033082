.grid-contact {
    position: relative;
    max-width: 1260px;
    margin: 0 auto 30px;

    &__tabs {
        margin-bottom: 15px;

        ul {

            li {

                a {
                    font-family: $header-font-family;
                }
            }
        }
    }

    &__view {
        position: absolute;
        top: 0;
        right: 10px;
        margin-bottom: 10px;

        @include breakpoint(medium down) {
            position: relative;
            top: auto;
            right: auto;
        }

        &-switch {

            @include breakpoint(medium only) {
                padding-right: 30px;
            }

            @include breakpoint(small only) {
                padding-right: 20px;
            }

            p {
                display: inline-block;
                letter-spacing: 1px;
                margin: 0 5px 0 0;
                vertical-align: sub;
            }

            ul {
                display: inline-block;
                list-style: none;
                margin: 0;

                li {
                    display: inline-block;
                    margin: 3px;

                    &#carousel {
                        button {
                            padding: 10px;
							background: url('img/oetker-collection/carousel-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;
                            &:hover {
								background: url('img/oetker-collection/carousel-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/carousel-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }

                    &#tile {
                        button {
                            padding: 10px;
							background: url('img/oetker-collection/tile-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;
                            &:hover {
								background: url('img/oetker-collection/tile-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/tile-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }

                    &#listing {
                        button {
                            padding: 10px;
							background: url('img/oetker-collection/listing-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;
                            &:hover {
								background: url('img/oetker-collection/listing-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/listing-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }

                    &#location {
                        button {
                            padding: 10px;
							background: url('img/oetker-collection/location-logo.svg') no-repeat;
							background-size: 15px;
							background-position: center;							

                            &:hover {
								background: url('img/oetker-collection/location-logo-active.svg') no-repeat;
                                cursor: pointer;
								background-size: 15px;
								background-position: center;							}

                            &.active {
								background: url('img/oetker-collection/location-logo-active.svg') no-repeat;
								background-size: 15px;
								background-position: center;							}
                        }
                    }
                }
            }
        }
    }

    &__container {
        .tabs-panel {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .filter-mobile-accordion {
        text-align: center;
        margin-bottom: 10px;

        .accordionButton {
            border: solid rgba(map-get($brand-colours, light-gray), 0.5);
            border-width: 1px 0px;

            h4 {
                
                margin-bottom: 0;
                padding: 10px;

                &::after {
                    background: url('img/oetker-collection/arrow-down-black.svg') no-repeat center;
                    background-size: 10px 6px;
                    content: '';
                    width: 10px;
                    height: 10px;
                    padding: 10px;
                }
            }

            &.on {
                border-bottom: none;

                h4 {
                    &::after {
                        background: url('img/oetker-collection/arrow-up-black.svg') no-repeat center;
                        background-size: 10px 6px;
                    }
                }
            }
        }

        .accordionContent.filter-controls {
            padding-top: 0;
            display: flex;
            flex-direction: column;
        }
    }

    .filter-controls {
        width: 100%;
        min-height: 40px;
        text-align: center;
        border: solid rgba(map-get($brand-colours, light-gray), 0.5);
        border-width: 1px 0px;
        margin: 0 auto 31px;

        @include breakpoint(small only) {
            padding: 20px 10px;
            border-top: none;
        }

        .button {
            color: map-get($brand-colours, medium-gray);
            background-color: transparent;
            font-size: rem-calc(15);
            line-height: rem-calc(25);
            font-family: $body-font-family;
            padding: 10px 0;
            margin: 0 10px;
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
                color: map-get($brand-colours, dark-gray);
            }

            @include breakpoint(small only) {
                width: 50%;
                margin: 0 auto;
                background-position: 10% center;
                padding: 10px 10px 10px 30px;
            }

            &.on {
                text-decoration: underline;
                color: map-get($brand-colours, dark-gray);

                @include breakpoint(small only) {
                    background-position: 10% center;
                }
            }
        }
    }
}
