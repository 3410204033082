.blog-hotels {
	width: 100%;
	max-width: 842px;
	margin: 0 auto 13px;
	text-align: center;
	
	&__header {
		h2 {
			text-transform: uppercase;
			font-family: $blog-font-family;
			font-size: 13px;
			letter-spacing: 0.16em;
			margin-bottom: 40px;
		}
	}
	
	&__carousel {
		position: relative;
		
		&-inner {
			
		}
		
		.slick-arrow {
			top: 78px;
			
			&.slick-prev {
				left: 25px;
				background-image: url('img/oetker-collection/left-green.svg');
				
				@include breakpoint(large) {
					left: -32px;
				}
			}
			
			&.slick-next {
				right: 25px;
				background-image: url('img/oetker-collection/right-green.svg');
				
				@include breakpoint(large) {
					right: -32px;
				}
			}
		}
	}
	
	&__item {

		h3 {
			/* font-size: 22px; */
			font-size: 17px;
			line-height: 20px;
		}

		a {
			display: block; 
			margin: 0 auto;
			max-width: 154px;
			font-family: $header-font-family;
			font-size: 22px;
			line-height: 21px;
		}
		
		img {
			border-radius: 100%;
			margin-bottom: 30px;
		}
	}
}