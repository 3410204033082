.property-information {
    max-width: 900px;
    margin: 0 auto 30px;

    @include foundation-flex-classes;
    @include foundation-flex-grid;

    .row {
        max-width: 1300px;
    }

    &__wrapper {
        padding: 25px 10px 50px 10px;
        border: 1px solid #e9eff0;
    }

    &__heading {
        position: relative;
        padding: 5px;
        margin-bottom: 20px;
        font-family: $header-font-family;
        font-weight: 400;
        font-size: 35px;
        text-align: center;
    }

    &__accordion {
        max-width: 450px;
        margin: 0 auto;

        .accordion-item {
            .accordion-title {
                padding: 12px 20px;
                font-family: $header-font-family;
                font-size: rem-calc(18);
                text-transform: uppercase;
                color: #000;

                &:before {
                    font-size: rem-calc(20);
                    content: '+';
                    border-bottom: none;
                    position: relative;
                    top: auto;
                    right: auto;
                    margin-top: 0;
                    display: inline-block;
                    margin-right: 10px;
                    border: none;
                }

                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }

            &.is-active {
                .accordion-title {
                    &:before {
                        content: '-';
                        border-bottom: none;
                        font-size: rem-calc(30);
                        line-height: rem-calc(10);
                    }
                }
            }

            .accordion-content {
                padding: 5px 10px 20px 35px;
                font-family: $body-font-family;
                font-size: 15px;
                line-height: 28px;
                color: #000;
                background-color: transparent;

                table {
                    th {
                        padding-right: rem-calc(18);
                        vertical-align: top;
                        font-family: $header-font-family;
                    }

                    td {
                        vertical-align: top;

                        p {
                            &:first-child {
                                margin-top: 0;
                            }

                            &:last-child {
                                margin-top: 0;
                            }
                        }
                    }
                }

                a {
                    color: map-get($brand-colours, group-dark-blue);

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
