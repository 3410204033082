.gallery-modal {
	padding: 60px 40px;
	background-color: transparent;
	border: none;
	width: 100%;
	max-width: 900px;

	@include breakpoint(small only) {
		padding: 50% 10px;
		background-color: #fff
	}

	&__button {
		position: absolute;
		top: 30px;
		right: 15px;
		background: url('img/oetker-collection/close.svg') no-repeat;
		background-position: center;
		background-size: 20px 20px;
    	width: 25px;
    	height: 25px;
		cursor: pointer;
	}
}