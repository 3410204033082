.image {
    max-width: 670px;
    margin: 0 auto 60px;
    padding: 0;

    &.slick-initialized.slick-dotted {
        margin-bottom: 40px;
    }

    &--single.slick-slider[data-slick*="\"arrows\": true"] {
        padding: 0;
    }

    &__item {

        &-image {
            width: 100%;
            margin: 0 auto;
        }

        &-video {
            position: relative;
            max-width: 100vw;

            @include breakpoint(medium) {
                max-width: 670px;
            }
        }

        &-caption {
            color: map-get($brand-colours, light-gray);
            font-family: $body-font-family;
            font-size: rem-calc(17px);
            margin-top: 5px;

            @include breakpoint(small only) {
                padding-left: 20px;
            }
        }
    }

    .slick-prev {
        background-image: url('img/oetker-collection/left-green.svg');
    }

    .slick-next {
        background-image: url('img/oetker-collection/right-green.svg');
    }
}
