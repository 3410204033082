.contact-travel {
	max-width: 1260px;
	width: 100%;
	margin: 0 auto 30px;

	&__item {
		padding: 15px;
		border: 1px solid #e6e6e6;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		&-staff {
			padding: 0 10px;
			border-right: 1px solid #e6e6e6;

			@include breakpoint(small only) {
				border-right: none;
				border-bottom: 1px solid #e6e6e6;
				padding: 0 0 10px;
			}

			h2 {
				margin-bottom: 30px;
				text-transform: uppercase;

				@include breakpoint(small only) {
					margin-bottom: 15px;
				}
			}

			h3 {
				font-family: $header-font-family;
				margin-bottom: 0;
				text-transform: none;

				&:last-of-type {
					margin-bottom: 15px !important;
				}
			}

			a {

				h4 {
					display: inline-block;
					padding: 8px 0px;
					margin-bottom: 0;
					font-family: $header-font-family;
					font-size: rem-calc(15);
				}

				&:hover {
					color: map-get($brand-colours, group-dark-blue);
					text-decoration: underline;
				}

				img {
					padding-right: 5px;
				}
			}

		}

		&-operators {

			@include breakpoint(small only) {
				padding-top: 15px;
			}

			&-item {
				padding: 15px;
				text-align: center;

				@include breakpoint(medium down) {
					padding: 15px 5px;
				}

				&:nth-child(even) {
					background-color: #FBFAF8;
				}

				p {
					line-height: rem-calc(13);

					@include breakpoint(small only) {
						margin-bottom: 5px;
					}
				}

				h3 {
					font-size: rem-calc(18);
					font-family: $header-font-family;
					margin-bottom: 0;
					line-height: rem-calc(28);
				}
			}

			&-mobile {
				padding: 20px 20px 0;
				text-align: center;

				.accordionButton {
					font-family: $header-font-family;
					font-size: 17px;
					line-height: 20px;

					img {
						transition: 0.3s transform ease-in-out;
						width: 8px;
					}

					&.on {

						img {
							transform: rotate(-180deg);
						}
					}
				}

				.accordionContent {
					padding-top: 50px;
				}

				&-item {

					p {
						font-family: $header-font-family;
						font-size: 16px;
						line-height: 12px;
						margin-bottom: 0;
					}

					h3 {
						font-family: $body-font-family;
						font-size: 14px;
						line-height: 19px;
						margin-bottom: 30px;
					}
				}
			}
		}


	}

}