.tabs {
	border: solid #000;
	border-width: 1px 0;
	text-align: center;
	margin: 0 auto;	

	&-title {
		float: unset;
		display: inline-block;

		&.is-active {

			a {
				background-color: transparent;
				color: #000;
				text-decoration: underline;
			}
		}

		a {
			text-transform: uppercase;
			color: map-get($brand-colours, light-gray);
			font-size: rem-calc(13);
			line-height: rem-calc(20);
			padding: 12px 16px;

			&:hover {
				color: #000;
				text-decoration: underline;
			}
		}

	}

	&-content {
		border: none;
	}
}