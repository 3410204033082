.hotel-header {
	height: 90px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	background-color: rgba(0,0,0,0.60);
	padding: 0 10px;

	&.video-slider {
		background-color: #fff !important;
	}

	&.opened {
		background-color: #fff;
	}

	&__primary-nav {
		padding: 30px 0 0 20px;


		@include breakpoint(xlarge) {
			display: none;
		}

		&-burger {
			font-size: rem-calc(13);
			line-height: rem-calc(13);
			color: #fff;
			text-transform: uppercase;
			display: block;
			opacity: 1;
			transition: 0.5s opacity ease-in;

			// &.fade-in {
			// 	display: block;
			// 	opacity: 1;
			// }

			.button {
				background: url('img/oetker-collection/burger.png') no-repeat;
				background-size: 20px 10px;
				background-position: center left;
				padding: 0;
				padding-left: 20px;
				padding-bottom: 20px;
				text-transform: uppercase;


				&:hover, &:focus {
					background-color: transparent;
					outline: none;
				}

				@include breakpoint(large) {
					padding: 0 0 0 30px;
					margin-top: 10px;
				}

				span {
					font-family: $header-font-family;

					@include breakpoint(medium down) {
						display: none;
					}
				}
			}

			.close-button {
				background: url('img/oetker-collection/close-white.svg') no-repeat;
				background-size: 15px;
				background-position: center left;
				padding: 0;
				padding-left: 15px;
				text-transform: uppercase;
				position: absolute;
				top: auto;
				left: 25px;
				display: none;
				width: 15px;
				height: 15px;
				cursor: pointer;

				&:hover, &:focus {
					background-color: transparent;
					outline: none;
				}

				@include breakpoint(large) {
					padding: 0 0 0px 15px;
				}

				span {
					font-family: $header-font-family;
					color: #fff;
					margin-left: 5px;

					@include breakpoint(medium down) {
						display: none;
					}
				}
			}

		}
	}

	&__logo {
		text-align: center;
		align-self: center;
		padding-top: 9px;

		@include breakpoint(medium) {
			padding-top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		a {

			img {
				width: 100%;
				max-width: 300px;
				height: 60px;
			}
		}

		.logo {

		}

		.logo-dark {
			display: none;
		}

		&-group {
			text-align: center;
			padding: 33px 0 0 20px;

			@include breakpoint(medium only) {
				padding: 33px 10px 0 10px;
			}

			@include breakpoint(xlarge) {
				min-width: 33% !important;
				text-align: left;
			}

			a {

				img {

					width: 100%;
					max-width: 180px;
				}
			}

			.logo {

			}

			.logo-dark {
				display: none;
			}
		}
	}

	&__secondary-nav {
		position: relative;
		padding-right: 20px;

		&-language {
			display: inline-block;
			position: relative;
			margin-right: 20px;

			@include breakpoint(medium down) {
				margin-right: 10px;
			}

			.selected-language {
				font-family: $header-font-family;
				font-size: rem-calc(13);
				line-height: 1.1;
				color: #fff;
				text-transform: uppercase;
				position: relative;

				&::after {
					content: "";
					background: url('img/oetker-collection/arrow-down.svg') no-repeat;
					background-size: 8px 5px;
					background-position: center;
					padding-left: 15px;
					width: 8px;
					height: 5px;
					position: absolute;
					top: 3px;
				}
			}

			.language-list {
				display: none;
				position: absolute;
				top: 30px;
				left: 50%;
				transform: translateX(-50%);

				&.open {
					display: block;
					z-index: 9;
					padding: 7px 15px;
					background-color: rgba(0,0,0,0.2);
					text-align: center;
				}

				ul {
					list-style: none;
					margin: 0;
					white-space: normal;

					li {
						line-height: 1;
						padding: 6px 0;

						&:first-child {
							padding-top: 2px;
						}

						&:last-child {
							padding-bottom: 2px;
						}

						a {
							font-family: $header-font-family;
							font-size: rem-calc(13);
							line-height: rem-calc(13);
							color: #fff;
							text-transform: uppercase;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}

		&-links {
			margin-top: 24px;

			&-list {
				display: none;

				@include breakpoint(large) {
					display: inline-block;
				}

				ul {
					list-style: none;
					margin: 0;

					li {
						display: inline-block;
						margin: 0 10px;

						a {
							font-size: rem-calc(13);
							line-height: rem-calc(13);
							color: #fff;
							text-transform: uppercase;
							font-family: $header-font-family;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}

		&-book {
			display: none;

			@media screen and (min-width: 715px) {
				display: inline-block;
				margin-left: 30px;
			}

			&-container {
				background-color: transparent;
				text-align: center;
				padding: 7px 20px;
				min-width: 92px;
				border: 1px solid #fff;
				position: relative;

				&:hover {
					background-color: map-get($brand-colours, group-dark-blue);
					border: 1px solid rgba(map-get($brand-colours, group-dark-blue),1);
				}

				&::before {
					content: '';
					position: absolute;
					top: 10px;
					left: 5px;
					border: solid rgba(0,0,0,0.5);
					border-width: 0 1px;
					background: transparent;
					transition: 0.3s;
					height: 0%;
					width: calc(100% - 10px);
					opacity: 0;
					pointer-events: none;
				}

				&::after {
					content: '';
					position: absolute;
					top: 5px;
					left: 8px;
					background: transparent;
					border: solid rgba(0,0,0,0.5);
					border-width: 1px 0;
					transition: 0.3s;
					height: calc(100% - 10px);
					width: 0%;
					opacity: 0;
					pointer-events: none;
				}

				&:hover {

					&::before {
						height: calc(100% - 20px);
						opacity: 1;
					}

					&::after {
						width: calc(100% - 15px);
						opacity: 1;
					}
				}

				a {
					font-size: rem-calc(13);
					line-height: rem-calc(13);
					color: #fff;
					text-transform: uppercase;
					padding: 8px 20px 7px;
					position: relative;
					font-family: $header-font-family;

					&:hover {

					}
				}
			}
		}
	}

	&__sub-nav {
		position: absolute;
		top: 90px;
		left: 0;
		background-color: rgba(0,0,0,0.60);
		text-align: center;
		border-top: 1px solid #fff;
		padding: 8px 0 4px;

		@include breakpoint(large down) {
			display: none;
		}

		ul {
			list-style: none;
			margin: 0;
			position: relative;

			li {
				display: inline-block;
				margin: 0 10px;

				&.hidden {
					display: none;
				}

				a {
					font-family: $header-font-family;
					font-size: 0.875rem;
					line-height: 0.875rem;
					color: #fff;
					text-transform: uppercase;
					letter-spacing: 2px;
				}

				&.active {

					> a {
						border-bottom: 1px solid #fff;
					}
				}

				ul {
					position: inherit;
					padding-bottom: 10px;
					background: url('img/oetker-collection/arrow-down.svg') no-repeat;
					background-position: center bottom;
					background-size: 10px;
				}

				&.show-dropdown {

					ul {
						background: url('img/oetker-collection/arrow-up.svg') no-repeat;
						background-position: center bottom;
						background-size: 10px;
					}
				}
			}
		}

		&-dropdown {
			width: 100%;
			background: rgba(0,0,0,0.60);
			padding: 30px;
			margin: 0 auto;

			&-wrapper {
				display: none;
				position: absolute;
				top: 24px;
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				padding-top: 15px;
				width: 100%;
			}

			&-container {
				width: 100%;
				height: 30px;
				background-color: transparent;
			}

			&-left {
				border-right: 1px solid #fff;
				text-align: left;

				li {
					margin: 0 0 10px 0;

					a {
						text-transform: unset !important;
						color: #fff !important;

						&:hover {
							text-decoration: underline;
						}
					}

					p {
						font-family: $body-font-family;
						color: map-get($brand-colours, light-gray);
						font-weight: 400;
						margin-bottom: 0;
						font-size: rem-calc(13);
						letter-spacing: 1px;
					}
				}
			}

			&-right {
				text-align: left;

				li {
					margin: 0 0 10px 40px !important;

					a {
						text-transform: unset !important;
						color: #fff !important;

						&:hover {
							text-decoration: underline;
						}
					}

					p {
						font-family: $header-font-family;
						color: map-get($brand-colours, light-gray);
						font-weight: 400;
						margin-bottom: 0;
						font-size: rem-calc(13);
						letter-spacing: 1px;
					}
				}
			}
		}

		&-more {
			display: none !important;

			&--active {
				display: inline-block !important;
			}
		}
	}

	&__mobile-booking {
		position: fixed;
		bottom: 0;
		left: 0;
		border-bottom: env(safe-area-inset-bottom) solid #fff;
		padding: 5px 5px;
		background-color: map-get($brand-colours, group-dark-blue);
		z-index: 15;
		min-height: 34px;
		border-top: 1px solid map-get($brand-colours, group-dark-blue);

		@media screen and (min-width: 900px) {
			display: none;
		}

		&-container {
			text-align: center;

			a {
				display: block;
				font-size: rem-calc(13);
				line-height: rem-calc(13);
				color: map-get($brand-colours, light-gray);
				text-transform: uppercase;
				padding: 6px 15px 4px;
				font-family: $header-font-family;
			}
		}
	}
}

@mixin fixed_hotel_header() {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	background-color: #fff;

	.hotel-header__logo {
		.logo {
			display: none !important;
		}

		.logo-dark {
			display: block !important;
		}
	}

	.hotel-header__logo-group {
		.logo {
			display: none !important;
		}

		.logo-dark {
			display: block !important;
		}
	}

	.hotel-header__primary-nav {

		.hotel-header__primary-nav-burger {

			.button {
				background: url('img/oetker-collection/burger-black.png') no-repeat;
				background-size: 20px 10px;

				span {
					color: #000;
				}
			}

			.close-button {
				background: url('img/oetker-collection/close.svg') no-repeat;
				background-size: 15px;

				span {
					color: #000;
				}
			}
		}
	}

	.hotel-header__logo {

		img {
			width: 100%;
			height: 60px;
		}
	}

	.hotel-header__secondary-nav-language {
		color: #000;

		button {
			color: #000;

			&::after {
				background: url('img/oetker-collection/arrow-down-black.svg') no-repeat;
				background-size: 8px 5px;
				background-position: center;
			}
		}

		.language-list.open {
			background-color: #fff;
			border: 1px solid #e6e6e6;
		}
	}

	.language-list ul li a {
		color: #000;
	}

	.hotel-header__secondary-nav-links ul li a {
		color: #000;
	}

	.hotel-header__secondary-nav-book-container {
		background-color: map-get($brand-colours, group-dark-blue);
		position: relative;

		a {
			color: map-get($brand-colours, light-gray);
		}
	}

	.hotel-header__sub-nav {
		background-color: #fff;
		border-color: rgba(map-get($brand-colours, light-gray),0.46);
		border-bottom: 1px solid rgba(map-get($brand-colours, light-gray),0.46);

		> ul {

			li {

				> a {
					color: map-get($brand-colours, medium-gray);

					&:hover {
						color: #000;
					}
				}

				&.active {

					> a {
						border-bottom: 1px solid #000;
						color: #000;
					}
				}
			}
		}
	}

}

.fixed.hotel-header {
	@include fixed_hotel_header();
}

// Disabling the fixed header for mobile and portrait to base this on Mayfair Townhouse style
/*@include breakpoint(small only) {
	.hotel-header {
		@include fixed_hotel_header();
	}
}*/

/*@media screen and (orientation: portrait) {
	.hotel-header {
		@include fixed_hotel_header();
	}
}*/

.show-dropdown ul .hotel-header__sub-nav-dropdown-wrapper {
	display: inline-flex;
}


.fixed .hotel-header__sub-nav {

	ul {

		li {

			ul {
				background: url('img/oetker-collection/arrow-down-black.svg') no-repeat center bottom;
				background-size: 10px;
			}

			&.show-dropdown {

				ul {
					background: url('img/oetker-collection/arrow-up-black.svg') no-repeat;
					background-position: center bottom;
					background-size: 10px;
				}
			}
		}
	}
}

.show-dropdown {
	>a {
		border-bottom: 1px solid #fff;
	}
}

.fixed .show-dropdown {

	>a {
		border-bottom: 1px solid #000 ;
	}
}

.no-hero .hotel-header {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	background-color: #fff;

	.hotel-header__primary-nav {

		.hotel-header__primary-nav-burger {

			.button {
				background: url('img/oetker-collection/burger-black.png') no-repeat;
				background-size: 20px 10px;

				span {
					color: #000;
				}
			}

			.close-button {
				background: url('img/oetker-collection/close.svg') no-repeat;
				background-size: 15px;

				span {
					color: #000;
				}
			}
		}
	}

	.hotel-header__logo {

		.logo {
			display: none;
		}

		.logo-dark {
			display: block;
		}

		img {
			width: 100%;
			height: 60px;
		}

		&-group {

			.logo {
				display: none;
			}

			.logo-dark {
				display: block;
			}
		}
	}

	.hotel-header__secondary-nav-language {
		color: #000;

		button {
			color: #000;

			&::after {
				background: url('img/oetker-collection/arrow-down-black.svg') no-repeat;
				background-size: 8px 5px;
				background-position: center;
			}
		}
	}

	.hotel-header__secondary-nav-book-container {
		background-color: map-get($brand-colours, group-dark-blue);
		position: relative;

		a {
			color: map-get($brand-colours, light-gray);
		}
	}

	.language-list.open {
		background-color: rgba(255,255,255,1);
		border: 1px solid #e6e6e6;
	}

	.language-list ul li a {
		color: #000;
	}

	.hotel-header__secondary-nav-links ul li a {
		color: #000;
	}

	.hotel-header__sub-nav {
		background-color: #fff;
		border-color: map-get($brand-colours, light-gray);
		border-bottom: 1px solid rgba(map-get($brand-colours, light-gray),0.5);

		> ul {

			li {

				> a {
					color: map-get($brand-colours, medium-gray);

					&:hover {
						color: #000;
					}
				}

				&.active {

					> a {
						border-bottom: 1px solid #000;
						color: #000;
					}
				}
			}
		}
	}

}

.no-hero .hotel-header__sub-nav {

	ul {

		li {

			ul {
				background: url(img/oetker-collection/arrow-down-black.svg) no-repeat center bottom;
				background-size: 10px;
			}
		}
	}
}

.no-hero .show-dropdown {

	>a {
		border-bottom: 1px solid #000 ;
	}
}

.hotel-header__mobile-booking.over-footer {
	border-top: 1px solid map-get($brand-colours, dark-gray);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS styles go here */

	.hotel-header__sub-nav-dropdown-wrapper {
		top: 20px;
		padding: 0;
	}

}

body.video-slider .hotel-header {

	@include breakpoint(small only) {
		@include fixed_hotel_header();
	}
}


@include breakpoint(small only) {
	// .book-now-container {
	// 	display: none;
	// }

}


//hide booking bar on offer details for mobile
body.mobile-booking-hide {

	.hotel-header__mobile-booking {
		display: none;
	}
}