.blog-suggestions {
	width: 100%;
	max-width: 975px;
	margin: 0 auto 80px;
	padding: 0 25px;
	text-align: center;
	
	&__header {
		border-top: 1px solid #000;
		border-bottom: 4px solid #000;
		padding: 40px 0 0;
		margin-bottom: 25px;
		
		@include breakpoint(medium down) {
			padding: 40px 25px 0;
		}
		
		h2 {
			text-transform: uppercase;
			font-family: $blog-font-family;
			font-size: 13px;
			letter-spacing: 0.16em;
			margin-bottom: 40px;
		}
	}
	
	&__carousel {
		position: relative;
		margin-left: -10px;
		margin-right: -10px;
		
		&-inner {
			margin-bottom: 45px;
			display: flex;
			justify-content: center;
		}
		
		.slick-arrow {
			top: 78px;
			
			&.slick-prev {
				left: 25px;
				
				@include breakpoint(large) {
					left: -32px;
				}
			}
			
			&.slick-next {
				right: 25px;
				
				@include breakpoint(large) {
					right: -32px;
				}
			}
		}
	}
	
	&__item {
		margin-bottom: 30px;
		
		&-inner {
			padding: 0 10px;
			//max-width: 280px;
			margin: auto;
		}

		&-content {
			margin-bottom: 15px;
		}
		
		img {
			margin-bottom: 27px;

			@include breakpoint(small only) {
				margin-bottom: 15px;
			}
		}
		
		h3 {
			position: relative;
			display: block;
			color: #000;
			font-family: $blog-font-family;
			font-size: 10px;
			letter-spacing: 0.16em;
			margin-bottom: 20px;
			
			&::after {
				content: '';
				width: 24px;
				height: 1px;
				background: #000;
				display: block;
				margin: 4px auto 0;
			}
		}
		
		h4 {
			font-size: 25px;
			line-height: 29px;
			font-family: $header-font-family;
			margin-bottom: 15px;
			
			a {
				color: inherit;
			}
		}
	}
	
	&__cta {
		a {
			color: #000;
			font-style: italic;
			font-family: $header-font-family;
			font-size: 17px;
			display: inline-block;
			padding-bottom: 0;
			border-bottom: 1px solid #000;
			text-transform: lowercase;
		}
	}
	&__load-more {
		border-top: 1px solid #000;
		padding-top: 25px;

		.accordionButton {
			cursor: pointer;

			h4 {
				font-family: $header-font-family;
				color: #000;
				position: relative;
				font-size: 20px;
				line-height: 30px;
				font-style: italic;

				&:after {
					content: '';
					background: url('img/oetker-collection/blog-arrow-down-black.svg') no-repeat bottom center;
					position: absolute;
					bottom: -25px;
					left: 50%;
					width: 50%;
					height: 15px;
					transform: translateX(-50%);
				}
			}

			&.on {

				h4 {
					display: none;
				}
			}
		}

		.accordionContent {
			justify-content: center;
		}
	}
}