.blog-featured-article {
	margin-bottom: 35px;
	padding: 90px 65px;
	position: relative;
	
	@include breakpoint(small only) {
		padding: 30px 25px;
	}
	
	&__category {
		&::after {
			content: '';
			display: inline-block;
			width: 40px;
			height: 1px;
			background: #000;
			margin: 15px 0;
		}
		
		h3 {
			display: block;
			color: #000;
			font-family: $blog-font-family;
			font-size: 10px;
			line-height: 1;
			letter-spacing: 0.16em;
			margin: 0;
		}
	}
	
	&__title {
		h1,
		h2 {
			font-size: 25px;
			line-height: 32px;
			text-transform: uppercase;
			margin-bottom: 10px;
			
			@include breakpoint(small only) {
				font-size: 30px;
				line-height: 34px;
			}
			
			a {
				color: #000;
			}
		}
	}
	
	&__cta {
		a {
			color: #000;
			font-style: italic;
			font-family: $header-font-family;
			font-size: 17px;
			display: inline-block;
			padding-bottom: 0;
			border-bottom: 1px solid #000;
			text-transform: lowercase;
		}
	}

	&__discover {
		position: absolute;
		left: 0;
		bottom: 30px;

		span {
			color: #fff;
			text-transform: uppercase;
			position: relative;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.1rem;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				left: 50%;
				bottom: -15px;
				transform: translateX(-50%);
				background: url('img/oetker-collection/blog-arrow-down-white.svg') no-repeat center center;
				width: 20px;
				height: 100%;

				@include breakpoint(small only) {
					bottom: -23px;
				}
			}
		}
	}
}