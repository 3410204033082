.grid-tile {
    padding: 0;
    margin-top: -30px;
    display: none;

    &.active {
        display: flex;
        justify-content: center;
        
    }

    @include breakpoint(medium down) {
        padding: 0;
    }

    &__equalizer {
        width: 100%;

        &--centered {
            justify-content: center;
        }
    }

    &__item {
        text-align: center;
        padding: 0 20px;
        margin-top: 30px;
      

        &:focus {
            outline: none;
        }

        &-img {
            position: relative;
            width: 100%;
            padding-bottom: 67.65%; // 34:23 aspect ratio

            &.border-on {
                border: solid #e6e6e6;
                border-width: 1px 1px 0px 1px;
            }

            &.slick-initialized {
                padding-bottom: 0;
            }

            > a,
            > img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            .slick-prev {
                background: url('img/oetker-collection/left.svg') no-repeat center;
                left: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;
                z-index: 2;

                &::before {
                    content: '';
                }
            }

            .slick-next {
                background: url('img/oetker-collection/right.svg') no-repeat center;
                right: 10px;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;

                &::before {
                    content: '';
                }
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 67.65%; // 34:23 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }

        &-container {
            max-width: 650px;
            margin: 0 auto;
        }

        &-detail {
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;
            padding: 10px 20px 20px;

            @include breakpoint(medium down) {
                padding: 10px 10px 20px;
            }

            div {
                margin-bottom: 15px;
            }

            h4 {
                font-size: rem-calc(13);
            }

            h2 {
                margin-bottom: 0;
                font-size: rem-calc(24);
                
                &:first-child {
                    margin-top: 18px;
                }
            }

            p {
                font-size: rem-calc(17);
            }

            ul {
                display: inline-flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 0 15px;
                padding: 0;
                list-style: none;
                font-size: rem-calc(13px);
                line-height: rem-calc(13px);

                li {
                    display: inline-block;
                    margin: 0 0 5px 0;

                    &:after {
                        content: '|';
                        padding: 0 5px;
                        display: inline-block;
                        width: rem-calc(15px);
                        height: rem-calc(13px);
                        margin-right: -rem-calc(14px);
                    }

                    &.no-pipe-separator {
                        &:after {
                            color: transparent;
                        }
                    }

                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                }
            }

            a {
                color: map-get($brand-colours, group-dark-blue);

                &:hover {
                    text-decoration: underline;
                }

                &.cta {
                    display: inline-block;
                    margin-bottom: 15px;
                }
            }
        }

        &-links {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;

            @at-root .grid--horizontal-links #{&} {
                flex-direction: row;
            }

            > div {
            }
        }
    }
    // &__item.large-3 {
    //     @include breakpoint (medium up) {
    //         h2 {
    //             font-size: 1.1rem;
    //         }
    //         h4 {
    //             font-size: 0.7rem;
    //         }
    //     }
    // }
}
