.small-gallery {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 30px;
    padding: 0;

    &__grid {
        display: flex;
        flex-flow: column;
        max-width: 670px;
        margin: 0 auto;

        @media screen and (min-width: 735px) {
            flex-flow: row wrap;
        }

        > div:first-child {
            > * {
                margin-top: 0;

                @include breakpoint(small only) {
                    margin-top: 10px;
                }
            }
        }

        img {
            outline: transparent;
            cursor: pointer;
        }

        &-large-small,
        &-full,
        &-three,
        &-two {
            max-width: 100%;
            flex: 3 3 auto;
            display: flex;

            @media screen and (max-width: 734px) {
                flex-direction: column;
            }

            img {
                @include breakpoint(small only) {
                    display: block;
                    margin: 0 auto;
                    max-height: 200px;
                    max-width: 200px;
                    width: 100%;
                }
            }
        }

        &-left,
        &-right,
        &-full,
        &-three,
        &-two {
            font-size: 0;

            // img {
            //     @media screen and (max-width: 734px) {
            //         &[data-slide-index]:not([data-slide-index='0']):not([data-slide-index='1']) {
            //             opacity: 0;
            //             height: 0;
            //             margin: 0;
            //         }
            //     }
            // }
        }

        &-left {
            flex: 2;
            margin: 0 auto;
            text-align: center;

            > div {
                margin-bottom: 10px;

                @media screen and (min-width: 735px) {
                    padding-right: 2px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @media screen and (min-width: 735px) {
                margin: 10px 0 0;

                &:not(.small-gallery__grid-left--large) {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    > div {
                        padding-right: 6px;
                    }
                }
            }


        }

        &-right {
            flex: 2;
            margin: 0 auto;
            text-align: center;

            > div {
                margin-bottom: 10px;

                @media screen and (min-width: 735px) {
                    padding-left: 2px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @media screen and (min-width: 735px) {
                flex: 2;
                margin: 10px 0 0;

                &.small-gallery__grid-right--small {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    > div {
                        padding-left: 6px;
                    }
                }
            }

            @include breakpoint(small only) {
                margin-top: 10px;
            }
        }

        &-full {
            width: 100%;

            @include breakpoint(small only) {
                margin-top: 10px;
            }

            img {
                margin-top: 0px;
                margin-bottom: 10px;

                @media screen and (min-width: 735px) {
                    margin-top: 10px;
                    margin-bottom: 0;
                }
            }
        }

        &-two {
            margin: 0 auto;

            > div {
                margin-top: 0px;
                margin-bottom: 10px;

                @media screen and (min-width: 735px) {
                    flex: 1 1 auto;
                    margin-top: 10px;
                    margin-bottom: 0;
                    margin-right: 11px;
                }

                &:last-child {
                    @include breakpoint(medium) {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-three {
            margin: 0 auto;

            @include breakpoint(small only) {
                margin-top: 10px;
            }

            > div {
                margin-top: 0px;
                margin-bottom: 10px;

                @media screen and (min-width: 735px) {
                    flex: 1 1 auto;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-right: 8px;
                    margin-bottom: 0;
                }

                &:last-child {
                    @include breakpoint(medium) {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

body.gallery-modal-open {
    .reveal-overlay {
        background-color: rgba(#000, 0.7);
    }
}

.reveal-overlay {
    .slick-slider .slick-arrow {
        outline: transparent;
        cursor: pointer;
        width: 16px;
        height: 37px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: cover;

        &::before {
            display: none;
        }

        &.control-prev {
            left: -25px;

            &--light {
                background-image: url(../assets/img/gallery-arrow-left.svg);
            }
        }

        &.control-next {
            right: -25px;

            &--light {
                background-image: url(../assets/img/gallery-arrow-right.svg);
            }
        }

        &.slick-disabled {
            opacity: .5;
        }
    }
}


/* temporary fixes https://support.eighty-days.com/a/tickets/21221 */
.new-small-gallery__grid {
	text-align: center;
}

.new-small-gallery-container .one-item img {
	width: 100%;
  padding: 10px 0px 10px 0px;
  max-width: 880px;
}
