.blog-comments {
	width: 100%;
	max-width: 842px;
	margin: 0 auto;
	padding: 0 25px;

	@include breakpoint(small only) {
		padding: 10px;
	}

	h2 {
		font-family: $blog-font-family;
		font-size: 13px;
		letter-spacing: 0.16em;
		text-transform: uppercase;
		text-align: center;
	}

	&__form {
		padding-bottom: 80px;
		margin-bottom: 35px;
		border-bottom: 1px solid #000;
		text-align: center;

		h2 {
			margin-bottom: 60px;
		}

		input,
		textarea {
			margin-bottom: 10px;
			font-family: $body-font-family;
		}

		input[type="text"],
		input[type="email"],
		textarea {
			width: 100%;
			border: 1px solid #ABABAB;
			color: #000;
			font-size: 16px;
			letter-spacing: 0.05em;
			padding: 10px;
		}

		input[type="text"],
		input[type="email"] {
			height: 42px;
		}

		textarea {
			height: 134px;
			text-align: left;
		}

		&-submit {
			position: relative;
			background-color: map-get($brand-colours, group-dark-blue);
			text-align: center;
			padding: 8px 5px;
			min-height: 40px;
			height: auto !important;
			display: inline-block;

			&::before {
				content: '';
				position: absolute;
				top: 10px;
				left: 5px;
				border: solid rgba(0,0,0,0.5);
				border-width: 0 1px;
				background: transparent;
				transition: 0.3s;
				height: 0%;
				width: calc(100% - 10px);
				opacity: 0;
				pointer-events: none;
			}

			&::after {
				content: '';
				position: absolute;
				top: 5px;
				left: 8px;
				background: transparent;
				border: solid rgba(0,0,0,0.5);
				border-width: 1px 0;
				transition: 0.3s;
				height: calc(100% - 10px);
				width: 0%;
				opacity: 0;
				pointer-events: none;
			}

			&:hover {

				&::before {
					height: calc(100% - 20px);
					opacity: 1;
				}

				&::after {
					width: calc(100% - 15px);
					opacity: 1;
				}
			}

			input[type="submit"] {
				display: inline-block;
				text-transform: uppercase;
				cursor: pointer;
				font-family: $header-font-family;
				font-size: 13px;
				padding: 8px 20px 3px;
				background-color: transparent;
				border: 0;
				margin: 0 !important;
				color: map-get($brand-colours, light-gray);
			}
		}
	}


	&__list {
		h2 {
			margin-bottom: 40px;
			font-size: 13px;
		}

		ul {
			border-top: 4px solid #000;
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		li {
			padding: 25px 30px 0;
			margin-bottom: 25px;

			@include breakpoint(small only) {
				padding: 0 10px 0px 0;
			}

			&.oetker-collection {
				background-color: rgba(#ababab,0.22);
				padding-bottom: 25px;
				padding-bottom: 10px;

				img {
					width: 200px;
				}
			}
		}

		& > ul {

			& > li {
				border-bottom: 1px solid #b8b8b8;
				margin-bottom: 25px;
				padding: 25px 30px 45px;

				@include breakpoint(small only) {
					padding: 25px 10px 45px;
				}

				&:last-of-type {
					border-bottom: 0;
				}
			}
		}

		blockquote {
			margin: 0;
			padding: 0;
			border: 0;
			color: #000;

			p {
				font-size: 16px;
				line-height: 27px;
				padding-bottom: 20px;
				margin-bottom: 0;
				color: #000;
			}

			cite {
				font-style: normal;
				font-family: $header-font-family;
				font-size: 22px;
				color: #000;
				display: inline-block;
				margin-right: 30px;
				margin-bottom: 30px;

				@include breakpoint(small only) {
					display: block;
					margin-bottom: 15px;
					margin-right: 0;
				}
			}

			h4 {
				display: inline-block;
				font-family: $body-font-family;
				text-transform: none;
				font-size: 15px;
				color: #000;
				margin-bottom: 30px;

				@include breakpoint(small only) {
					display: block;
				}
			}
		}
	}

	&__reply {
		display: inline-block;
		padding-left: 30px;

		@include breakpoint(small only) {
			padding-left: 15px;
		}

		&.first {
			padding-left: 0;
			border: 0;

			@include breakpoint(small only) {
				padding-left: 0;
			}
		}

		button {
			display: inline-block;
			margin-right: 30px;
			font-family: $body-font-family;
			font-size: 15px;
			color: #000;
    		padding: 0 0 4px;
    		cursor: pointer;
    		vertical-align: top;
    		height: 25px;

			@include breakpoint(small only) {
				margin-right: 20px;
			}
		}

		&-show-hide {
			display: inline-block;
			width: calc(100% - 100px);
			vertical-align: top;

			.accordionButton {
				display: inline-block;
				cursor: pointer;
				position: relative;
				padding-right: 24px;

				p {
					font-family: $body-font-family;
					font-size: 15px;
					color: #000;
					padding-bottom: 20px;
					margin-bottom: 0;

					&.show {
						display: inline-block;
						opacity: 1;
						transition: 0.3s all ease-in-out;
					}

					&.hide {
						display: none;
						opacity: 0;
						transition: 0.3s all ease-in-out;
					}
				}

				img {
					position: absolute;
					top: 9px;
					right: 0;
					transform: rotate(180deg);
					transition: all 0.5s ease-in-out;
					width: 12px !important;
				}

				&.on {

					p {

						&.show {
							display: none;
							opacity: 0;
						}

						&.hide {
							display: inline-block !important;
							opacity: 1;
						}
					}

					img {
						transform: rotate(0deg);
					}
				}
			}

			.accordionContent {
				margin-left: -65px;
				width: calc(100% + 100px);

				@include breakpoint(small only) {
					margin-left: -55px;
				}
			}
		}

		&-comments {
			border: none !important;

			.blog-comments__reply {
				padding-left: 0;

				@include breakpoint(small only) {
					padding-left: 15px;
				}
			}

			li {
				position: relative;
				margin-bottom: 30px;
				border-left: 1px solid #b8b8b8;
			}

			blockquote {
				padding-left: 0px;

				@include breakpoint(small only) {
					padding-left: 15px;
				}

				p {
					color: rgba(0,0,0,0.7);
				}

				cite {
					color: rgba(0,0,0,0.7);
					margin-bottom: 20px;

					@include breakpoint(small only) {
						margin-bottom: 15px
					}
				}

				h4 {
					color: rgba(0,0,0,0.7);
					margin-bottom: 20px;
				}
			}
		}

		&-popup {
			background-color: rgba(#ffffff, 0.9) !important;

			&-wrap {
				padding: 30px;
				border: 1px solid #c0c0c0;
				position: relative;
				max-width: 1260px;
				width: 100%;
				margin: 0 auto;
				background-color: #fff;
			}

			&-close {
				position: absolute;
				top: 15px;
				right: 15px;
				width: 14px;
				height: 14px;
				background: url('img/oetker-collection/close.svg') no-repeat center center;
				background-size: 14px;
				cursor: pointer;
			}
		}

		&-form {

			h2 {
				font-size: 13px;
				font-family: $blog-font-family;
				letter-spacing: 0.16em;
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 35px;
			}

			.form {

				& > div {
					margin-bottom: 30px;
				}

				input[type="text"], input[type="email"], textarea {
					font-size: 16px;
					letter-spacing: 0.053em;
					text-transform: uppercase;
					font-family: $body-font-family;
					border: 1px solid #ababab;
					box-shadow: none;
					background-color: #fff;

					&::placeholder {
						font-size: 16px;
						letter-spacing: 0.053em;
						text-transform: uppercase;
						font-family: $body-font-family;
					}
				}

				textarea {
					min-height: 130px;
				}
			}

			&-submit {
				background-color: map-get($brand-colours, group-dark-blue);
				text-align: center;
				padding: 8px 5px;
				min-height: 40px;
				height: auto !important;
				display: inline-block;

				&:hover, &:focus {
					background-color: map-get($brand-colours, group-light-blue);
				}

				input[type="submit"] {
					display: inline-block;
					text-transform: uppercase;
					border: 1px solid #fff;
					cursor: pointer;
					font-size: 13px;
					padding: 8px 20px 7px;
					background-color: transparent;
					margin: 0 !important;
					color: #fff;
				}
			}
		}
	}
}

.blog-comments__wrapper {
	padding: 10px 0 0 0;
}
