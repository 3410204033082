.hotel-location {
    position: relative;
    overflow: hidden;
    margin: 0 auto 30px;

    &__map {
        height: 525px;
        max-height: 80vh;

        @include breakpoint(medium) {
            max-height: none;
        }

        &-content {
            position: absolute;
            top: 35px;
            right: 0;
            bottom: 0;
            transform: translateX(100%);
            transition: transform 0.3s linear;
            width: 100%;
            max-width: 465px;
            background-color: #fff;
            text-align: center;
            padding: 0px 0 20px;
            
            @include breakpoint(medium) {
                top: 0;
            }

            @include breakpoint(small only) {
                top: 50%;
                bottom: auto;
                max-width: 285px;
                transform: translate(100%,-50%)
            }

            &.marker-active {
                transform: translateX(0);

                @include breakpoint(small only) {
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }

            h2 {
                max-width: 300px;
                width: 100%;
                margin: 0 auto 10px;
                font-size: 28px;
                line-height: 32px;
                padding-top: 20px;
            }

            p {
                max-width: 300px;
                width: 100%;
                margin: 0 auto 10px;

                @include breakpoint(small only) {
                    font-size: 12px
                }
            }

            &-close {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 15px;
                height: 15px;
                background: url('img/oetker-collection/close.svg') no-repeat center center;
                background-size: 100%;
                cursor: pointer;
    
                &:focus {
                    outline: transparent;
                }
    
                @include breakpoint(small only) {
                    top: 45px;
                }
            }
        }

        &-phone {
            font-family: $header-font-family;
            font-size: 15px;
            line-height: 13px;
            background: url('img/phone.svg') no-repeat left center;
            background-size: 16px 13px;
            margin: 0 auto 15px;
            padding-left: 25px;
            color: map-get($brand-colours, light-gray);

            &:hover {
                color: map-get($brand-colours, light-gray);
                text-decoration: underline;
            }

            @include breakpoint(small only) {
                font-size: 12px;
                background: none;
                padding-left: 0;
            }
        }
        
        &-mail {
            font-family: $header-font-family;
            font-size: 15px;
            line-height: 13px;
            background: url('img/envelope.svg') no-repeat left center;
            background-size: 24px 15px;
            margin: 0 auto;
            padding-left: 30px;
            color: map-get($brand-colours, light-gray);

            &:hover {
                color: map-get($brand-colours, light-gray);
                text-decoration: underline;
            }

            @include breakpoint(small only) {
                font-size: 12px;
                background: none;
                padding-left: 0;
            }
        }
    }

    &__marker {
        position: absolute;
        top: 35px;
        right: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: transform 0.3s linear;
        width: 100%;
        max-width: 425px;
        background-color: #fff;
        text-align: center;
        font-family: $header-font-family;

        @include breakpoint(medium) {
            top: 0;
        }

        &--active {
            transform: translateX(0);
        }

        &-content {
            padding: 40px;
        }

        &-close {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 15px;
            height: 15px;
            background: url('img/oetker-collection/close.svg') no-repeat center center;
            background-size: 100%;
            cursor: pointer;

            &:focus {
                outline: transparent;
            }

            @include breakpoint(small only) {
                top: 45px;
            }
        }

        &-heading {
            font-size: rem-calc(35px);
            margin-bottom: 35px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &-description {
            font-size: rem-calc(13px);
            padding: 10px 0;

            p:last-child {
                margin-bottom: 0;
            }
        }

        &-slider {
            .slick-prev,
            .slick-next {
                background-repeat: no-repeat;
                background-position: center;
                top: 50%;
                transform: translate(0%, -50%);
                background-size: 15px 30px;
                width: 30px;
                height: 30px;
                z-index: 2;

                &::before {
                    content: '';
                }
            }

            .slick-prev {
                background-image: url('img/oetker-collection/left.svg');
                left: 10px;
            }

            .slick-next {
                background-image: url('img/oetker-collection/right.svg');
                right: 10px;
            }
        }

        &-video {
            position: relative;
            width: 100%;
            padding-bottom: 75%; // 4:3 aspect ratio

            .mejs__player {
                position: absolute;
                top: 0;
                left: 0;
            }

            .mejs__player,
            .mejs__overlay,
            video,
            iframe {
                max-width: 100%;
                height: 100% !important;
            }
        }
    }

    &__filters {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        background-color: #fff;
        border-top: 1px solid map-get($brand-colours, lightest-gray);
        border-bottom: 1px solid map-get($brand-colours, lightest-gray);
        

        @include breakpoint(medium) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            padding: 10px;
        }

        @include breakpoint(small only) {
            
        }

        p {
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 0;
            letter-spacing: 2px;
            font-size: rem-calc(13);
            line-height: 1;
            text-transform: uppercase;
            display: none;

            @include breakpoint(small only) {
                margin-right: 0;
            }
        }



        ul {
            display: none;
            margin: 0 auto;
            padding: 10px 0;
            text-align: left;

            @at-root .hotel-location__filters--expanded#{&} {
                display: inline-block;
            }

            @include breakpoint(medium) {
                display: inline-block;
                padding: 0;
            }

            li {
                display: block;
                padding: 0 0 10px 0;

                @include breakpoint(medium) {
                    display: inline-block;
                    padding: 0;
                }

                input[type='checkbox'] {
                    display: none;
                }
            }
        }
    }

    &__filter {
        padding: 5px;
        font-size: rem-calc(13px);
        line-height: rem-calc(13px);
        text-transform: uppercase;
        cursor: pointer;
        font-family: $header-font-family;

        @at-root input[type='checkbox']:not(:checked) + #{&} {
            span {
                background-color: #fff !important;
            }       
        }

        @include breakpoint(medium) {
            padding: 0 25px;
        }

        &-all-wrapper {
            
        }

        &-toggle {
            position: relative;
            display: block;
            width: 100%;
            padding: 12px;
            font-size: rem-calc(13px);
            text-transform: uppercase;
            text-align: center;
            color: #fff;
            background-color: map-get($brand-colours, group-dark-blue);
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                width: 20px;
                height: 5px;
                background: url('img/oetker-collection/arrow-down.svg') no-repeat center 100%;

                @at-root .hotel-location__filters--expanded #{&} {
                    transform: rotate(180deg);
                    transform-origin: center;
                }
            }

            @include breakpoint(medium) {
                display: none;
            }
        }

        &-circle {
            display: inline-block;
            vertical-align: text-top;
            width: 12px;
            height: 12px;
            border: 2px solid;
            border-radius: 12px;
        }

        &-all {
            padding: 5px 15px;
            font-size: 13px;
            color: #0a0a0a;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &-wrapper {
                display: inline-block;
            }
        }
    }
}

body.no-hero .hotel-location {
    margin-top: 160px;

    @include breakpoint(medium down) {
        margin-top: 90px;
    }
}
