.social-feed {
	max-width: 1260px;
	width: 100%;
	margin: 0 auto 30px;

	&__container {
		padding-top: 20px;
		
		&.crt-feed.crt-feed-branded {
			padding-bottom: 0;
		}

		&.crt-widget-grid.crt-grid-col1 .crt-grid-post {
			@include breakpoint(small) {
				width: 50%;
			}
		}

				
	}

	.crt-load-more-container {
		position: relative;

		.translator-wrapper {
			position: absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;

			span {
				font-size: 0.9rem;
				line-height: rem-calc(15);
				color: #000;
				letter-spacing: 0.1em;
				font-family: $header-font-family;
				padding-bottom: 6px;
				border-bottom: 1px solid #000;
				text-transform: lowercase;
				transition: 0.3s ease-in-out;
			}
		}
	}

	.crt-load-more {
		border: 0 !important;

		span {
			font-size: 0.9rem;
			line-height: rem-calc(15);
			color: #000;
			letter-spacing: 0.1em;
			font-family: $header-font-family;
			padding-bottom: 6px;
			border-bottom: 1px solid #000;
			text-transform: lowercase;
			transition: 0.3s ease-in-out;
		}

		&:hover {

			span {
				color: map-get($brand-colours, group-dark-blue);
				border-color: map-get($brand-colours, group-dark-blue);
			}
		}
	}

	.crt-widget-grid .crt-grid-post {
		padding: 10px;
	}
}