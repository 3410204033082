button:focus {
	outline: none;
}

.property-listing__item {
    
    .book-now-container {

        // display: grid;
        // grid-template-columns: 1fr;
        // gap: 1rem;

        .cta {
            // margin: 0px;
        }

        .book-button {
            width: 100%;
            display: flex;
            padding: 0px;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

	.book-now-wrapper {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.book-now {
	&-container {
		display: block;
		margin-bottom: 15px;
		font-size: 1rem;
		cursor: pointer;

		&--disabled {
			opacity: .7;
			pointer-events: none;
		}
	}

	&-wrapper {
		display: inline-block;
		background-color: map-get($brand-colours, group-dark-blue);
		text-align: center;
		padding: 4px 5px;
		min-height: 40px;
		height: auto !important;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 10px;
			left: 5px;
			border: solid rgba(0,0,0,0.5);
			border-width: 0 1px;
			background: transparent;
			transition: 0.3s;
			height: 0%;
			width: calc(100% - 10px);
			opacity: 0;
			pointer-events: none;
		}

		&::after {
			content: '';
			position: absolute;
			top: 5px;
			left: 8px;
			background: transparent;
			border: solid rgba(0,0,0,0.5);
			border-width: 1px 0;
			transition: 0.3s;
			height: calc(100% - 10px);
			width: 0%;
			opacity: 0;
			pointer-events: none;
		}

		&:hover {
			background-color: map-get($brand-colours, group-dark-blue);

			&::before {
				height: calc(100% - 20px);
				opacity: 1;
			}

			&::after {
				width: calc(100% - 15px);
				opacity: 1;
			}
		}

		.book-button {
			font-family: $header-font-family;
			font-size: rem-calc(13);
			line-height: rem-calc(13);
			color: map-get($brand-colours, light-gray);
			text-transform: uppercase;
			padding: 7px 20px;
			background-color: transparent;
			position: relative;
			margin: 0 !important;
			cursor: pointer;
			vertical-align: bottom;

			&:hover {		
				text-decoration: none !important;
			}
		}
	}
}

.book-button {
	background-color: map-get($brand-colours, group-dark-blue);
	padding: 9px 4px 9px;
	color: map-get($brand-colours, light-gray);
	font-family: $header-font-family;
}

.submit {
	&-container {
		display: inline-block;
		margin: 0 15px;
	}

	&-wrapper {
		display: inline-block;
		background-color: map-get($brand-colours, group-dark-blue);
		text-align: center;
		padding: 5px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 10px;
			left: 5px;
			border: solid rgba(0,0,0,0.5);
			border-width: 0 1px;
			background: transparent;
			transition: 0.3s;
			height: 0%;
			width: calc(100% - 10px);
			opacity: 0;
			pointer-events: none;
		}

		&::after {
			content: '';
			position: absolute;
			top: 5px;
			left: 8px;
			background: transparent;
			border: solid rgba(0,0,0,0.5);
			border-width: 1px 0;
			transition: 0.3s;
			height: calc(100% - 10px);
			width: 0%;
			opacity: 0;
			pointer-events: none;
		}

		&:hover {
			background-color: map-get($brand-colours, group-dark-blue);

			&::before {
				height: calc(100% - 20px);
				opacity: 1;
			}

			&::after {
				width: calc(100% - 15px);
				opacity: 1;
			}
		}

		button {
			font-family: $header-font-family;
			font-size: rem-calc(13);
			line-height: rem-calc(13);
			color: map-get($brand-colours, light-gray);
			text-transform: uppercase;
			padding: 8px 20px 7px;
			
			position: relative;
			margin: 0 !important;

			&:hover {
				
				text-decoration: none !important;
			}
		}
	}
}

.submit-button {
	background-color: map-get($brand-colours, group-dark-blue);
	padding: 8px 5px 10px;
	font-family: $header-font-family;
	position: relative;
}

.button {
	&.hollow {
        position: relative;
        text-transform: uppercase;
        border-color: map-get($brand-colours, group-dark-blue);
        transition: background-color 0.25s ease-out, color 0.25s ease-out, border-color 0.25s ease-out;
        font-family: $header-font-family;

        &:before {
            content: '';
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            border: 1px solid map-get($brand-colours, group-dark-blue);
            transition: border-color 0.25s ease-out;
            pointer-events: none;
        }

        &:hover,
        &:focus {
            border-color: map-get($brand-colours, group-dark-blue);

            &:before {
                border-color: map-get($brand-colours, group-dark-blue);
            }
        }
    }
}