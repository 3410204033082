.contact-details {
	max-width: 1260px;
	width: 100%;
	margin: 0 auto 30px;

	&__item {
		padding: 0 10px;
		border-bottom: 1px solid #e6e6e6;

		@include breakpoint(small only) {
			text-align: center;
			padding: 0;
		}

		&:last-child {
			border-bottom: none;
		}

		img {
			display: inline-block;
			padding-right: 5px;
		}

		a {
			display: inline-block;
		}

		h3 {
			display: inline-block;
			padding: 8px 0px;
			margin-bottom: 0;
			font-family: $header-font-family;
			font-size: rem-calc(15);
			white-space: normal !important;

			@include breakpoint(small only) {
				padding-top: 0;
			}
		}

		&-email {
			word-break: break-all;

			@include breakpoint(small only) {
				padding-top: 10px;
			}

			@media screen and (max-width: 460px) {
				h3 {
					font-size:0.6rem;
				}

				img {
					max-width: 23px;
				}
			}

			
		}
	}
}