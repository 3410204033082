/*Main color*/
/*Datepickk*/
.d-noscroll {
  overflow: hidden;
}
#Datepickk {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  font-family: inherit;
  color: #333;
  user-select: none;
  padding: 18px;
  text-transform: uppercase;
  max-width: 530px;
}
#Datepickk.MSIE:not(.wrapped):not(.inline) .d-calendar {
  height: 560px;
}
#Datepickk.d-show .d-overlay {
  animation-name: show;
  animation-duration: .2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}
#Datepickk.d-hide > * {
  animation-name: DatepickkHide;
  animation-duration: .2s;
  animation-timing-function: linear;
  animation-fill-mode: both;
}
#Datepickk.d-hide .d-overlay {
  animation-name: show;
  animation-duration: .15s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  animation-direction: reverse;
}
#Datepickk .d-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 58, 71, 0.95);
  z-index: 1;
}
#Datepickk .d-title {
  color: #26343c;
  text-align: center;
  position: relative;
  font-size: rem-calc(20);
  margin: 25px 0;
  z-index: 2;
}
#Datepickk .d-confirm {
  border: none;
  position: relative;
  background-color: transparent;
  outline: none;
  font-family: inherit;
  font-size: rem-calc(20);
  font-weight: 500;
  color: #26343c;
  margin: 25px 0;
  cursor: pointer;
  padding: 0;
  z-index: 2;
}
#Datepickk .d-title:empty,
#Datepickk .d-confirm:empty {
  display: none;
}
@media (max-height: 528px) {
  #Datepickk .d-title,
  #Datepickk .d-confirm {
    font-size: rem-calc(20);
    margin: 15px 0;
  }
  #Datepickk .d-calendar {
    font-size: rem-calc(20);
  }
}
#Datepickk.multi .d-calendar,
#Datepickk.multi .d-legend {
  max-width: 800px;
}
#Datepickk.multi .d-table {
  position: relative;
  padding-top: 30px;
  
  &:first-child {
	  border-right: 1px solid #cbc8c0;
  }
}
#Datepickk.multi .d-table:before {
  content: attr(data-month);
  text-align: center;
  width: 100%;
  font-size: rem-calc(20);
  padding: 5px 10px 5px 0;
  box-sizing: border-box;
  color: #26343c;
  position: absolute;
  left: 0;
  top: 0;
  border-bottom: 1px solid #cbc8c0;
}
#Datepickk.inline.d-show .d-calendar {
  animation: none;
}
#Datepickk.inline,
#Datepickk.wrapped {
  position: static;
  z-index: 0;
}
#Datepickk.inline .d-calendar,
#Datepickk.wrapped .d-calendar {
  box-shadow: none;
  z-index: 0;
  max-width: none;
  max-height: none;
}
#Datepickk.inline .d-confirm,
#Datepickk.wrapped .d-confirm,
#Datepickk.inline .d-title,
#Datepickk.wrapped .d-title {
  color: #222;
}
#Datepickk.inline .d-overlay,
#Datepickk.wrapped .d-overlay {
  display: none;
}
#Datepickk.inline .d-legend,
#Datepickk.wrapped .d-legend {
  color: #222!important;
  max-width: none;
  max-height: none;
}
#Datepickk.fullscreen .d-calendar {
  max-width: none;
  max-height: none;
}
#Datepickk.fullscreen .d-calendar input + label {
  outline: 1px solid #eaeaea;
  box-sizing: border-box;
  align-items: flex-start !important;
  justify-content: flex-end !important;
}
#Datepickk.fullscreen .d-calendar input + label text {
  padding: 5px 10px 0 0;
}
#Datepickk.fullscreen .d-legend {
  max-width: none;
}
#Datepickk .d-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  z-index: 2;
  background-color: #26343csmoke;
  max-width: 500px;
  position: relative;
  color: #26343c;
}
#Datepickk .d-legend:empty {
  height: 0;
}
#Datepickk .d-legend p {
  backface-visibility: visible !important;
  animation-name: DatepickfadeInUp;
  animation-duration: .5s;
  animation-fill-mode: both;
  margin: 0;
  padding: 15px;
  transition: background-color 0.2s ease;
  cursor: pointer;
  color: #1B353F;
}
#Datepickk .d-legend p:after {
  content: attr(data-legend);
}
#Datepickk .d-legend p span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 10px;
}
#Datepickk .d-calendar {
  margin: 0;
  font-size: rem-calc(20);
  width: 100%;
  position: relative;
  max-width: 500px;
  max-height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  z-index: 2;
}
#Datepickk .d-header {
  position: relative;
  color: #26343c;
  font-size: rem-calc(20);
  font-weight: bold;
}
#Datepickk .d-header p {
  margin: .5em 0;
  text-align: center;
}
#Datepickk .d-header i {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  text-align: center;
  border-radius: 100%;
  transition: background-color 0.2s ease;
  transform: translateY(-50%);
}
#Datepickk .d-header i:before,
#Datepickk .d-header i:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
/*#Datepickk .d-header i:before {
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}
#Datepickk .d-header i:after {
  border-top: 7px solid rgba(0, 0, 0, 0);
  border-bottom: 7px solid rgba(0, 0, 0, 0);
  transition: border-color 0.2s ease;
}*/
#Datepickk .d-header i#d-previous {
  left: 20px;
  width: 18px;
  height: 18px;
  
  &::before {
	  content: '';
	  display: block;
	  width: 18px;
	  height: 18px;
	  background: url('img/arrow.svg') center center no-repeat;
	  background-size: 8px 11px;;
	  transform: rotate(180deg);
  }
}
/*#Datepickk .d-header i#d-previous:before {
  border-right: 10px solid #26343c;
  top: 5px;
  left: 7px;
}
#Datepickk .d-header i#d-previous:after {
  border-right: 7px solid #1B363F;
  top: 8px;
  left: 10px;
}*/
#Datepickk .d-header i#d-next {
  right: 20px;
  width: 18px;
  height: 18px;
  
  &::before {
	  content: '';
	  display: block;
	  width: 18px;
	  height: 18px;
	  background: url('img/arrow.svg') left top no-repeat;
	  background-size: 8px 11px;
  }
}
/*#Datepickk .d-header i#d-next:before {
  border-left: 10px solid #26343c;
  top: 5px;
  left: 12px;
}
#Datepickk .d-header i#d-next:after {
  border-left: 7px solid #1B363F;
  top: 8px;
  left: 12px;
}*/
/*#Datepickk .d-header i:hover {
  background-color: #5E7178;
}
#Datepickk .d-header i:hover:after {
  border-left-color: #5E7178!important;
  border-right-color: #5E7178!important;
}*/
#Datepickk .d-month {
  cursor: pointer;
  white-space: nowrap;
}
#Datepickk .d-year {
  margin-left: 10px;
  cursor: pointer;
}
#Datepickk .d-year:before {
  content: "";
}
#Datepickk .d-month-picker {
  display: flex;
  justify-content: space-between;
  background-color: rgba(27, 54, 63, 0.9);
  height: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height .2s ease;
}
#Datepickk .d-month-picker.d-show {
  height: 44px;
  pointer-events: auto;
}
#Datepickk .d-month-picker > div {
  width: calc(100% / 12);
  text-align: center;
  line-height: 44px;
  color: #26343c;
  cursor: pointer;
}
#Datepickk .d-month-picker > div:hover,
#Datepickk .d-month-picker > div:focus {
  background-color: #e32d2d;
  color: #26343c;
}
#Datepickk .d-month-picker > div.current {
  background-color: #e9965a;
  color: #26343c;
}
#Datepickk .d-year-picker {
  display: flex;
  justify-content: space-between;
  background-color: rgba(27, 54, 63, 0.9);
  height: 0;
  overflow: hidden;
  pointer-events: none;
  transition: height .2s ease;
}
#Datepickk .d-year-picker.d-show {
  height: 44px;
  pointer-events: auto;
}
#Datepickk .d-year-picker > div {
  width: calc(100% / 11);
  text-align: center;
  line-height: 44px;
  color: #26343c;
  cursor: pointer;
}
#Datepickk .d-year-picker > div:hover,
#Datepickk .d-year-picker > div:focus {
  background-color: #e32d2d;
  color: #26343c;
}
#Datepickk .d-year-picker > div.current {
  background-color: #e9965a;
  color: #26343c;
}
#Datepickk .d-weekdays {
  display: flex;
}
#Datepickk .d-week {
  color: #26343c;
  display: flex;
  width: 100%;
}
#Datepickk .d-week + .d-week {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
#Datepickk .d-week > div {
  flex-basis: 14.28%; // calc(100% / 7) but rounded down for Edge/IE
  text-align: center;
}
#Datepickk .d-week > div p {
  margin: .8em 0;
}
@media (max-height: 540px) {
  #Datepickk .d-week {
    display: none;
  }
}
#Datepickk .d-table {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
#Datepickk .d-table:first-child:nth-last-child(n + 3),
#Datepickk .d-table:first-child:nth-last-child(n + 3) ~ div {
  flex: 0;
  flex-basis: calc(100% / 3 - 1px);
  /*-1px due to browser render*/
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
#Datepickk .d-table label:nth-of-type(7n) .d-date-legends {
  padding-right: 0;
}
#Datepickk .d-table label:nth-last-of-type(-n + 7) .d-date-legends {
  padding-bottom: 0;
}
#Datepickk .d-table input {
  display: none;
}
#Datepickk .d-table input + label {
  flex-basis: 14.28%; // calc(100% / 7) but rounded down for Edge/IE
  -ms-flex-preferred-size: 13.96%; // 14.28%, but reduced for IE
  /*Keep -> IE render fail*/
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, background 0.2s ease, color 0.2s ease;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  border: solid #cbc8c0;
  border-width: 0 1px 1px 0;
  padding: 5px 0;
  
  &:nth-child(7n) { // hide the borders of the rightmost days
	  border-right: none;
  }
  
  &:nth-child(n+71) { // hide the bottom border of the last week of days
	  border-bottom: none;
  }
}
#Datepickk .d-table input + label text {
  z-index: 9;
  pointer-events: none;
  position: relative;
}
#Datepickk .d-table input + label[style*="linear-gradient"] text {
  text-shadow: 0 0 20px black;
}
/*#Datepickk .d-table input + label.today text:before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #E95A5A;
  position: absolute;
  bottom: -5px;
  left: 0;
}*/
#Datepickk .d-table input + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #25343c;
  transition: transform 0.1s ease-out;
  transform: scaleX(0);
  transform-origin: center;
}
#Datepickk .d-table input + label.legend-hover {
  animation: legendhover 1s infinite;
  z-index: 10;
}
@keyframes legendhover {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
#Datepickk .d-table input + label.next,
#Datepickk .d-table input + label.prev {
  color: #ccc;
}
#Datepickk .d-table input + label [data-tooltip]:after {
  content: '';
  border-radius: 100%;
  background-color: #a68639;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  right: -10px;
}
#Datepickk .d-table input + label .d-tooltip {
  position: absolute;
  background-color: #a68639;
  color: #26343c;
  padding: 7px;
  font-size: rem-calc(20);
  z-index: 13;
  text-align: center;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -5px);
  display: none;
}
#Datepickk .d-table input + label .d-tooltip:before {
  content: '';
  border-bottom: 7px solid #1B363F;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  top: -7px;
  left: 50%;
  position: absolute;
  margin-left: -5px;
}
#Datepickk .d-table input + label .d-tooltip:empty {
  display: none!important;
}
#Datepickk .d-table input + label:hover .d-tooltip {
  display: block;
}
#Datepickk .d-table input:checked + label {
  color: #fff;
}
#Datepickk .d-table input:checked + label:before {
  transform: scaleX(1);
}
#Datepickk .d-table input:disabled + label {
  cursor: not-allowed;
}
#Datepickk .d-table input:disabled + label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 20%;
  width: 60%;
  height: 2px;
  z-index: 10;
  background-color: #c60000;
  transform-origin: center;
  transform: rotate(-25deg);
}
#Datepickk .d-table input + label.d-hidden {
  cursor: default;
  //color: #ccc!important;
  background: transparent !important;
  
  text {
	  opacity: 0;
  }
}
#Datepickk .d-table input + label.d-hidden:after {
  content: none;
}
#Datepickk .d-tables {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  border: 1px solid #cbc8c0;
  min-height: 157px;
}
#Datepickk .d-tables:not(.locked) input:not(:checked) + label:not(.hidden):hover {
  color: #222;
  background-color: #eaeaea;
}
#Datepickk .d-tables.locked label {
  cursor: default;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked + label {
  color: #fff;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked + label ~ label:not(.hidden) {
  color: #26343c;
}
/*#Datepickk .d-tables.range:not(.before) input:not(.single):checked + label ~ label:not(.hidden):before {
  transform: scaleX(1);
  background-color: rgba(125, 208, 118, 0.5);
}*/
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label {
  color: #666;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label:before,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label:before {
  transform: scaleX(0);
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label.next,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label.next,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:hover ~ label.prev,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ label:focus ~ label.prev {
  color: #ccc;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label {
  color: #fff;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label:before {
  transform: scaleX(1);
  background-color: #a68639;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label {
  color: inherit;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label.next,
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label.prev {
  color: #ccc;
}
#Datepickk .d-tables.range:not(.before) input:not(.single):checked ~ input:checked + label ~ label:before {
  transform: scaleX(0);
}
#Datepickk .d-tables.range.before input:not(.single):not(:checked) + label {
  color: #26343c;
}
#Datepickk .d-tables.range.before input:not(.single):not(:checked) + label:before {
  transform: scaleX(1);
  background-color: rgba(233, 90, 126, 0.3);
}
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label {
  color: inherit;
}
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label.next,
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label.prev {
  color: #ccc;
}
#Datepickk .d-tables.range.before input:not(.single):checked + label ~ label:before {
  transform: scaleX(0);
}
#Datepickk .d-fadeInUp {
  backface-visibility: visible !important;
  animation-name: DatepickfadeInUp;
  animation-duration: .5s;
  animation-fill-mode: both;
}
@keyframes DatepickBGShow {
  from {
    opacity: 0;
  }
}
@keyframes DatepickfadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.d-fadeInUp {
  animation-name: DatepickfadeInUp;
}
#Datepickk.d-show > * {
  animation-name: DatepickkShow;
  animation-duration: .2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}
@keyframes DatepickkShow {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
}
@keyframes DatepickkHide {
  to {
    opacity: 0;
    transform: translateY(50px);
  }
}
