.blog-hero {
	&__logo {
		padding: 125px 0 25px;

		@include breakpoint(small only) {
			padding: 100px 0 25px;
		}
		
		img {
			width: 100%;
			max-width: 177px;
			margin: 0 auto;
			display: block;

			@include breakpoint(small only) {
				max-width: 115px;
			}
		}
	}
	
	&__nav {
		position: relative;
		width: 100%;
		text-align: center;
		
		> ul {
			margin: auto;
			list-style-type: none;
			
			> li {
				display: inline;
				
				> a {
					color: #000;
					font-size: 14px;
					letter-spacing: 0.16em;
					text-transform: uppercase;
					display: inline-block;
					padding: 0 30px 27px;
				}
				
				&.has-dropdown a {
					background: url('img/oetker-collection/blog-nav-dropdown-arrow.svg') no-repeat;
					background-size: 8px 4px;
					background-position: center bottom 22px;
				} 
			}
		}

		&-search {
				display: inline-block;

				label {
					padding: 0 8px;
					background-repeat: no-repeat;
					background-position: center center;
					transition: 0.3s all ease-in-out;
					background-size: contain;

					&.open {
						padding: 0;
					}
				}

				&-close {
					display: none;
					width: 16px;
					cursor: pointer;
					transition: 0.3s all ease-in-out;

					&.open {
						display: inline-block;
					}
				}

				input[type="text"] {
					width: 1px;
					opacity: 0;
					transition: 0.6s all ease-in-out;
					border: 0;

					&.open {
						width: fit-content;
						border: none;
						border-bottom: 1px solid #767676;
						opacity: 1;
					}
				}

				input[type="image"] {
					opacity: 0;
					transition: 0.3s all ease-in-out;

					&.open {
						opacity: 1;
						width: 16px;
					}
				}
			}
		
		&-dropdown {
			position: absolute;
			z-index: 10;
			top: 50px;
			left: 0;
			width: 100%;
			background-color: rgba(#fff, 0.92);
			padding: 40px 10px;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.3s ease-in-out;
			
			@at-root .has-dropdown:hover #{&} {
				opacity: 1;
				pointer-events: all;
			}

			& > div {
				border-right: 1px solid #A1A1A1;

				&:last-child {
					border-right: none;
				}
			}
			
			ul {
				display: inline-block;
				list-style-type: none;
				margin: 0 15px 0 15px;
				padding-right: 15px;
				
				li {
					margin-bottom: 13px;
				}
				
				a {
					color: #000;
					font-size: 14px;
				}
			}
		}

		&-mobile {
			margin-bottom: 30px;

			button {
				text-transform: uppercase;
				position: relative;
				font-family: $blog-font-family;
				font-size: 13px;
				letter-spacing: 0.16em;
				color: #373737;
				padding: 3px 0;

				&::after {
					content: '';
					position: absolute;
					top: -2px;
					right: -25px;
					width: 16px;
					height: 100%;
					background: url('img/oetker-collection/blog-arrow-down-grey.svg') no-repeat center center;
					background-size: contain;
				}
			}

		}
	}
	
	&__search {
		margin-bottom: 50px;
		
		.small-4 {
			text-align: center;
		}
		
		label {
			color: #000;
			font-size: 14px;
			letter-spacing: 0.16em;
			text-transform: uppercase;
		}
		
		input[type="text"] {
			display: block;
			width: calc(100% - 15px);
			font-size: 14px;
			height: 20px;
			border: solid #707070;
			border-width: 0 0 1px 0;
		}
	}
}