/* Include any third-party CSS */
@import 'slick.scss';
@import 'slick-theme.scss';
@import 'foundation-datepicker';
@import 'mediaelementplayer';
@import 'leaflet';
@import 'leaflet-gesture-handling';

.slick {
    &-slider[data-slick*='"arrows": true'] {
        padding: 0 50px;
    }

    &-slide {
        outline: transparent;
    }

    &-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px 25px;
        transition: top 0.3s linear;
        cursor: pointer;

        &:hover,
        &:focus {

        }

        &:before {
            content: none;
        }

        &.slick-prev {
            left: 15px;
            background-image: url('../assets/img/oetker-collection/left.svg');
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 1;
            width: 15px;
            height: 25px;

            &::before {
                content: none;
            }

            &:hover {
                transform: translate(-5px, -50%);
                background-position: center center;
                background-size: 15px 25px;

                @include breakpoint(small only) {
                    transform: translate(0,-50%);
                }
            }

            &:focus {
                transform: translate(0px, -50%);
                background-position: center center;
                background-size: 15px 25px;

                @include breakpoint(small only) {
                    transform: translate(0,-50%);
                }
            }

            &.slick-disabled {
                background-image: url('../assets/img/left-disabled.svg') !important;
            }
        }

        &.slick-next {
            right: 15px;
            background-image: url('../assets/img/oetker-collection/right.svg');
            background-repeat: no-repeat;
            background-size: contain;
            width: 15px;
            height: 25px;

            &::before {
                content: none;
            }

            &:hover {
                transform: translate(5px, -50%);
                background-position: center center;
                background-size: 15px 25px;

                @include breakpoint(small only) {
                    transform: translate(0,-50%);
                }
            }

            &:focus {
                transform: translate(0, -50%);
                background-position: center center;
                background-size: 15px 25px;

                @include breakpoint(small only) {
                    transform: translate(0,-50%);
                }
            }

            &.slick-disabled {
                background-image: url('../assets/img/right-disabled.svg') !important;
            }
        }
    }

    &-dotted {
        margin-bottom: 60px;

        &.slick-slider {
            margin-bottom: 0;
        }
    }

    &-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;
        display: block;
        width: 100%;
        text-align: center;
        list-style: none;
        transition: top 0.3s linear;

        li {
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                font-size: 0;
                width: 10px;
                height: 10px;
                border-radius: 10px;
                border: 1px solid map-get($brand-colours, light-gray);
                background-color: #fff;
                padding: 4px;

                &:before {
                    content: none;
                }

                &:hover {
                    background-color: map-get($brand-colours, light-gray);
                }
            }

            &.slick-active {
                button {
                    background-color: map-get($brand-colours, group-dark-blue);
                }
            }
        }
    }
}

.mejs {

    &__container {
        margin: 0 auto;
        height: 100%;
    }

    &__button > button {
        background-image: url('../assets/img/mejs-controls.svg');
        border: 0;
        cursor: pointer;
        display: block;
        font-size: 0;
        height: 20px;
        line-height: 0;
        margin: 10px 6px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        text-decoration: none;
        width: 20px;
    }

    &__overlay-button {
        background-image: url('../assets/img/mejs-controls.svg');

        @at-root .video--dark-play #{&} {
            background-image: url('../assets/img/mejs-controls-dark.svg');
        }
    }

    &__play > button {
        background-position: 0 0;
    }

    &__pause > button {
        background-position: -20px 0;
    }

    &__fullscreen-button > button {
        background-position: -80px 0;
    }

    &__unfullscreen > button {
        background-position: -100px 0;
    }

    &__mute > button {
        background-position: -60px 0;
    }

    &__unmute > button {
        background-position: -40px 0;
    }

    &__volume-button {
        position: relative;
    }

    &__volume-button > &__volume-slider {
        backface-visibility: hidden;
        background: rgba(50, 50, 50, 0.7);
        border-radius: 0;
        bottom: 100%;
        display: none;
        height: 115px;
        left: 50%;
        margin: 0;
        position: absolute;
        transform: translateX(-50%);
        width: 25px;
        z-index: 1;
    }

    &__volume-button:hover {
        border-radius: 0 0 4px 4px;
    }

    &__volume-total {
        background: rgba(255, 255, 255, 0.5);
        height: 100px;
        left: 50%;
        margin: 0;
        position: absolute;
        top: 8px;
        transform: translateX(-50%);
        width: 2px;
    }

    &__volume-current {
        background: rgba(255, 255, 255, 0.9);
        left: 0;
        margin: 0;
        position: absolute;
        width: 100%;
    }

    &__volume-handle {
        background: rgba(255, 255, 255, 0.9);
        border-radius: 1px;
        cursor: ns-resize;
        height: 6px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 16px;
    }

    &__horizontal-volume-slider {
        display: block;
        height: 36px;
        position: relative;
        vertical-align: middle;
        width: 56px;
    }

    &__horizontal-volume-total {
        background: rgba(50, 50, 50, 0.8);
        border-radius: 2px;
        font-size: 1px;
        height: 8px;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 16px;
        width: 50px;
    }

    &__horizontal-volume-current {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 2px;
        font-size: 1px;
        height: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__horizontal-volume-handle {
        display: none;
    }

    &__controls:not([style*='display: none']) {
        background: rgba(255, 0, 0, 0.7);
        background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0));
        background: linear-gradient(transparent, rgba(0, 0, 0, 0));
    }

    &__controls {
        display: none;
    }

    &__iframe-overlay {
        display: none !important;
    }
}



.datepicker.datepicker--filters {
    margin-top: 5px;

    thead {
        font-family: $body-font-family;
        font-style: italic;

        th {
            font-size: 14px;
        }
    }

    td {
        span,
        &.month,
        &.year,
        &.day {
            &.active {
                background-color: #000 !important;

                &.disabled {
                    background-color: #fff !important;
                    color: #eee !important;
                }
            }

            &.selected {
                background-color: lighten(#000, 15%) !important;
                color: #fff !important;
            }
        }
    }

    &.dropdown-menu {
        box-shadow: none;
        border: 1px solid #000;
        border-radius: 0;

        &:before {
            top: -33px;
            right: -1px;
            left: auto;
            display: block;
            width: 25px;
            height: 25px;
            border: none;
            background-color: #000;
            background-image: url('../assets/img/jumby-bay/close-white.png');
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
            pointer-events: none;
        }
    }
}



.position-top {

    height: auto;
}
