.blog-article-intro {
	width: 100%;
	max-width: 975px;
	margin: 0 auto 50px;
	padding: 45px 25px 0;
	text-align: center;
	
	@include breakpoint(small only) {
		margin-bottom: 40px;
	}
	
	&__category {
		border-bottom: 4px solid #000;
		margin-bottom: 60px;
		
		h2 {
			font-family: $blog-font-family;
			font-size: 13px;
			letter-spacing: 0.16em;
			text-transform: uppercase;
			margin-bottom: 40px;
		}
	}
	
	&__title {
		h1 {
			font-size: 50px;
			line-height: 55px;
			width: 100%;
			max-width: 510px;
			margin: 0 auto 30px;

			@include breakpoint(small only) {
				font-size: 40px;
			}
		}
	}
	
	&__date {
		border-bottom: 1px solid #000;
		font-size: 12px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		padding-bottom: 70px;
	}
}