.social {
	text-align: center;
	margin: 0 auto 30px;

	&__hashtag {
		margin-bottom: 20px;

		h4 {
			line-height: rem-calc(35px);
			font-family: $header-font-family;
			text-transform: none;
		}
	}

	&__icons {
		p {
			position: absolute;
			font-family: $header-font-family;
			color: map-get($brand-colours, light-gray) !important;
			font-size: rem-calc(10);
			text-transform: uppercase;
			display: none;
			left: 50%;
			transform: translateX(-50%);
		}

		&-fb {
			background: url('img/oetker-collection/facebook.svg') no-repeat;
			background-size: 10px 21px;
			width: 10px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/facebook-dark.svg') no-repeat;
				background-size: 10px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-twit {
			background: url('img/oetker-collection/twitter.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/twitter-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}

		}

		&-yt {
			background: url('img/oetker-collection/youtube.svg') no-repeat;
			background-size: 31px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/youtube-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-ig {
			background: url('img/oetker-collection/instagram.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/instagram-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-wc {
			background: url('img/oetker-collection/wechat.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/wechat-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-mail {
			background: url('img/oetker-collection/mail.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/mail-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-li {
			background: url('img/oetker-collection/linkedin.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/linkedin-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-sw {
			background: url('img/oetker-collection/sina-weibo.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				width: 60px;
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/sina-weibo-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-tik {
			background: url('img/oetker-collection/tiktok.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				width: 60px;
				bottom: -35px;
			}

			&:hover {
				background: url('img/oetker-collection/tiktok-hover.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}

		&-pi {
			background: url('img/oetker-collection/pinterest.svg') no-repeat;
			background-size: 30px 21px;
			width: 30px;
			height: 25px;
			display: inline-block;
			margin: 0 20px;
			position: relative;

			p {
				position: absolute;
				width: 60px;
				bottom: -35px;
				left: 50%;
				transform: translateX(-50%);
				font-size: rem-calc(10);
				text-transform: uppercase;
				display: none;
				color: map-get($brand-colours, group-dark-blue);
			}

			&:hover {
				background: url('img/oetker-collection/pinterest-dark.svg') no-repeat;
				background-size: 30px 21px;

				p {
					display: block;
				}
			}

			@include breakpoint(small only) {
				margin: 0 10px;
			}


		}
	}

}
