.hotel-off-canvas {
	background-color: rgba(255,255,255,0.6);
	margin-top: 90px;

	&.position-top {
		height: calc(100% - 90px);

		@include breakpoint(large) {
			height: unset;
		}
	}

	&__group-link {
		position: relative;
		width: 100%;
		padding: 20px 10px;
		text-align: center;
		background-color: map-get($brand-colours, group-dark-blue);

		a {

			&::before {
				content: '';
				width: 30px;
				height: 30px;
				background: url('img/oetker-collection/thick-arrow-left-white.svg') no-repeat;
				background-size: 10px 15px;
				background-position: center left;
				position: absolute;
				top: 50%;
				left: 20px;
				transform: translateY(-50%);
			}

			img {
				width: 100%;
				max-width: 180px;
			}
		}

	}

	&__inner {
		position: relative;
		background-color: #fff;
		width: 100%;
		min-height: 90vh;
		max-width: none;
		box-shadow: rgba(#000, 0.2) 10px 0 18px;
		
		@include breakpoint(small only) {
			max-width: none;
		}

		@media screen and (min-width: 715px) {
			padding-bottom: 0;
		}
	}
	
	&__close-button {
		position: absolute;
		top: 40px;
		right: 26px;
		width: 20px;
		height: 20px;
		text-indent: -12345px;
		cursor: pointer;
		background: url('img/oetker-collection/close.svg') no-repeat center center;
		background-size: 20px 20px;
		
		&:focus {
			outline: none;
		}
	}
	
	&__primary {
		background-color: #fff;
		padding: 25px 50px;
		
		ul {
			margin: 0;
			list-style-type: none;
			
			ul {
				display: none;
			}
		}
		
		li {
			margin: 0 auto 25px;
			text-align: center;
			max-width: 300px;
		}
		
		a {
			font-size: rem-calc(13);
			text-transform: uppercase;
			letter-spacing: 0.5px;
			color: map-get($brand-colours, 'light-gray');
			transition: color 0.3s ease-in-out;
			font-family: $header-font-family;
			
			&:hover,
			&:active {
				color: map-get($brand-colours, 'group-dark-blue');
			}
		}
	}
	
	&__sub {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 100vw;
		padding: 40px 0;
		background-color: #fff;
		transition: all 0.5s ease-out;
		will-change: left;
		opacity: 0;
		
		@include breakpoint(small only) {
			max-width: none;
			z-index: 1;
			left: 100%;
		}
		
		&--is-open {
			left: 0%;
			opacity: 1;
			z-index: 0;
			height: calc(100% - 130px);
		}
		
		&-close {
			position: absolute;
			top: 47px;
			left: 15px;
			width: 15px;
			height: 20px;
			background: url('img/oetker-collection/nav-arrow-left.png') no-repeat center center;
			will-change: transform;
			cursor: pointer;
			text-indent: -12345px;
			transition: transform 0.3s ease-in-out;
			z-index: 9;
			
			&:hover {
				transform: translateX(-5px);
			}
		}
		
		ul {
			position: relative;
			margin: 0;
			list-style-type: none;
		}
		
		li {
			margin-bottom: 25px;
			padding: 0 35px;
			text-align: center;

			&:first-child a {
				color: #000;
			}
		}
		
		a {
			color: map-get($brand-colours, light-gray);
			font-size: rem-calc(15);
			line-height: rem-calc(35);
			transition: color 0.3s ease-in-out;
			font-family: $header-font-family;
			text-transform: uppercase;
			letter-spacing: 2px;
			
			&:hover,
			&:active {
				color: map-get($brand-colours, 'group-dark-blue');
			}
		}
	}
	
	&__secondary {
		background-color: #fff;
		padding: 20px 45px;
		width: 100%;
		
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			text-align: center;
		}
		
		li {
			display: inline-block;
			margin: 0 12px;
		}
		
		a {
			white-space: nowrap;
			font-size: rem-calc(13);
			line-height: rem-calc(18);
			text-transform: uppercase;
			letter-spacing: 0.5px;
			color: map-get($brand-colours, 'light-gray');
			transition: color 0.3s ease-in-out;
			font-family: $header-font-family;
			
			&:hover,
			&:active {
				color: map-get($brand-colours, 'group-dark-blue');
			}
		}
	}
	
	&__text {
		padding: 0 40px 60px;
		text-align: center;
		
		p {
			font-size: rem-calc(12);
			line-height: rem-calc(22);
			color: map-get($brand-colours, 'light-gray');
			margin: 0;
			font-family: $header-font-family;
			
			a {
				color: inherit;
				transition: color 0.3s ease-in-out;
				font-family: $header-font-family;
				
				&:hover,
				&:active {
					color: map-get($brand-colours, 'group-dark-blue');
				}
			}
		}
	}
}





.js-off-canvas-overlay.is-visible {
	opacity: 0;
}